/* eslint-disable no-case-declarations */
import * as subscriptionTypes from '../types/subscriptionTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject } from 'utils';

export const subscription = (state = initialState.subscription, action) => {
  switch (action.type) {
    case subscriptionTypes.FETCH_SUBSCRIPTIONS_START:
      return updateObject(state, { isLoading: true });
    case subscriptionTypes.FETCH_SUBSCRIPTIONS_SUCCESS:
      return updateObject(state, { data: action.payload, isLoading: false });

    case subscriptionTypes.FETCH_PAYMENTS_START:
      return updateObject(state, { payment: { isLoading: true } });
    case subscriptionTypes.FETCH_PAYMENTS_SUCCESS:
      return updateObject(state, { payment: { data: action.payload, isLoading: false } });

    case subscriptionTypes.FETCH_CARDS_START:
      return updateObject(state, { cards: { isLoading: true } });
    case subscriptionTypes.FETCH_CARDS_SUCCESS:
      return updateObject(state, { cards: { data: action.payload, isLoading: false } });

    case subscriptionTypes.CANCEL_SUBSCRIPTION_START:
      return updateObject(state, { isSubscriptionCancelling: true });
    case subscriptionTypes.CANCEL_SUBSCRIPTION_END:
      return updateObject(state, { isSubscriptionCancelling: false });

    default:
      return { ...state };
  }
};
