/* eslint-disable max-len */
import aurora from '../assets/sounds/notification_sound(3).mp3';
import chord from '../assets/sounds/notification_sound(2).mp3';
import bell from '../assets/sounds/notification_sound(1).mp3';
import hello from '../assets/sounds/notification_sound.mp3';
import input from '../assets/sounds/notification-sound-7062.mp3';

export const tunes = [
  {
    audio: null,
    name: 'None',
    id: 0,
  },
  {
    audio: aurora,
    name: 'Aurora',
    id: 1,
  },
  {
    audio: chord,
    name: 'Chord',
    id: 2,
  },
  {
    audio: bell,
    name: 'Bell',
    id: 3,
  },
  {
    audio: input,
    name: 'Input',
    id: 4,
  },
  {
    audio: hello,
    name: 'Hello',
    id: 5,
  },
];

export const SPECIALTIES = ['General Practice', 'Ophthalmology'];
export const NEW_SPECIALTIES = [
  { label: 'Ophthalmology', value: 'ophthalmology' },
  { label: 'General Practice', value: 'generalPractice' },
  { label: 'Obstetrics and Gynaecology', value: 'obgyn' },
  { label: 'Endocrinology', value: 'endocrinology' },
  { label: 'Cardiology', value: 'cardiology' },
  { label: 'Paediatrician', value: 'paediatrician' },
  { label: 'Physiotherapy', value: 'physiotherapy' },
  { label: 'Orthopaedic', value: 'orthopaedic' },
  { label: 'Dentist', value: 'dentist' },
  { label: 'Urology', value: 'urology' },
  { label: 'Neurology', value: 'neurology' },
  { label: 'Oncology', value: 'oncology' },
  { label: 'Nephrology', value: 'nephrology' },
  { label: 'Cardio Thoracic', value: 'cardiothoracic' },
  { label: 'General Surgeries', value: 'generalSurgeries' },
  { label: 'Rheumatology', value: 'rheumatology' },
  { label: 'General Family Physician', value: 'generalFamilyPhysician' },
];
export const SECTIONS = ['Vitals', 'Consultant', 'Outpatient Procedure', 'Outpatient Surgery'];

export const personalDetails = [
  {
    _id: 1,
    checked: false,
    text: 'Location',
    value: 'address',
  },
  { _id: 2, checked: false, text: 'Email Address', value: 'email' },
];
export const nextOfKin = [
  { _id: 7, checked: false, text: 'Full Name', value: 'nokFullName' },
  { _id: 8, checked: false, text: 'Relationship', value: 'nokRelationship' },
  { _id: 9, checked: false, text: 'Phone Number', value: 'nokPhoneNumber' },
  { _id: 10, checked: false, text: 'Email Address', value: 'nokEmail' },
  {
    _id: 11,
    checked: false,
    text: 'Location',
    value: 'nokAddress',
  },
];
export const hmoDetails = [{ _id: 12, checked: false, text: 'HMO/Provider', value: 'sponsors' }];
export const extraDetails = [
  { _id: 13, checked: false, text: 'Referral', value: 'referral' },
  { _id: 14, checked: false, text: 'Allergies', value: 'allergies' },
  { _id: 15, checked: false, text: 'Patient ID Number', value: 'staffId' },
  { _id: 16, checked: false, text: 'Department', value: 'companyDepartment' },
];

export const daysOfTheWeek = [
  { label: 'Sunday', value: 'sunday' },
  { label: 'Monday', value: 'monday' },
  { label: 'Tuesday', value: 'tuesday' },
  { label: 'Wednesday', value: 'wednesday' },
  { label: 'Thursday', value: 'thursday' },
  { label: 'Friday', value: 'friday' },
  { label: 'Saturday', value: 'saturday' },
];

export const appointmentsSlots = Array.from({ length: 60 }, (_, id) => id + 1).map((slotValue) => ({
  label: `${slotValue} minute${slotValue === 1 ? '' : 's'}`,
  value: `${slotValue}`,
}));

export const unitOfPurchaseOptions = [
  { label: 'Bottle', value: 'Bottle' },
  { label: 'Box', value: 'Box' },
  { label: 'Cartons', value: 'Cartons' },
  { label: 'Case', value: 'Case' },
  { label: 'Cups', value: 'Cups' },
  { label: 'Dozen', value: 'Dozen' },
  { label: 'Each', value: 'Each' },
  { label: 'Gallon', value: 'Gallon' },
  { label: 'Inches', value: 'Inches' },
  { label: 'Oz', value: 'Oz' },
  { label: 'Packs', value: 'Packs' },
  { label: 'Sheet', value: 'Sheet' },
  { label: 'Yard', value: 'Yard' },
];

export const productVariantOptions = [
  { label: 'Colour', value: 'colour' },
  { label: 'Size', value: 'size' },
  { label: 'Length', value: 'length' },
  { label: 'Gender', value: 'gender' },
  { label: 'Class', value: 'class' },
  { label: 'Adult or Kid', value: 'adult or kid' },
  { label: 'Type', value: 'type' },
  { label: 'Frame', value: 'frame' },
  { label: 'Material', value: 'material' },
  { label: 'Style', value: 'style' },
  { label: 'Bridge', value: 'bridge' },
  { label: 'Model', value: 'model' },
];

export const unitOfSalesOptions = [
  { label: 'Ampoules(s)', value: 'Ampoules(s)' },
  { label: 'Bottle(s)', value: 'Bottle(s)' },
  { label: 'Bag(s)', value: 'Bag(s)' },
  { label: 'Can(s)', value: 'Can(s)' },
  { label: 'Catheter', value: 'Catheter' },
  { label: 'Capsule(s)', value: 'Capsule(s)' },
  { label: 'ML(Ampoules)', value: 'ML(Ampoules)' },
  { label: 'ML(syrub)', value: 'ML(syrub)' },
  { label: 'Pessary(Capsules)', value: 'Pessary(Capsules)' },
  { label: 'Roll(s)', value: 'Roll(s)' },
  { label: 'Strip', value: 'Strip' },
  { label: 'Supp(Capsules)', value: 'Supp(Capsules)' },
  { label: 'Tablet(s)', value: 'Tablet(s)' },
  { label: 'Tube(s)', value: 'Tube(s)' },
  { label: 'Vial(s)', value: 'Vial(s)' },
];

export const PATIENT_FILTER_OPTIONS = ['Name', 'MRN', 'Phone Number', 'Email', 'Department', 'ID NO'];

export const doseFrequencyOptions = [
  { label: 'once a day (OD)', value: 'once a day (OD)' },
  { label: 'twice a day (BID)', value: 'twice a day (BID)' },
  { label: 'three times a day (TID)', value: 'three times a day (TID)' },
  { label: 'four times a day (QID)', value: 'four times a day (QID)' },
  { label: 'five times a day', value: 'five times a day' },
  { label: 'Immediately (STAT)', value: 'Immediately (STAT)' },
  { label: 'Every four hours (Q4H)', value: 'Every four hours (Q4H)' },
  { label: 'Every five hours (Q5H)', value: 'Every five hours (Q5H)' },
  { label: 'every six hours (Q6H)', value: 'every six hours (Q6H)' },
  { label: 'bedtime', value: 'bedtime' },
  { label: 'nocte', value: 'nocte' },
  { label: '30 minutes', value: '30 minutes' },
  { label: '1 hourly', value: '1 hourly' },
  { label: '2 hourly', value: '2 hourly' },
  { label: '4 hourly', value: '4 hourly' },
  { label: '8 hourly', value: '8 hourly' },
  { label: '10 hourly', value: '10 hourly' },
  { label: '12 hourly', value: '12 hourly' },
];

export const dosageRouteOptions = [
  { label: 'Mouth/oral', value: 'Mouth/oral' },
  { label: 'Intermuscular/Injection', value: 'Intermuscular/Injection' },
  { label: 'Intravenous/Injection', value: 'Intravenous/Injection' },
  { label: 'Intravenous Push', value: 'Intravenous Push' },
  { label: 'Subcutaneous', value: 'Subcutaneous' },
  { label: 'Rectal', value: 'Rectal' },
  { label: 'Vagina', value: 'Vagina' },
  { label: 'Nasal', value: 'Nasal' },
  { label: 'Intradermal', value: 'Intradermal' },
  { label: 'Total Parenteral nutrition', value: 'Total Parenteral nutrition' },
  { label: 'Nothing by mouth', value: 'Nothing by mouth' },
  { label: 'Sublingual', value: 'Sublingual' },
  { label: 'Ocular', value: 'Ocular' },
  { label: 'Otic', value: 'Otic' },
  { label: 'Inhalation', value: 'Inhalation' },
  { label: 'Nubulization', value: 'Nubulization' },
  { label: 'Cutaneous', value: 'Cutaneous' },
  { label: 'Transdermal', value: 'Transdermal' },
];

export const dosageFormOptions = [
  { label: 'Tablets', value: 'Tablets' },
  { label: 'Capsules', value: 'Capsules' },
  { label: 'mg', value: 'mg' },
  { label: 'mL', value: 'mL' },
  { label: 'Tsp', value: 'Tsp' },
  { label: 'Tbsp', value: 'Tbsp' },
  { label: 'oz', value: 'oz' },
  { label: 'Microgram', value: 'microgram' },
  { label: 'Cup', value: 'Cup' },
  { label: 'Drops', value: 'Drops' },
  { label: 'Syrup', value: 'Syrup' },
  { label: 'Powder', value: 'Powder' },
  { label: 'Injections', value: 'Injections' },
];

export const roles = [
  { value: 'admin', label: 'admin' },
  { value: 'staff', label: 'staff' },
];

export const branches = [
  { value: 'ikeja', label: 'ikeja' },
  { value: 'lekki', label: 'lekki' },
];

export const departments = [
  { value: 'optical', label: 'optical' },
  { value: 'clinical', label: 'clinical' },
];

export const referralOptions = [
  { label: 'Facebook', value: 'Facebook' },
  { label: 'LinkedIn', value: 'LinkedIn' },
  { label: 'Twitter', value: 'Twitter' },
  { label: 'Website', value: 'Website' },
  { label: 'Friend', value: 'Friend' },
  { label: 'Poster', value: 'Poster' },
  { label: 'Hospital Outreach Programme', value: 'Hospital Outreach Programme' },
  { label: 'Others', value: 'Others' },
];

export const todo = 'pending';
export const pending = 'accepted';
export const inprogress = 'active';
export const completed = 'completed';
export const dispatched = 'delivered';
export const declined = 'declined';

export const orderStateOptions = [
  { label: 'All', value: '' },
  { label: 'Todo', value: todo },
  { label: 'Pending', value: pending },
  { label: 'Active', value: inprogress },
  { label: 'Completed', value: completed },
  { label: 'Dispatched', value: dispatched },
  { label: 'Declined', value: declined },
];

export const relationshipOptions = [
  { label: 'Mother', value: 'Mother' },
  { label: 'Father', value: 'Father' },
  { label: 'Sibling', value: 'Sibling' },
  { label: 'Cousin', value: 'Cousin' },
  { label: 'Relation', value: 'Relation' },
  { label: 'Spouse', value: 'Spouse' },
  { label: 'Friend', value: 'Friend' },
];

export const icdCodes = [
  { label: 'ICD 9', value: 'icd9' },
  { label: 'ICD 10', value: 'icd10' },
];

export const timeZones = [
  { label: '(GMT -12:00) Eniwetok, Kwajalein', value: '-12:00' },
  { label: '(GMT -11:00) Midway Island, Samoa', value: '-11:00' },
  { label: '(GMT -10:00) Hawaii', value: '-10:00' },
  { label: '(GMT -9:30) Taiohae', value: '-09:50' },
  { label: '(GMT -9:00) Alaska', value: '-09:00' },
  { label: '(GMT -8:00) Pacific Time (US & Canada)', value: '-08:00' },
  { label: '(GMT -7:00) Mountain Time (US & Canada)', value: '-07:00' },
  {
    label: '(GMT -6:00) Central Time (US & Canada), Mexico City',
    value: '-06:00',
  },
  {
    label: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
    value: '-05:00',
  },
  { label: '(GMT -4:30) Caracas', value: '-04:50' },
  {
    label: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
    value: '-04:00',
  },
  { label: '(GMT -3:30) Newfoundland', value: '-03:50' },
  { label: '(GMT -3:00) Brazil, Buenos Aires, Georgetown', value: '-03:00' },
  { label: '(GMT -2:00) Mid-Atlantic', value: '-02:00' },
  { label: '(GMT -1:00) Azores, Cape Verde Islands', value: '-01:00' },
  {
    label: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
    value: '+00:00',
  },
  { label: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris', value: '+01:00' },
  { label: '(GMT +2:00) Kaliningrad, South Africa', value: '+02:00' },
  {
    label: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
    value: '+03:00',
  },
  { label: '(GMT +3:30) Tehran', value: '+03:50' },
  { label: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', value: '+04:00' },
  { label: '(GMT +4:30) Kabul', value: '+04:50' },
  {
    label: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
    value: '+05:00',
  },
  { label: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi', value: '+05:50' },
  { label: '(GMT +5:45) Kathmandu, Pokhara', value: '+05:75' },
  { label: '(GMT +6:00) Almaty, Dhaka, Colombo', value: '+06:00' },
  { label: '(GMT +6:30) Yangon, Mandalay', value: '+06:50' },
  { label: '(GMT +7:00) Bangkok, Hanoi, Jakarta', value: '+07:00' },
  {
    label: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
    value: '+08:00',
  },
  { label: '(GMT +8:45) Eucla', value: '+08:75' },
  {
    label: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
    value: '+09:00',
  },
  { label: '(GMT +9:30) Adelaide, Darwin', value: '+09:50' },
  {
    label: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
    value: '+10:00',
  },
  { label: '(GMT +10:30) Lord Howe Island', value: '+10:50' },
  {
    label: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
    value: '+11:00',
  },
  { label: '(GMT +11:30) Norfolk Island', value: '+11:50' },
  {
    label: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
    value: '+12:00',
  },
  { label: '(GMT +12:45) Chatham Islands', value: '+12:75' },
  { label: '(GMT +13:00) Apia, Nukualofa', value: '+13:00' },
  { label: '(GMT +14:00) Line Islands, Tokelau', value: '+14:00' },
];

export const timezonesUTC = [
  { label: '(UTC -12:00) International Date Line West', value: '-12:00' },
  { label: '(UTC -11:00) Coordinated Universal Time-11', value: '-11:00' },
  { label: '(UTC -10:00) Hawaii', value: '-10:00' },
  { label: '(UTC -09:00) Alaska', value: '-09:00' },
  { label: '(UTC -08:00) Baja California, Pacific Time (US and Canada)', value: '-08:00' },
  { label: '(UTC -07:00) Chihuahua, La Paz, Mazatlan, Arizona, Mountain Time (US and Canada)', value: '-07:00' },
  {
    label:
      '(UTC -06:00) Central America, Central Time (US and Canada), Saskatchewan, Guadalajara, Mexico City, Monterey',
    value: '-06:00',
  },
  { label: '(UTC -05:00) Bogota, Lima, Quito, Indiana (East), Eastern Time (US and Canada)', value: '-05:00' },
  { label: '(UTC -04:30) Caracas', value: '-04:30' },
  {
    label: '(UTC -04:00) Atlantic Time (Canada), Asuncion, Georgetown, La Paz, Manaus, San Juan, Cuiaba, Santiago',
    value: '-04:00',
  },
  { label: '(UTC -03:30) Newfoundland', value: '-03:30' },
  { label: '(UTC -03:00) Brasilia, Greenland, Cayenne, Fortaleza, Buenos Aires, Montevideo', value: '-03:00' },
  { label: '(UTC -02:00) Coordinated Universal Time-2', value: '-02:00' },
  { label: '(UTC -01:00) Cape Verde, Azores', value: '-01:00' },
  { label: '(UTC +00:00) Casablanca, Monrovia, Reykjavik, Dublin, Edinburgh, Lisbon, London', value: '+00:00' },
  {
    label:
      '(UTC +01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, Brussels, Copenhagen, Madrid, Paris, West Central Africa, Belgrade, Bratislava, Budapest, Ljubljana, Prague, Sarajevo, Skopje, Warsaw, Zagreb, Windhoek',
    value: '+01:00',
  },
  {
    label:
      '(UTC +02:00) Athens, Bucharest, Istanbul, Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius, Cairo, Damascus, Amman, Harare, Pretoria, Jerusalem, Beirut',
    value: '+02:00',
  },
  {
    label: '(UTC +03:00) Baghdad, Minsk, Kuwait, Riyadh, Nairobi',
    value: '+03:00',
  },
  { label: '(UTC +03:30) Tehran', value: '+03:30' },
  {
    label: '(UTC +04:00) Moscow, St. Petersburg, Volgograd, Tbilisi, Yerevan, Abu Dhabi, Muscat, Baku, Port Louis',
    value: '+04:00',
  },
  { label: '(UTC +04:30) Kabul', value: '+04:30' },
  { label: '(UTC +05:00) Tashkent, Islamabad, Karachi', value: '+05:00' },
  { label: '(UTC +05:30) Sri Jayewardenepura Kotte, Chennai, Kolkata, Mumbai, New Delhi', value: '+05:30' },
  { label: '(UTC +05:45) Kathmandu', value: '+05:45' },
  { label: '(UTC +06:00) Astana, Dhaka, Yekaterinburg', value: '+06:00' },
  { label: '(UTC +06:30) Yangon', value: '+06:30' },
  { label: '(UTC +06:30) Yangon', value: '+06:30' },
  { label: '(UTC +07:00) Bangkok, Hanoi, Jakarta, Novosibirsk', value: '+07:00' },
  {
    label:
      '(UTC +08:00) Krasnoyarsk, Ulaanbaatar, Beijing, Chongqing, Hong Kong, Urumqi, Perth, Kuala Lumpur, Singapore, Taipei',
    value: '+08:00',
  },
  { label: '(UTC +09:00) Irkutsk, Seoul, Osaka, Sapporo, Tokyo', value: '+09:00' },
  { label: '(UTC +09:30) Darwin, Adelaide', value: '+09:30' },
  { label: '(UTC +10:00) Hobart, Yakutsk, Brisbane, Guam, Port Moresby, Canberra, Melbourne, Sydney', value: '+10:00' },
  { label: '(UTC +11:00) Vladivostok, Solomon Islands, New Caledonia', value: '+11:00' },
  {
    label: '(UTC +12:00) Coordinated Universal Time+12, Fiji, Marshall Islands, Magadan, Auckland, Wellington',
    value: '+12:00',
  },
  { label: "(UTC +13:00) Nuku'alofa, Samoa", value: '+13:00' },
];

export const requiredComplaintKeys = [
  'rightEye',
  'leftEye',
  'historyOfPresentEye',
  'previousEyeProblems',
  'summary',
  'haveYouSortAdviceBefore',
  'otherComments',
];

export const requiredOpCasnoteKeys = [
  'complaint',
  'anteriorSegmentExam',
  'extraOcularMuscleMovement',
  'fundus',
  'pastOcularHistory',
  'posteriorSegmentExam',
  'refraction',
  'visualAcuity',
  'selectedEye',
  'leftEyeOtherDiagnosis',
  'rightEyeOtherDiagnosis',
  'leftEyeDiagnosis',
  'rightEyeDiagnosis',
  'followUp',
  'narrative',
  'attachments',
  'signedBy',
  'linkedVisit',
  'recommendations',
];

export const requiredGeneralCasenoteKeys = [
  'complaint',
  'narrative',
  'attachments',
  'signedBy',
  'linkedVisit',
  'recommendations',
  'systemReview',
  'medicalHistory',
  'physicalExamination',
  'diagnosis',
  'otherDiagnosis',
];
export const swiperParams = {
  containerclass: 'swiper-container p-1 pb-3 pr-4',
  slidesPerView: 1,
  spaceBetween: 20,
  scrollbar: {
    el: '.swiper-scrollbar',
  },
  breakpoints: {
    1600: {
      slidesPerView: 3,
      spaceBetween: 20,
    },
    1330: {
      slidesPerView: 2.5,
      spaceBetween: 20,
    },

    1200: {
      slidesPerView: 2.2,
      spaceBetween: 20,
    },
    830: {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    650: {
      slidesPerView: 1.5,
      spaceBetween: 20,
    },
    450: {
      slidesPerView: 1,
      spaceBetween: 20,
    },
  },
};

export const extraOcularOptions = [
  { label: 'IOP', value: 'iop' },
  { label: 'IOP Adjusted', value: 'adjusted' },
  { label: 'Pupils', value: 'pupils' },
  { label: 'Orbit', value: 'orbit' },
  { label: 'Lids', value: 'lids' },
  { label: 'Conjunctiva', value: 'conjunctiva' },
  { label: 'Cornea', value: 'cornea' },
  { label: 'AC / Iris', value: 'acIris' },
  { label: 'Lens', value: 'lens' },
  { label: 'Vitreous', value: 'vitreous' },
];

export const fundusOptions = [
  { label: 'Disc', value: 'disk' },
  { label: 'Vessels', value: 'vessels' },
  { label: 'Macular', value: 'macular' },
  { label: 'Periphery', value: 'periphery' },
];

export const refractionOptions = [
  { label: 'Retinoscopy', value: 'retinoscopy' },
  { label: 'Subjective', value: 'subjective' },
];
export const VisualAcuityOptions = [
  { label: 'CC', value: 'cc' },
  { label: 'PH', value: 'ph' },
  { label: 'NEAR', value: 'near' },
  { label: 'SC', value: 'sc' },
];

export const FILTER_OPTION = [
  { label: 'Name', value: 'name' },
  { label: 'MRN', value: 'mrn.in' },
  { label: 'Phone Number', value: 'phoneNumber.in' },
  { label: 'Email', value: 'email.in' },
  { label: 'Department', value: 'companyDepartment' },
  { label: 'ID NO', value: 'staffId' },
];

export const describeComplaintOptions = [
  { label: 'Acute', value: 'Acute' },
  { label: 'Chronic', value: 'Chronic' },
  { label: 'Subacute', value: 'Subacute' },
  { label: 'Worse at night', value: 'Worse at night' },
  { label: 'Worse during the day', value: 'Worse during the day' },
  { label: 'Constant', value: 'Constant' },
  { label: 'Intermittent ', value: 'Intermittent ' },
  { label: 'Colicky', value: 'Colicky' },
  { label: 'Sharp', value: 'Sharp' },
  { label: 'Crushing', value: 'Crushing' },
  { label: 'Pressured', value: 'Pressured' },
  { label: 'Worse with food', value: 'Worse with food' },
  { label: 'Worse without food', value: 'Worse without food' },
  { label: 'Worse with exertion', value: 'Worse with exertion' },
  { label: 'Relieved at rest', value: 'Relieved at rest' },
  { label: 'Projectile', value: 'Projectile' },
  { label: 'Non Projectile', value: 'Non Projectile' },
];

export const severityColor = [
  { label: 'None', value: 'None' },
  { label: 'Minor', value: 'Minor' },
  { label: 'Moderate', value: 'Moderate' },
  { label: 'Major', value: 'Major' },
  { label: 'Extreme', value: 'Extreme' },
];

export const FormIDs = {
  saveVitalsForm: 'saveVitalsForm',
  addCaseNoteForm: 'addCaseNoteForm',
  saveComplaintForm: 'saveComplaintForm',
  presentingComplaintCaseNoteForm: 'presentingComplaintCaseNoteForm',
  reviewOfSystemCaseNoteForm: 'reviewOfSystemCaseNoteForm',
  medicalHistoryCaseNoteForm: 'medicalHistoryCaseNoteForm',
  physicalExaminationCaseNoteForm: 'physicalExaminationCaseNoteForm',
  otherDiagnosisCaseNoteForm: 'otherDiagnosisCaseNoteForm',
  requestLabInvestigationCaseNoteForm: 'requestLabInvestigationCaseNoteForm',
  primaryDiagnosisCaseNoteForm: 'physicalExaminationCaseNoteForm',
  addPrescriptionCaseNoteForm: 'addPrescriptionCaseNoteForm',
  addPrescriptionCaseNoteForm2: 'addPrescriptionCaseNoteForm2',
  referralForm: 'referralForm',
  SaveBookSurgery: 'SaveBookSurgery',
  SaveProcedure: 'SaveProcedure',
  SaveProcedureInOffice: 'SaveProcedure',
  SaveProcedureInternal: 'SaveProcedureInternal',
  SaveProcedureExternal: 'SaveProcedureExternal',
  saveAdmitPatient: 'saveAdmitPatient',
  saveAppointment: 'saveAppointment',
  savePrescription: 'savePrescription',
  saveFollowUp: 'saveFollowUp',
  addProgressNoteForm: 'addProgressNoteForm',
  addPastRecordForm: 'addPastRecordForm',
  addConsumablesForm: 'addConsumablesForm',
  addAdmissionServices: 'addAdmissionServices',
  addNursingNote: 'addNursingNote',
  saveVisualAcuity: 'saveVisualAcuity',
  saveReviewOfSystems: 'saveReviewOfSystems',
  saveAnteriorSegmentExamination: 'saveAnteriorSegmentExamination',
  savePosteriorSegmentExamination: 'savePosteriorSegmentExamination',
  saveFundus: 'saveFundus',
  saveExtraOcularMuscleMovement: 'saveExtraOcularMuscleMovement',
  savePastOcularHistory: 'savePastOcularHistory',
  counselorsForm: 'counselorsForm',
  patientSurgeryChecklist: 'patientSurgeryChecklist',
  operationRoomChecklist: 'operationRoomChecklist',
  preOperativeFormIntraOperative: 'preOperativeFormIntraOperative',
  anesthesiaRecords: 'anesthesiaRecords',
  generalOperationForm: 'generalOperationForm',
  postOperativeForm: 'postOperativeForm',
};

export const SURGERY_LOCATIONS = [
  { label: 'Theatre', value: 'theatre' },
  { label: 'In Office', value: 'inOffice' },
];

export const CUSTOM_ERROR_MESSAGE = 'Unknown error occurred, please refresh or try again.';

export const SOCKET_EVENTS = [
  {
    title: 'New Appointment',
    event: 'newAppointment',
  },
  {
    title: 'Vitals Done',
    event: 'newConsultingAppointment',
  },
  {
    title: 'Internal Lab Investigation',
    event: 'newInternalLabInvestigation',
  },
  {
    title: 'External Lab Investigation',
    event: 'newExternalLabInvestigation',
  },
  {
    title: 'Private Patient Admitted',
    event: 'newAdmission',
  },
  {
    title: 'Test Result',
    event: 'testTypeResult',
  },
  {
    title: 'Consultant Transfer',
    event: 'consultantTransfer',
  },
  {
    title: 'Software Update',
    event: 'software-update',
  },
];
