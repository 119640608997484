/* eslint-disable no-case-declarations */
import * as diseaseTypes from '../types/diseaseTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject, _sortItems } from 'utils';

export const diseases = (state = initialState.diseases, action) => {
  switch (action.type) {
    case diseaseTypes.FETCH_DISEASES_SUCCESS:
      return updateObject(state, action.payload);

    case diseaseTypes.FETCH_DISEASES_FAILED:
      return updateObject(state, { error: action.error });

    case diseaseTypes.ADD_DISEASE_START:
      return updateObject(state, { isSaving: true });

    case diseaseTypes.ADD_DISEASE_SUCCESS:
      const newDiseases = _sortItems([...state.items].concat(action.payload), 'name');
      return updateObject(state, { items: newDiseases, filteredItems: newDiseases, isSaving: false });

    case diseaseTypes.ADD_DISEASE_FAILED:
      return updateObject(state, {
        isSaving: false,
      });

    case diseaseTypes.UPDATE_DISEASE_START:
      return updateObject(state, { ...action.payload });

    case diseaseTypes.UPDATE_DISEASE_SUCCESS:
      const updated = [...state.items].map(prev =>
        String(prev._id) === String(action.payload._id) ? action.payload : prev,
      );
      return updateObject(state, {
        items: updated,
        filteredItems: updated,
        isSaving: false,
        isDeactivating: false,
      });

    case diseaseTypes.UPDATE_DISEASE_FAILED:
      return updateObject(state, {
        isSaving: false,
        isDeactivating: false,
      });

    case diseaseTypes.UPDATE_DISEASES_LIST:
      return updateObject(state, action.payload);

    default:
      return { ...state };
  }
};
