
import initialState from 'redux/reducers/initialState';
import { updateObject } from 'utils';
import {
    GET_NOTIFICATIONS_FAIL, GET_NOTIFICATIONS_START,
    GET_NOTIFICATIONS_SUCCESS, MARK_NOTIFICATION_FAIL, MARK_NOTIFICATION_START, MARK_NOTIFICATION_SUCCESS
} from 'redux/types/notificationTypes';

export const notifications = (state = initialState.notifications, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS_START:
            return updateObject(state, { isLoading: true });
        case GET_NOTIFICATIONS_SUCCESS:
            return updateObject(state, { ...action.payload, isLoading: false });
        case GET_NOTIFICATIONS_FAIL:
            return updateObject(state, { isLoading: false });
        case MARK_NOTIFICATION_START:
            return updateObject(state, { isLoading: true });
        case MARK_NOTIFICATION_SUCCESS:
            return updateObject(state, { isLoading: false });
        case MARK_NOTIFICATION_FAIL:
            return updateObject(state, { isLoading: false });
        default:
            return { ...state };
    }
};

export default notifications;