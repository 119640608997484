import React, { lazy, useEffect } from 'react';
import { Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../../../layouts/MainLayout';
import AuthRoute from '../../../routes/AuthRoute';
import { fetchStaff } from '../../../redux/actions';

const AuditTrail = lazy(() => import('../pages/Audit-trail'));
const ErrorPage = lazy(() => import('../../../pages/Error'));

const AuditTrailRoutes = ({ match }) => {
  const { loggedIn } = useSelector(state => state.auth);
  // TODO check accessGranted value using the modules for the current branch
  const { current } = useSelector(state => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (current && current.parentOrganizationId) {
      dispatch(fetchStaff(current.parentOrganizationId));
    }
  }, [dispatch, current]);

  return (
    <>
      <Switch>
        <AuthRoute
          exact
          path={`${match.path}`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={AuditTrail}
          accessGranted={true}
        />

        <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
      </Switch>
    </>
  );
};

AuditTrailRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default AuditTrailRoutes;
