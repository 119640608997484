/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { SettingsModal, UploadFile, AuthErrorCard } from 'components';
import { Button, Form } from 'reactstrap';
import PropTypes from 'prop-types';
import { convertFile } from 'utils';
import classnames from 'classnames';
// import { useSelector } from 'react-redux';

const fileTypes = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'text/csv',
];

export const ImportModal = props => {
  const [state, setState] = useState({
    file: {},
    convertedCsv: [],
    error: '',
    isSaving: false,
  });
  const { file, convertedCsv, error, isSaving } = state;

  // const { user } = useSelector(_state => _state);
  const _handleClose = () =>
    setState({
      ...state,
      file: {},
      convertedCsv: [],
      error: '',
    });

  const _handleFileChange = async e => {
    const selected = e.target.files[0];
    if (selected) {
      if (fileTypes.some(fl => fl === selected.type)) {
        const convertedCsv = await convertFile(selected, 'toCsv');
        setState({ ...state, file: selected, convertedCsv, error: '' });
      } else {
        const errorMessage = 'Please kindly import a CSV file only';
        setState({
          ...state,
          file: selected,
          error: errorMessage,
          convertedCsv: [],
        });
      }
    }
  };

  const onClose = () => setState({ ...state, error: '' });

  const onSubmit = async () => {
    // const payload = {
    //   data: convertedCsv,
    //   section: props.section || '',
    //   parentOrganizationId,
    //   operatorId: userId
    // };
    // setState({ ...state, isSaving: true });
    // try {
    //   const { data } = await appStitch.instance('bulkCreate', [payload]);
    //   setState({ ...state, isSaving: false });
    //   if (data) {
    //     props.toggle();
    //     props.importFileSuccess(data);
    //     _notifySuccess('Data uploaded successfully!');
    //   } else {
    //     _notifyError('Bulk create unsuccessful, please try again');
    //   }
    // } catch (error) {
    //   setState({ ...state, isSaving: false });
    // }
  };

  return (
    <SettingsModal
      toggle={() => {
        _handleClose();
        props.toggle();
      }}
      {...props}
      title="Import File"
      size="md"
      subtitle={`Import file for ${props.page}`}
    >
      <div className="container-fluid">
        {error ? <AuthErrorCard error={error} onClose={() => onClose()} /> : null}
        <Form onSubmit={e => e.preventDefault()} className="form__settings form">
          <div className="row">
            <div className="col-md-12">
              <UploadFile onChange={_handleFileChange} fileName={file.name} />
            </div>
          </div>

          <div className="form__settings-footer">
            <div className="row">
              <div className="form__settings-button">
                <Button
                  disabled={!convertedCsv.length}
                  block
                  className={classnames('btn__secondary', {
                    btn__loading: isSaving,
                  })}
                  onClick={onSubmit}
                >
                  Save file
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </SettingsModal>
  );
};

ImportModal.propTypes = {
  importFileSuccess: PropTypes.func,
  toggle: PropTypes.func,
  page: PropTypes.string,
  section: PropTypes.string,
};
