import React, { lazy } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import MainLayout from '../../../layouts/MainLayout';
import AuthRoute from '../../../routes/AuthRoute';

const Backlog = lazy(() => import('../pages/Backlog'));
const Report = lazy(() => import('../pages/Report'));
const TaskManagement = lazy(() => import('../pages/Task-management'));
const ErrorPage = lazy(() => import('../../../pages/Error'));

const LaboratoryRoutes = ({ match }) => {
  const { loggedIn } = useSelector((state) => state.auth);
  // TODO check accessGranted value using the modules for the current branch

  return (
    <>
      <Switch>
        <Redirect exact from={`${match.path}`} to={`${match.path}/task-management`} />
        <AuthRoute
          exact
          path={`${match.path}`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={TaskManagement}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/task-management`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={TaskManagement}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/backlog`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Backlog}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/report`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Report}
          accessGranted={true}
        />

        <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
      </Switch>
    </>
  );
};

LaboratoryRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default LaboratoryRoutes;
