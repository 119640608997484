import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import { TaskCard } from '../components';
import { fetchTasksPerStatus } from '../redux/actions';
import { handlePagination } from '../pages/helpers';

export const Loader = () => {
    return (
        <div className={'d-flex w-100 justify-content-center'}>
            <div className="page-loader__dot red" />
            <div className="page-loader__dot red" />
            <div className="page-loader__dot red" />
        </div>
    )
}

const Scroller = ({ taskObject, dispatch, taskStatus, viewSingleTask, showStatus }) => {
    return (
        <InfiniteScroll
            dataLength={taskObject?.docs.length || 0}
            next={() => handlePagination(taskObject?.totalPages,
                taskObject?.page, dispatch, fetchTasksPerStatus, taskStatus, taskObject?.docs)}
            hasMore={taskObject?.hasNextPage || false}
            loader={<Loader />}
            scrollThreshold={1}
            className={'w-100'}
            height={600}
            endMessage={
                <p className="color-grey text-center">
                    <small>{`${taskObject?.docs.length} of ${taskObject?.totalDocs} tasks fetched`}</small>
                </p>
            }
        >
            {taskObject && taskObject.totalDocs
                ? taskObject.docs.map(task => (
                    <TaskCard
                        key={task._id}
                        {...task}
                        type="todo"
                        showDeadline={true}
                        showStatus={showStatus}
                        onClick={() => viewSingleTask(task)}
                    />
                ))
                : null}
        </InfiniteScroll>
    )
}

Scroller.propTypes = {
    taskObject: PropTypes.object,
    dispatch: PropTypes.func,
    taskStatus: PropTypes.string,
    viewSingleTask: PropTypes.func,
    showStatus: PropTypes.bool
}

export default Scroller;