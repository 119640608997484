import React from 'react';
import logo from 'assets/icon/indigo-logo.png';
import PropTypes from 'prop-types';

export const NavBar = ({ drawerAction }) => {
  return (
    <div className="main-nav">
      <i className="iconmoon icon-menu" onClick={() => drawerAction()} />

      <div className="main-nav__logo">
        <img src={logo} alt="Indigo EMR" />
      </div>

      <i className="iconmoon icon-notification">
        <span className="path1" />
        <span className="path2" />
        <span className="path3" />
      </i>
    </div>
  );
};

NavBar.propTypes = {
  drawerAction: PropTypes.func
};
