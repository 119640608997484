import React, { lazy, useEffect } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import MainLayout from '../../../layouts/MainLayout';
import AuthRoute from '../../../routes/AuthRoute';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { fetchBranches, setCurrency, fetchSponsors } from 'redux/actions';
import { fetchBanks, fetchSubscriptions } from '../../settings/redux/actions';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const Patients = lazy(() => import('../pages/Patients'));
const Transactions = lazy(() => import('../pages/Transactions'));
const Insight = lazy(() => import('../pages/Insight'));
const ServicesReport = lazy(() => import('../pages/Insight/report/Services'));
const ConsumablesReport = lazy(() => import('../pages/Insight/report/Consumables'));
const HMOReport = lazy(() => import('../pages/Insight/report/HMO'));

const Hmo = lazy(() => import('../pages/Hmo'));
const PatientDetailsRoutes = lazy(() => import('../../patients/pages/Patients/PatientDetails'));

const ErrorPage = lazy(() => import('pages/Error'));

const FinanceRoutes = ({ match }) => {
  const dispatch = useDispatch();

  const { loggedIn } = useSelector(state => state.auth);
  const branchId = useSelector(store => store.user?.current?.branchId);
  const currency = useSelector(store => store.branches?.items?.find(e => e._id === branchId)?.currency?.code);

  useEffect(() => {
    dispatch(fetchBranches());
    dispatch(fetchSponsors());
    dispatch(fetchBanks());
    dispatch(fetchSubscriptions());
  }, []);

  useEffect(() => {
    if (!currency) return;
    dispatch(setCurrency(currency));
  }, [currency]);

  return (
    <>
      <Switch>
        <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />

        <AuthRoute
          exact
          path={`${match.path}/dashboard`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Dashboard}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/patients`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Patients}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/transactions`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Transactions}
          accessGranted={true}
        />
        <AuthRoute
          exact
          path={`${match.path}/claims`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Hmo}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/patients/:id`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={PatientDetailsRoutes}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/insight`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Insight}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/insight/hmos`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={HMOReport}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/insight/services`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={ServicesReport}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/insight/consumables`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={ConsumablesReport}
          accessGranted={true}
        />

        <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
      </Switch>
    </>
  );
};

FinanceRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default FinanceRoutes;
