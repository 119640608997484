import { _notifyError, _notifySuccess } from 'utils';
import * as inventoryTypes from '../types/inventoryTypes';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const getPricelistStart = () => ({
  type: inventoryTypes.GET_PRICELISTS_START,
});

const getPricelistFailure = () => ({
  type: inventoryTypes.GET_PRICELISTS_FAILURE,
});

const getPricelistSuccess = (payload) => ({
  type: inventoryTypes.GET_PRICELISTS,
  payload,
});

export const getPricelist = (queryParams) => {
  return async (dispatch) => {
    dispatch(getPricelistStart());
    try {
      const { data } = await Axios.get(`/pricelists${queryParams ? `?${queryParams}` : ''}`);

      if (data.status) {
        dispatch(getPricelistSuccess(data.data));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getPricelistFailure());
    }
  };
};

const createPricelistStart = () => ({ type: inventoryTypes.CREATE_PRICELIST_START });

const createPricelistFailure = (error) => ({
  type: inventoryTypes.CREATE_PRICELIST_FAILURE,
  error,
});

const createPricelistSuccess = (payload) => ({
  type: inventoryTypes.CREATE_PRICELIST_SUCCESS,
  payload,
});

export const createPricelist = ({ payload, sponsorId }, close) => {
  return async (dispatch) => {
    dispatch(createPricelistStart());

    try {
      const { data } = await Axios.post(`/sponsors/${sponsorId}/pricelists`, payload);
      const cache = data.data;
      if (data.status) {
        dispatch(createPricelistSuccess(cache));
        _notifySuccess('Created Pricelist successfully.');
        close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(createPricelistFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updatePricelistStart = () => ({ type: inventoryTypes.UPDATE_PRICELIST_START });

const updatePricelistFailure = (error) => ({
  type: inventoryTypes.UPDATE_PRICELIST_FAILURE,
  error,
});

const updatePricelistSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_PRICELIST_SUCCESS,
  payload,
});

export const updatePricelist = (payload, close) => {
  const { _id, ...rest } = payload;

  return async (dispatch) => {
    dispatch(updatePricelistStart());
    try {
      const { data } = await Axios.patch(`/pricelists/${_id}`, { ...rest });
      if (data.status) {
        const cache = { ...data.data, ...payload };
        dispatch(updatePricelistSuccess(cache));
        _notifySuccess(`Updated Pricelist successfully.`);
        close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(updatePricelistFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const deletePricelistStart = () => ({ type: inventoryTypes.DELETE_PRICELIST_START });

const deletePricelistFailure = (error) => ({
  type: inventoryTypes.DELETE_PRICELIST_FAILURE,
  error,
});

const deletePricelistSuccess = (payload) => ({
  type: inventoryTypes.DELETE_PRICELIST_SUCCESS,
  payload,
});

export const deletePricelist = (_id) => {
  return async (dispatch) => {
    dispatch(deletePricelistStart());
    try {
      const { data } = await Axios.delete(`/pricelists/${_id}`);
      if (data.status) {
        dispatch(deletePricelistSuccess(_id));
        _notifySuccess(`Pricelist successfully deleted.`);
        // close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(deletePricelistFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};
