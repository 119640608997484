import { updateObject } from 'utils';
import * as sponsorTypes from '../types/sponsorTypes';
import initialState from 'redux/reducers/initialState';

export const sponsors = (state = initialState.company, action) => {
  switch (action.type) {
    case sponsorTypes.FETCH_SPONSOR_START:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case sponsorTypes.FETCH_SPONSOR_SUCCESS:
      return {
        ...state,
        ...action.payload,
        error: null,
        loading: false,
      };
    case sponsorTypes.FETCH_SPONSOR_FAILED:
      return { ...state, loading: false };

    case sponsorTypes.ADD_SPONSOR_START:
      return updateObject(state, { isSaving: true });

    case sponsorTypes.ADD_SPONSOR_FAILED:
      return updateObject(state, { isSaving: false });

    case sponsorTypes.ADD_SPONSOR_SUCCESS:
      return updateObject(state, action.payload);

    case sponsorTypes.UPDATE_SPONSORS_LIST:
      return updateObject(state, action.payload);

    case sponsorTypes.UPLOAD_SPONSORS_SUCCESS:
      return updateObject(state, action.payload);

    case sponsorTypes.UPDATE_SPONSOR_START:
      return updateObject(state, action.payload);

    case sponsorTypes.UPDATE_SPONSOR_SUCCESS:
      return updateObject(state, {
        ...action.payload,
        isSaving: false,
        isDeactivating: false,
      });

    case sponsorTypes.UPDATE_SPONSOR_FAILED:
      return updateObject(state, { isSaving: false, isDeactivating: false });

    default:
      return state;
  }
};

export default sponsors;
