import { updatedState, _notifyError, _notifySuccess, _sortItems, _transformToSelectOptions } from 'utils';
import * as roleTypes from '../types/roleTypes';
import { beginApiCall, endApiCall } from './apiActions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const fetchRolesSuccess = (payload) => ({
  type: roleTypes.FETCH_ROLES_SUCCESS,
  payload,
});

const fetchRolesFailed = (msg) => ({
  type: roleTypes.FETCH_ROLES_FAILED,
  error: msg,
});

export const fetchRoles = () => async (dispatch) => {
  dispatch(beginApiCall());

  const { data } = await Axios.get('/roles');
  const { data: permsData } = await Axios.get('/permissions');

  if (data.status && permsData.status) {
    const transformed = _transformToSelectOptions(permsData.data, 'display_title', 'title');

    const payload = {
      items: _sortItems([...data.data], 'title'),
      filteredItems: _sortItems([...data.data], 'title'),
      primaryOptions: ['permissions'],
      secondaryOptions: [...permsData.data].map((permission) => permission.display_title),
      permissions: transformed,
    };

    dispatch(fetchRolesSuccess(payload));
  } else {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchRolesFailed(data.message));
  }
  dispatch(endApiCall());
};

const addRoleStart = () => ({ type: roleTypes.ADD_ROLE_START });

const addRoleSuccess = (payload) => ({
  type: roleTypes.ADD_ROLE_SUCCESS,
  payload,
});

const addRoleFailed = (msg) => ({
  type: roleTypes.ADD_ROLE_FAILED,
  error: msg,
});

export const addRole = (current, arr, close) => {
  return async (dispatch) => {
    dispatch(addRoleStart());

    try {
      const { data } = await Axios.post('/roles', current);

      if (data.status) {
        const cache = _sortItems([...arr].concat(data.data), 'title');
        dispatch(addRoleSuccess(updatedState(cache)));
        _notifySuccess('Role successfully added.');
        close();
      }
    } catch ({ response }) {
      const errMsg = typeof response?.data === 'string' ? response.data : response?.data?.message || '';
      dispatch(addRoleFailed(errMsg || CUSTOM_ERROR_MESSAGE));
      _notifyError(errMsg || CUSTOM_ERROR_MESSAGE);
    }
  };
};

const updateRoleStart = (payload) => ({
  type: roleTypes.UPDATE_ROLE_START,
  payload,
});

const updateRoleSuccess = (payload) => ({
  type: roleTypes.UPDATE_ROLE_SUCCESS,
  payload,
});

const updateRoleFailed = (msg) => ({
  type: roleTypes.UPDATE_ROLE_FAILED,
  error: msg,
});

export const updateSelectedRole = (current, arr, close, action) => {
  return async (dispatch) => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const { title, isActive, permissions } = current;

    dispatch(updateRoleStart(actionType));
    try {
      const { data } = await Axios.patch(`/roles/${current._id}`, {
        title,
        isActive,
        permissions,
      });

      if (data.status) {
        const cache = [...arr].map((prev) => (String(prev._id) === String(current._id) ? current : prev));

        dispatch(updateRoleSuccess(updatedState(cache)));
        _notifySuccess(`Role ${action || 'updated'} successfully.`);
        close();
      }
    } catch ({ response }) {
      const errMsg = typeof response?.data === 'string' ? response.data : response?.data?.message || '';
      dispatch(updateRoleFailed(errMsg || CUSTOM_ERROR_MESSAGE));
      _notifyError(errMsg || CUSTOM_ERROR_MESSAGE);
    }
  };
};

const deleteRoleStart = () => ({
  type: roleTypes.DELETE_ROLE_START,
});

const deleteRoleSuccess = (payload) => ({
  type: roleTypes.DELETE_ROLE_SUCCESS,
  payload,
});

const deleteRoleFailed = (msg) => ({
  type: roleTypes.DELETE_ROLE_FAILED,
  error: msg,
});

export const deleteRole = (id, arr, close) => {
  return async (dispatch) => {
    dispatch(deleteRoleStart());
    try {
      const { data } = await Axios.patch(`/roles/${id}`);
      if (data.status) {
        const cache = [...arr].filter((prev) => String(prev._id) !== String(id));

        dispatch(
          deleteRoleSuccess({
            items: cache,
            filteredItems: cache,
          }),
        );
        _notifySuccess(`Role deleted successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(deleteRoleFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const rolesPageSort = (arr) => {
  return {
    type: roleTypes.ROLES_PAGE_SORT,
    payload: {
      items: arr,
      filteredItems: arr,
    },
  };
};

export const updateRoleList = (value) => ({
  type: roleTypes.UPDATE_ROLES_LIST,
  payload: {
    filteredItems: value,
  },
});
