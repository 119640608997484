/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AddMoreTable, AuthErrorCard, FormInput } from 'components';
import { Button, Form } from 'reactstrap';
import classnames from 'classnames';
import { _notifyError, _validateForm, isObjectEmpty, isStringEmpty } from 'utils';
import * as actionTypes from 'redux/types/utilityTypes';
import { useDispatch, useSelector } from 'react-redux';
import { addSupplier, updateSelectedSupplier } from 'redux/actions';

export const SaveSupplier = ({ entity, toggle }) => {
  const dispatch = useDispatch();
  const { suppliers } = useSelector(store => store);
  const { isSaving, formErrorMessage, items } = suppliers;

  const initialItem = {};

  const itemDetails = entity._id
    ? {
        ...entity,
      }
    : initialItem;

  const [state, setState] = useState({
    form: itemDetails,
    errorState: {},
    supplier: [],
  });

  const { form, supplier, errorState } = state;

  const handleAttributeChange = (value, attribute, countryCode = {}) => {
    const formStatus = _validateForm(value, attribute, form, errorState, countryCode?.dialCode);
    setState({
      ...state,
      form: formStatus.obj,
      errorState: formStatus.errorState,
    });
  };

  const isDisabled = () => {
    let status;

    const identical = supplier.find(item => String(item.name).trim() === String(form.name).trim()) === undefined;
    const multi = supplier.length > 0;
    const isFormEmpty = isStringEmpty(form.name) && isStringEmpty(form.phoneNumber) && !errorState.email;
    const nonEmptyFormComparism =
      !errorState.name &&
      !isStringEmpty(form.name) &&
      !errorState.phoneNumber &&
      !isStringEmpty(form.phoneNumber) &&
      !errorState.email &&
      !isStringEmpty(form.email);

    let condition;

    if (multi) {
      condition = isFormEmpty ? true : nonEmptyFormComparism && identical;
    } else {
      condition = nonEmptyFormComparism;
    }

    condition ? (status = true) : (status = false);
    return status;
  };

  const add = newSupplier => {
    let payload = Object.entries(newSupplier).map(([, value]) => value);
    if (!isObjectEmpty(form)) {
      payload.push(form);
    }

    const error = items.find(item => {
      return [...payload].find(
        load =>
          load.name.toLowerCase() === item.name.toLowerCase() ||
          load.email.toLowerCase() === (item.email || '').toLowerCase(),
      );
    });
    if (error) {
      _notifyError(`Error: Supplier "${error.name}" with email "${error.email}" already exists.`);
      return;
    }

    dispatch(addSupplier(payload, items || [], toggle));
  };

  const handleUpdate = (selected, type) => {
    const payload = { name: selected.name, values: selected.values };
    const updatedItem = { ...selected, ...payload };
    // const toggle_ = toggleViewModal ? toggleViewModal : toggle;

    const error = items.find(
      item =>
        item.name.toLowerCase() !== entity.name.toLowerCase() &&
        (item.name.toLowerCase() === selected.name.toLowerCase() ||
          item.email.toLowerCase() === selected.email.toLowerCase()),
    );

    if (error) {
      _notifyError(`Error: Supplier "${error.name}" already exists.`);
      return;
    }

    if (type === 'edit') {
      dispatch(updateSelectedSupplier(selected._id, payload, updatedItem, toggle, 'updated'));
    }
  };

  const onClose = () => dispatch({ type: actionTypes.CLOSE_FORM_ERROR });

  const _onSubmit = payload => {
    if (entity._id) {
      handleUpdate(form, 'edit');
    } else {
      add(payload);
    }
  };

  const addMoreCondition = () => {
    let status;

    const identical = supplier.find(item => String(item.name).trim() === String(form.name).trim()) === undefined;
    const isFormEmpty = isStringEmpty(form.name) && isStringEmpty(form.phoneNumber) && !errorState.email;
    const nonEmptyFormComparism =
      !errorState.name &&
      !isStringEmpty(form.name) &&
      !errorState.phoneNumber &&
      !isStringEmpty(form.phoneNumber) &&
      !errorState.email &&
      !isStringEmpty(form.email);

    let condition;

    if (isFormEmpty) {
      condition = false;
    } else {
      condition = nonEmptyFormComparism && identical;
    }

    condition ? (status = true) : (status = false);
    return status;
  };

  const unSelectSupplier = selected => {
    const updatedSuppliers = supplier.filter(test => test.name !== selected);
    setState(prevState => ({
      ...prevState,
      supplier: updatedSuppliers,
    }));
  };

  return (
    <>
      <div className="container-fluid">
        {formErrorMessage && <AuthErrorCard error={formErrorMessage} onClose={onClose} />}
        <Form onSubmit={e => e.preventDefault()} className="form__settings form">
          <div className="row">
            <div className="col-md-6">
              <FormInput
                id="name"
                label="Supplier name"
                isRequired={true}
                placeholder="Enter supplier name"
                className={errorState.name ? 'is-invalid' : null}
                type="text"
                value={form.name}
                onChange={e => handleAttributeChange(e.target.value, 'name')}
              />
            </div>

            <div className="col-md-6">
              <FormInput
                id="phone"
                label="Phone Number"
                className={errorState.phoneNumber ? 'is-invalid' : null}
                isRequired={true}
                placeholder="e.g 8111111111"
                type="tel"
                value={form.phoneNumber}
                onChange={(e, data) => handleAttributeChange(e, 'phoneNumber', data)}
              />
            </div>

            <div className="col-12">
              <FormInput
                id="email"
                label="Email"
                className={errorState.email ? 'is-invalid' : null}
                isRequired={true}
                placeholder="Enter email address"
                type="text"
                value={form.email}
                onChange={e => handleAttributeChange(e.target.value, 'email')}
              />
            </div>
          </div>

          {!entity._id && (
            <div className="col-md-12">
              <button
                className="btn department__button"
                onClick={() =>
                  addMoreCondition() &&
                  setState(prevState => ({
                    ...prevState,
                    supplier: [...supplier, form],
                    errorState: {},
                    form: {},
                  }))
                }
              >
                <span className="mr-3">
                  <i className="fas fa-plus" />
                </span>
                Add Another Supplier
              </button>
            </div>
          )}

          {supplier.length > 0 && (
            <AddMoreTable heading={['Supplier', 'Phone', 'Email']}>
              {supplier.map(individual => {
                return (
                  <tr key={individual.name}>
                    <td className="pr-5 pt-3">{individual.name}</td>
                    <td className="pr-4 pt-3">{individual.phoneNumber}</td>
                    <td className="pr-4 pt-3">{individual.email}</td>
                    <td className="pr-4 pt-3" onClick={() => unSelectSupplier(individual.name)}>
                      <i className="far fa-trash-alt"></i>
                    </td>
                  </tr>
                );
              })}
            </AddMoreTable>
          )}

          <div className="form__settings-footer">
            <div className="row justify-content-center flex-gap-2  mt-5  mt-sm-0">
              <div className="form__settings-button minimal">
                <Button onClick={toggle} block className={classnames('btn__discard')}>
                  Discard
                </Button>
              </div>

              <div className="form__settings-button minimal">
                <Button
                  block
                  disabled={!isDisabled()}
                  className={classnames('btn__secondary', {
                    btn__loading: isSaving,
                  })}
                  onClick={() => _onSubmit(supplier)}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

SaveSupplier.propTypes = {
  entity: PropTypes.object,
  toggle: PropTypes.func,
};
