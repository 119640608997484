// import { updateCategoryDepartments } from 'actions/labs/categories';
import { updatedState, _notifySuccess, _sortItems, _notifyError } from 'utils';
import * as departmentTypes from '../types/departmentTypes';
import { beginApiCall, endApiCall } from './apiActions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const fetchDepartmentsSuccess = (payload) => ({
  type: departmentTypes.FETCH_DEPARTMENTS_SUCCESS,
  payload,
});

const fetchDepartmentsFailed = (msg) => ({
  type: departmentTypes.FETCH_DEPARTMENTS_FAILED,
  error: msg,
});

const fetchPatientsDepartmentsSuccess = (payload) => ({
  type: departmentTypes.FETCH_PATIENTS_DEPARTMENTS_SUCCESS,
  payload,
});

const fetchPatientsDepartmentsFailed = (msg) => ({
  type: departmentTypes.FETCH_PATIENTS_DEPARTMENTS_FAILED,
  error: msg,
});

export const fetchDepartments = () => {
  return async (dispatch) => {
    dispatch(beginApiCall());
    const { data } = await Axios.get('/departments');
    if (data.status) {
      const sortData = _sortItems(data.data, 'name');
      const payload = {
        items: sortData,
        filteredItems: sortData,
      };
      dispatch(fetchDepartmentsSuccess(payload));
      dispatch(endApiCall());
    } else {
      dispatch(fetchDepartmentsFailed(data.message));
      dispatch(endApiCall());
    }
  };
};

export const fetchPatientsDepartments = (searchQuery) => async (dispatch) => {
  dispatch(beginApiCall());
  let url = '/patient-departments';
  if (searchQuery) url += `?search=${searchQuery}`;

  try {
    const { data } = await Axios.get(url);
    if (data.status) {
      const sortData = _sortItems(data.data, 'name');
      const payload = {
        items: sortData,
      };
      if (!searchQuery) {
        dispatch(fetchPatientsDepartmentsSuccess(payload));
      }
      dispatch(endApiCall());
      return Promise.resolve(sortData);
    }
  } catch (error) {
    dispatch(fetchPatientsDepartmentsFailed(error?.response?.message || 'Sonething went wrong'));
    dispatch(endApiCall());
  }
};

export const departmentUploadSuccess = (data) => ({
  type: departmentTypes.UPLOAD_DEPARTMENTS_SUCCESS,
  payload: data,
});

const addDepartmentStart = () => ({
  type: departmentTypes.ADD_DEPARTMENT_START,
});

const addDepartmentSuccess = (payload) => ({
  type: departmentTypes.ADD_DEPARTMENT_SUCCESS,
  payload,
});

const addDepartmentFailed = (msg) => ({
  type: departmentTypes.ADD_DEPARTMENT_FAILED,
  error: msg,
});

export const addDepartment = (departments, arr, onSuccess) => {
  return async (dispatch) => {
    dispatch(addDepartmentStart());
    try {
      const { data } = await Axios.post('/departments', departments);
      if (data.status) {
        const updatedDepartments = [...arr].concat([...data.data]);
        const cache = _sortItems(updatedDepartments, 'name');
        dispatch(addDepartmentSuccess(updatedState(cache)));

        // /<----- Do this for category update --->/
        // dispatch(updateCategoryDepartments(cache));
        // /<-----  --->/

        onSuccess();
        _notifySuccess('Department(s) successfully added.');
      }
    } catch ({ response }) {
      const errMsg = typeof response?.data === 'string' ? response.data : response?.data?.message || '';
      dispatch(addDepartmentFailed(errMsg || CUSTOM_ERROR_MESSAGE));
      _notifyError(errMsg || CUSTOM_ERROR_MESSAGE);
    }
  };
};

const updateDepartmentStart = (payload) => ({
  type: departmentTypes.UPDATE_DEPARTMENT_START,
  payload,
});

const updateDepartmentSuccess = (payload) => ({
  type: departmentTypes.UPDATE_DEPARTMENT_SUCCESS,
  payload,
});

const updateDepartmentFailed = (msg) => ({
  type: departmentTypes.UPDATE_DEPARTMENT_FAILED,
  error: msg,
});

export const updateSelectedDepartment = (id, current, arr, close, action) => {
  return async (dispatch) => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };

    dispatch(updateDepartmentStart(actionType));
    try {
      const { data } = await Axios.patch(`/departments/${id}`, current);

      if (data.status === 'true') {
        const cache = [...arr].map((prev) => (String(prev._id) === String(id) ? { ...prev, ...current } : prev));

        dispatch(updateDepartmentSuccess(updatedState(cache)));
        _notifySuccess(`Department ${action || 'updated'} successfully.`);
        close();
      }
    } catch ({ response }) {
      const errMsg = typeof response?.data === 'string' ? response.data : response?.data?.message || '';
      dispatch(updateDepartmentFailed(errMsg || CUSTOM_ERROR_MESSAGE));
      _notifyError(errMsg || CUSTOM_ERROR_MESSAGE);
    }
  };
};

const deleteDepartmentStart = () => ({
  type: departmentTypes.DELETE_DEPARTMENT_START,
});

const deleteDepartmentSuccess = (payload) => ({
  type: departmentTypes.DELETE_DEPARTMENT_SUCCESS,
  payload,
});

const deleteDepartmentFailed = (msg) => ({
  type: departmentTypes.DELETE_DEPARTMENT_FAILED,
  error: msg,
});

export const deleteDepartment = (id, arr, close) => {
  return async (dispatch) => {
    dispatch(deleteDepartmentStart());

    try {
      const { data } = await Axios.delete(`/departments/${id}`);
      if (!data.status) {
        _notifyError(data.message);
        dispatch(deleteDepartmentFailed(CUSTOM_ERROR_MESSAGE));
      }
      if (data.status) {
        const cache = [...arr].filter((prev) => String(prev._id) !== String(id));
        dispatch(
          deleteDepartmentSuccess({
            items: cache,
            filteredItems: cache,
          }),
        );
        _notifySuccess(`Department deleted successfully.`);
        close();
      } else {
        _notifyError(data.message);
        dispatch(deleteDepartmentFailed(CUSTOM_ERROR_MESSAGE));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      _notifyError(errorMessage);
      dispatch(deleteDepartmentFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const departmentsPageSort = (arr) => {
  return {
    type: departmentTypes.DEPARTMENTS_PAGE_SORT,
    payload: {
      items: arr,
      filteredItems: arr,
    },
  };
};

export const updateDepartmentList = (value) => ({
  type: departmentTypes.UPDATE_DEPARTMENTS_LIST,
  payload: {
    filteredItems: value,
  },
});
