import { _notifyError, _notifySuccess, printError } from 'utils';
import * as companyTypes from '../types/companyTypes';
import Axios from 'services/axios';
import { setAuthData } from '.';
import { MODIFY_USER_DATA } from 'redux/types/userTypes';

// export const beginApiCall = () => ({
//   type: actionTypes.START_API_CALL
// });

// export const endApiCall = () => ({
//   type: actionTypes.END_API_CALL
// });

const fetchCompanyStart = () => ({
  type: companyTypes.FETCH_COMPANY_START,
});

const fetchCompanySuccess = (payload) => ({
  type: companyTypes.FETCH_COMPANY_SUCCESS,
  payload,
});

const fetchCompanyFailed = (msg) => ({
  type: companyTypes.FETCH_COMPANY_FAILED,
  error: msg,
});

export const fetchCompany = (id) => async (dispatch) => {
  dispatch(fetchCompanyStart());
  const { data } = await Axios.get(`/organizations/${id}`);

  if (!data.status) {
    dispatch(fetchCompanyFailed());
  } else {
    dispatch(fetchCompanySuccess(data.data));
  }
};

const updateColors = (payload) => ({
  type: companyTypes.UPDATE_COLORS,
  payload,
});

const updateOrganizationStart = () => ({
  type: companyTypes.UPDATE_COMPANY_START,
});

const updateOrganizationSuccess = (payload) => ({
  type: companyTypes.UPDATE_COMPANY_SUCCESS,
  payload,
});

const updateOrganizationFailed = (msg) => ({
  type: companyTypes.UPDATE_COMPANY_FAILED,
  error: msg,
});

export const updateOnboarding = (updateDetails, update) => async (dispatch) => {
  dispatch(updateOrganizationStart());

  const { data } = await Axios.patch('/organizations', {
    nextSection: updateDetails.payload.nextSection,
    logo: updateDetails.payload.logo,
    primaryColour: updateDetails.payload.primaryColour,
    secondaryColour: updateDetails.payload.secondaryColour,
  });

  try {
    if (data.status) {
      _notifySuccess(`${updateDetails.currentPage} set up successfully`);

      dispatch(
        updateColors({
          primaryColour: updateDetails.payload.primaryColour,
          secondaryColour: updateDetails.payload.secondaryColour,
        }),
      );

      if (update === undefined) {
        dispatch(updateOrganizationSuccess({ ...data.data }));
        const url =
          updateDetails.payload.nextSection === false
            ? '/modules'
            : `/onboarding/configuration?tab=${updateDetails.payload.nextSection || 'branches'}`;

        window.location = url;
      } else {
        dispatch(updateOrganizationSuccess({ ...data.data, nextSection: false }));
      }
    }
  } catch (error) {
    dispatch(updateOrganizationFailed(data.message));
    _notifyError(`${updateDetails.currentPage} could not be set up, please try again`);
  }
};

const fetchOrganizationCustomizationStart = () => ({
  type: companyTypes.FETCH_COMPANY_CUSTOMIZATION_START,
});

const fetchOrganizationCustomizationSuccess = (payload) => ({
  type: companyTypes.FETCH_COMPANY_CUSTOMIZATION_SUCCESS,
  payload,
});

const fetchOrganizationCustomizationFailed = (msg) => ({
  type: companyTypes.FETCH_COMPANY_CUSTOMIZATION_FAILED,
  error: msg,
});

export const fetchOrganizationCustomization = () => async (dispatch) => {
  dispatch(fetchOrganizationCustomizationStart());
  const { data } = await Axios.get(`/get-config`);

  if (!data.status) {
    dispatch(fetchOrganizationCustomizationFailed());
  } else {
    dispatch(fetchOrganizationCustomizationSuccess(data.data));
  }
};

const updateOrganizationCustomizationStart = () => ({
  type: companyTypes.UPDATE_COMPANY_CUSTOMIZATION_START,
});

const updateOrganizationCustomizationSuccess = (payload) => ({
  type: companyTypes.UPDATE_COMPANY_CUSTOMIZATION_SUCCESS,
  payload,
});

const updateOrganizationCustomizationFailed = (msg) => ({
  type: companyTypes.UPDATE_COMPANY_CUSTOMIZATION_FAILED,
  error: msg,
});

export const updateOrganizationCustomization = (updateDetails) => async (dispatch) => {
  dispatch(updateOrganizationCustomizationStart());
  try {
    const { data } = await Axios.patch('/organizations', {
      registrationSettings: updateDetails,
    });
    if (data.status) {
      dispatch(updateOrganizationCustomizationSuccess(data.data.registrationSettings));
      _notifySuccess('Organization updated successfuly');
    }
  } catch (error) {
    dispatch(updateOrganizationCustomizationFailed(error.message));
    _notifyError('Organization could not be updated, please try again');
  }
};

export const fetchLocations = (email) => async (dispatch) => {
  try {
    const { data } = await Axios.get(`/locations?email=${email}`);
    if (data.status) {
      const payload = data.data.sort((_, b) => (b.parent ? 1 : -1)).map((e) => ({ ...e, label: e.name, value: e._id }));
      dispatch({
        type: companyTypes.FETCH_LOCATIONS,
        payload,
      });
      dispatch(setAuthData({ orgBranches: payload }));
    }
  } catch (e) {
    const errorMsg = printError(e);
    // eslint-disable-next-line no-console

    _notifyError(errorMsg);
  }
};

export const fetchLocation = (id) => async (dispatch) => {
  const { data } = await Axios.post(`/locations/${id}/select`);
  const locationData = data.data;

  if (data.status) {
    dispatch({
      type: companyTypes.FETCH_LOCATION,
      payload: data.data,
    });
    dispatch(
      setAuthData({
        current: {
          parentOrganizationId: locationData.parentOrganizationId,
          label: locationData.currentLocation.name,
          value: locationData.currentLocation._id,
          nextSection: locationData.currentLocation.nextSection,
          permissions: locationData.currentLocation.permissions,
          details: {
            name: locationData.currentLocation.staff.name,
            email: locationData.email,
            userId: locationData._id,
            avatar: locationData.avatar,
          },
          modules: locationData.currentLocation.modules,
          branchId: locationData.currentLocation._id,
          departments: locationData.currentLocation.departments,
        },
      }),
    );
    dispatch({
      type: MODIFY_USER_DATA,
      payload: locationData.currentLocation,
    });

    return data.data;
  }
};
