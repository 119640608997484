import { _notifyError, _notifySuccess, _sortItems } from 'utils';
import Axios from 'services/axios';
import * as branchTypes from '../types/branchTypes';
import { beginApiCall, endApiCall } from './apiActions';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const updatedState = (data) => ({
  isSaving: false,
  error: '',
  items: data,
  filteredItems: data,
  newBranch: { currency: {} },
});

const fetchBranchesSuccess = (payload) => ({
  type: branchTypes.FETCH_BRANCHES_SUCCESS,
  payload,
});

const fetchBranchesFailed = (msg) => ({
  type: branchTypes.FETCH_BRANCHES_FAILED,
  error: msg,
});

export const fetchBranches = () => async (dispatch) => {
  dispatch(beginApiCall());
  const { data } = await Axios.get(`/branches`);
  if (!data.status) {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchBranchesFailed(data.message));
  } else {
    const activeBranches = data.data.filter((item) => item.isActive);
    const payload = {
      items: _sortItems([...activeBranches], 'name'),
      filteredItems: _sortItems([...activeBranches], 'name'),
    };
    dispatch(endApiCall());
    dispatch(fetchBranchesSuccess(payload));
  }
};

export const branchUploadSuccess = (data) => ({
  type: branchTypes.UPLOAD_BRANCHES_SUCCESS,
  payload: data,
});

const addBranchStart = () => ({ type: branchTypes.ADD_BRANCH_START });

const addBranchSuccess = (payload) => ({
  type: branchTypes.ADD_BRANCH_SUCCESS,
  payload,
});

const addBranchFailed = (msg) => ({
  type: branchTypes.ADD_BRANCH_FAILED,
  error: msg,
});

export const addBranch = (current, arr, close) => {
  return async (dispatch) => {
    dispatch(addBranchStart());
    try {
      const { data } = await Axios.post(`/branches`, current);
      if (data.status) {
        const cache = _sortItems([...arr].concat(data.data), 'name');
        dispatch(addBranchSuccess(updatedState(cache)));
        _notifySuccess('Branch successfully added.');
        close();
      }
    } catch ({ response }) {
      let errMsg = response?.data?.message;
      if (errMsg && errMsg.includes('Duplicate Error')) errMsg = `Branch email or name already exists`;
      _notifyError(errMsg || CUSTOM_ERROR_MESSAGE);
      dispatch(addBranchFailed(errMsg || CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateBranchStart = (payload) => ({
  type: branchTypes.UPDATE_BRANCH_START,
  payload,
});

const updateBranchSuccess = (payload) => ({
  type: branchTypes.UPDATE_BRANCH_SUCCESS,
  payload,
});

const updateBranchFailed = (msg) => ({
  type: branchTypes.UPDATE_BRANCH_FAILED,
  error: msg,
});

export const updateSelectedBranch = (current, arr, close, action) => {
  return async (dispatch) => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const { address, currency, email, isActive, name, phoneNumber, specialties } = current;

    dispatch(updateBranchStart(actionType));
    try {
      const { data } = await Axios.patch(`/branches/${current._id}`, {
        address,
        currency,
        email,
        isActive,
        name,
        phoneNumber,
        specialties,
      });
      if (data.status) {
        const cache = [...arr].map((prev) => (String(prev._id) === String(current._id) ? current : prev));
        close();
        dispatch(updateBranchSuccess(updatedState(cache)));
        _notifySuccess(`Branch ${action || 'updated'} successfully.`);
      }
    } catch ({ response }) {
      const errMsg = typeof response?.data === 'string' ? response.data : response?.data?.message;
      _notifyError(errMsg || CUSTOM_ERROR_MESSAGE);
      dispatch(updateBranchFailed(errMsg || CUSTOM_ERROR_MESSAGE));
    }
  };
};

const deleteBranchStart = () => ({ type: branchTypes.DELETE_BRANCH_START });

const deleteBranchSuccess = (payload) => ({
  type: branchTypes.DELETE_BRANCH_SUCCESS,
  payload,
});

const deleteBranchFailed = (msg) => ({
  type: branchTypes.DELETE_BRANCH_FAILED,
  error: msg,
});

export const deleteBranch = (id, arr, close) => {
  return async (dispatch) => {
    dispatch(deleteBranchStart());
    try {
      const { data } = await Axios.delete(`/branches/${id}`);

      if (data.status) {
        const cache = [...arr].filter((prev) => String(prev._id) !== String(id));
        dispatch(
          deleteBranchSuccess({
            items: cache,
            filteredItems: cache,
          }),
        );
        _notifySuccess(`Branch deleted successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(deleteBranchFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const branchesPageSort = (arr) => {
  return {
    type: branchTypes.BRANCHES_PAGE_SORT,
    payload: {
      items: arr,
      filteredItems: arr,
    },
  };
};

export const updateBranchList = (value) => ({
  type: branchTypes.UPDATE_BRANCHES_LIST,
  payload: {
    filteredItems: value,
  },
});
