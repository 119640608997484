import React from 'react';
import { Modal } from 'reactstrap';
import PropTypes from 'prop-types';
import { Loader } from '../common/loader';
import classnames from 'classnames';
import { FormInput } from 'components/common';

export const ConfirmModal = props => {
  return (
    <Modal
      centered
      contentClassName={`modal--confirm ${props.color} `}
      isOpen={props.isOpen}
      size={props.size}
      toggle={props.toggle}
    >
      <div ref={props.Ref}>
        <div className="modal--confirm__body">
          <h3 className="modal__title with-subtitle">{props.title}</h3>

          <p className="modal__subtitle">{props.subtitle}</p>

          {props.deliveryMethod && (
            <FormInput
              type="text"
              id={'recipient'}
              value={props.recipient}
              onChange={e => props.handleAttributeChange(e.target.value)}
              placeholder={"Enter recipient's name"}
            />
          )}

          {props.isUpdating && (
            <div className="modal__spinner">
              <Loader />
            </div>
          )}

          {props.children}
        </div>

        {props.showFooter && (
          <div className="modal--confirm__footer">
            <button
              className={classnames('btn modal--confirm__btn bold', {
                'is-loading': props.isSaving,
              })}
              disabled={props.disabled}
              onClick={props.positiveClick}
            >
              {props.positiveText}
            </button>
            <button className="btn modal--confirm__btn" onClick={props.negativeClick}>
              {props.negativeText}
            </button>
          </div>
        )}
      </div>
    </Modal>
  );
};

ConfirmModal.defaultProps = {
  color: 'blue',
  negativeText: 'No',
  positiveText: 'Yes',
  subtitle: 'Please acknowledge work order received',
  title: 'Accept?',
  size: 'lm',
  showFooter: true,
};

ConfirmModal.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSaving: PropTypes.bool,
  isUpdating: PropTypes.bool,
  negativeClick: PropTypes.func,
  negativeText: PropTypes.string,
  positiveClick: PropTypes.func,
  positiveText: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  toggle: PropTypes.func,
  size: PropTypes.string,
  Ref: PropTypes.object,
  recipient: PropTypes.string,
  deliveryMethod: PropTypes.string,
  handleAttributeChange: PropTypes.func,
  showFooter: PropTypes.bool,
};
