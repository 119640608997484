import { errorFormat, _notifyError, _notifySuccess, _sortItems } from 'utils';
import * as appointmentPurposeTypes from '../types/appointmentPurposeTypes';
import { endApiCall } from '../../../../redux/actions/apiActions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const updatedState = (data) => ({
  isSaving: false,
  error: '',
  items: data,
  filteredItems: data,
  newAppointmentPurpose: {},
});

const fetchAppointmentPurposesStart = (payload) => ({
  type: appointmentPurposeTypes.FETCH_APPOINTMENT_PURPOSES_START,
  payload,
});

const fetchAppointmentPurposesSuccess = (payload) => ({
  type: appointmentPurposeTypes.FETCH_APPOINTMENT_PURPOSES_SUCCESS,
  payload,
});

const fetchAppointmentPurposesFailed = () => ({
  type: appointmentPurposeTypes.FETCH_APPOINTMENT_PURPOSES_FAILED,
});

const addAppointmentPurposeStart = () => ({
  type: appointmentPurposeTypes.ADD_APPOINTMENT_PURPOSE_START,
});

const addAppointmentPurposeSuccess = (payload) => ({
  type: appointmentPurposeTypes.ADD_APPOINTMENT_PURPOSE_SUCCESS,
  payload,
});

const addAppointmentPurposeFailed = () => ({
  type: appointmentPurposeTypes.ADD_APPOINTMENT_PURPOSE_FAILED,
});

const updateAppointmentPurposeStart = () => ({
  type: appointmentPurposeTypes.UPDATE_APPOINTMENT_PURPOSE_START,
});

const updateAppointmentPurposesSuccess = (payload) => ({
  type: appointmentPurposeTypes.UPDATE_APPOINTMENT_PURPOSE_SUCCESS,
  payload,
});

const updateAppointmentPurposesFail = (payload) => ({
  type: appointmentPurposeTypes.UPDATE_APPOINTMENT_PURPOSE_FAILED,
  payload,
});

const deactivateAppointmentPurposeStart = (payload) => ({
  type: appointmentPurposeTypes.DEACTIVATE_APPOINTMENT_PRURPOSE_START,
  payload,
});

const deactivateAppointmentPurposeFail = (payload) => ({
  type: appointmentPurposeTypes.DEACTIVATE_APPOINTMENT_PRURPOSE_FAILED,
  payload,
});

export const fetchAppointmentPurposes = () => async (dispatch) => {
  dispatch(fetchAppointmentPurposesStart());
  const { data } = await Axios.get('/appointment-purposes');
  if (data.status === 'true') {
    const payload = _sortItems([...data.data], 'name');
    dispatch(endApiCall());
    dispatch(fetchAppointmentPurposesSuccess(updatedState(payload)));
  } else {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchAppointmentPurposesFailed(data.message));
  }
};

export const addAppointmentPurpose = (current, close) => {
  return async (dispatch) => {
    dispatch(addAppointmentPurposeStart());
    try {
      const { data } = await Axios.post('/appointment-purposes', current);
      if (data.status) {
        dispatch(addAppointmentPurposeSuccess(data.data));
        _notifySuccess('Appointment Plan successfully added.');
        close();
        return true;
      }
    } catch (error) {
      _notifyError(errorFormat(error));
      dispatch(addAppointmentPurposeFailed(CUSTOM_ERROR_MESSAGE));
      return false;
    }
  };
};

export const updateAppointmentPurpose = (current, close) => {
  return async (dispatch) => {
    dispatch(updateAppointmentPurposeStart());
    const { _id, ...apptPurpose } = current;
    try {
      const { data } = await Axios.patch(`/appointment-purposes/${_id}`, apptPurpose);
      if (data.status) {
        dispatch(updateAppointmentPurposesSuccess(data.data));
        _notifySuccess(`Appointment Plan updated successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateAppointmentPurposesFail(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const updateAppointmentPurposeList = (value) => ({
  type: appointmentPurposeTypes.UPDATE_APPOINTMENT_PURPOSE_LIST,
  payload: {
    filteredItems: value,
  },
});

export const deactivateAppointmentPurpose = (updateObj, toggle, toggleViewModal, action) => {
  return async (dispatch) => {
    dispatch(deactivateAppointmentPurposeStart());
    try {
      const { _id, ...current } = updateObj;
      const { data } = await Axios.patch(`/appointment-purposes/${_id}`, current);

      if (data.status) {
        toggle();
        toggleViewModal();
        dispatch(updateAppointmentPurposesSuccess(data.data));

        _notifySuccess(`Appointment Plan ${action} successfully.`);
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(deactivateAppointmentPurposeFail(CUSTOM_ERROR_MESSAGE));
    }
  };
};
