import React from 'react';
import PropTypes from 'prop-types';

export const DashboardHeader = ({ ...props }) => {
  const { title, subtitle, rightContent: Content, className } = props;
  return (
    <div
      className={`dashboard-header d-flex flex-column flex-sm-row justify-content-between align-items-center ${className}`}
    >
      <div>
        <h3 className="dashboard-header__title mb-3 mb-sm-0 ">{title}</h3>
        {props.subtitle && <h4 className="dashboard-header__subtitle">{subtitle}</h4>}
      </div>
      {Content && <Content />}
    </div>
  );
};

DashboardHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  rightContent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  className: PropTypes.string,
};

DashboardHeader.defaultProps = {
  className: '',
};

export const DashboardHeaderLink = (props) => {
  const { title, left, onClick } = props;

  return (
    <div className={`d-flex justify-content-${left ? 'start' : 'end'} align-items-center mt-5`}>
      <div className="dashboard-header-link" onClick={onClick}>
        {left ? (
          <>
            <span className="mr-3">
              <i className="fas fa-long-arrow-alt-left"></i>
            </span>
            {<span className="dashboard-header-link-text"> {title} </span>}
          </>
        ) : (
          <>
            {<span className="dashboard-header-link-text"> {title} </span>}
            <span className="ml-3">
              <i className="fas fa-long-arrow-alt-right"></i>
            </span>
          </>
        )}
      </div>
    </div>
  );
};

DashboardHeaderLink.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string,
  left: PropTypes.bool,
  onClick: PropTypes.func,
};

export const DashboardHeaderGoBack = (props) => {
  const { text, onClick, margin } = props;

  return (
    <div onClick={onClick} className={`dashboard-header-goback ${margin || ''}`}>
      <span>
        <i className="iconmoon icon-go-back" />
      </span>
      <p className="paragraph-big tw-font-medium color-dark ml-3 tw-whitespace-nowrap">{text || 'Go Back'}</p>
    </div>
  );
};

DashboardHeaderGoBack.propTypes = {
  text: PropTypes.string,
  margin: PropTypes.string,
  onClick: PropTypes.func,
};
