import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TabContent, Nav } from 'reactstrap';
import TabLink from './TabLink';
import TabDetails from './TabDetails';
// import Check from '../permission/Check';

export const Tabs = ({ defaultTab, path, nextSection, ...props }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const namedTabs = props.tabs.map(tab => tab.tabId);
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    const getTab = async () => {
      try {
        const { search } = window.location;
        const params = new URLSearchParams(search);
        const tab = params.get('tab') || defaultTab;

        setActiveTab(tab);
      } catch (error) {
        // TODO Display error properly
      }
    };

    getTab();
  }, [defaultTab]);

  return (
    <div className="section">
      <Nav tabs pills className="pl-0 pl-md-5">
        {props.tabs.map((tab, index) => {
          const { tabId, title } = tab;

          return (
            <TabLink
              key={tabId}
              title={title}
              tabId={tabId}
              category={tabId}
              path={path}
              toggle={toggle}
              activeTab={activeTab}
              index={index}
              disabled={namedTabs.indexOf(nextSection) < index}
            />
          );
        })}
      </Nav>
      <div className="mt-5">
        <TabContent activeTab={activeTab}>
          {props.tabs.map(tab => {
            const { tabId, component } = tab;
            return <TabDetails key={tabId} tabId={tabId} component={component} />;
          })}
        </TabContent>
      </div>
    </div>
  );
};

Tabs.propTypes = {
  defaultTab: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  tabs: PropTypes.array,
  nextSection: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
