/* eslint-disable linebreak-style */
import React from 'react';
import { SettingsModal } from 'components';
import PropTypes from 'prop-types';
import FilterReport from './reportcard-filter';
import { ReactComponent as DownloadSvg } from 'assets/svg/computer.svg';
import { Button } from 'reactstrap';

export const ReportCard = (props) => {
  const [filterVisibility, setFilterVisibility] = React.useState(false);
  const [showModalChart, setShowModalChart] = React.useState(false);
  const toggle = () => {
    setFilterVisibility(!filterVisibility);
  };
  const toggleDownload = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    setShowModalChart(!showModalChart);
  };
  const downloadStyle =
    props.title.includes('order') === true
      ? { height: '600px', padding: '20px', marginTop: '-40px' }
      : { padding: '20px', marginTop: '-40px' };

  const reffer = React.createRef();

  const exportPDF = async (document_ref) => {
    try {
      const html2canvas = await import('html2canvas');
      const { jsPDF } = await import('jspdf');

      const canvas = await html2canvas.default(document_ref.current);
      const imgData = canvas.toDataURL('image/png');

      const pdf = new jsPDF({
        orientation: 'landscape',
        unit: 'in',
        format: [20, 30],
      });

      pdf.addImage(imgData, 'JPEG', 10, 5);

      // Assuming props is defined elsewhere
      pdf.save(`order chart ${props.params?.fromDate} - ${props.params?.toDate}.pdf`);
    } catch (error) {
      // eslint-disable-next-line no-console
      // console.error('Error importing dependencies:', error);
    }
  };

  return (
    <div className="card card__report card__report--data">
      <div className="header flex-column flex-md-row">
        <h3 className="form-header__title mr-0 mr-md-auto">
          {props.title} <br />
          <small className="color-grey" style={{ fontSize: '13px' }}>
            {props.params?.branchId && props.getBranchName(props.params?.branchId)}
            <br />
            {props.params?.fromDate}&nbsp; - &nbsp;{props.params?.toDate}
          </small>
        </h3>

        <div className="w-full d-flex flex-column flex-md-row justify-content-center justify-content-md-end">
          <div
            className="header__filter mb-3 mb-md-0 mx-0 mx-md-3 btn_responsive"
            onClick={() => toggle()}
            title="Filter chart"
          >
            <FilterReport monthOptions={props.monthOptions} setFilter={props.setFilter} line={props.line} />
          </div>
          <div
            className="header__download btn_responsive"
            onClick={() => (props.line === false ? toggleDownload() : props.generate())}
            title="Download"
          >
            <p className="header__download-text">Download</p>
            <DownloadSvg />
          </div>
        </div>
      </div>

      {showModalChart && (
        <SettingsModal isOpen={true} toggle={() => toggleDownload()} title="Download Chart" size="lg">
          <div style={downloadStyle}>
            <div>
              <Button className="btn__secondary button-audit float-right" onClick={() => exportPDF(reffer)}>
                {' '}
                Save{' '}
              </Button>
            </div>

            <div className="modal-download-chart" ref={reffer}>
              <div className="header mt-5 ">
                <h3 className="form-header__title">
                  <small className="color-grey" style={{ fontSize: '13px' }}>
                    {props.params?.branchId && props.getBranchName(props.params?.branchId)}
                    <br />
                    {props.params?.fromDate}&nbsp; - &nbsp;{props.params?.toDate}
                  </small>
                </h3>
              </div>
              {props.children}
            </div>
          </div>
        </SettingsModal>
      )}

      <div className="body">{props.children}</div>
    </div>
  );
};

ReportCard.defaultProps = {
  title: 'dashboard',
  options: [],
};

ReportCard.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  line: PropTypes.bool,
  monthOptions: PropTypes.array,
  setFilter: PropTypes.func,
  getBranchName: PropTypes.func,
  params: PropTypes.object,
  generate: PropTypes.func,
};
