import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const MoreLinks = ({ ...props }) => {
  const { panel, option, match, tourOverride } = props;
  const history = useHistory();
  const dropDownRef = useRef(null);
  const [display, setDisplay] = useState(false);

  const toggle = () => {
    if (tourOverride === undefined) {
      setDisplay(!display);
    }
  };

  const handleClickOutside = event => {
    if (tourOverride) {
      return;
    }
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false);
    };
  });

  useEffect(() => {
    if (tourOverride) {
      setDisplay(true);
    } else {
      if (!panel) {
        setDisplay(false);
      }
    }
  }, [panel]);

  const activeModule = moduleName => {
    return match.path === moduleName;
  };

  return (
    <div className="navigation-otherlinks" ref={dropDownRef}>
      <Link to={'#'} className="nav-link d-flex justify-content-start align-items-center" title="More" onClick={toggle}>
        <i className={`iconmoon icon-more`}></i>
        <span
          className={classnames({
            'nav-link-text': true,
            'nav-link-display': panel === false,
            'ml-1': panel === false,
          })}
        >
          More
        </span>
      </Link>

      <div
        className={classnames({
          show: display,
          'navigation-otherlinks__items': true,
          'navigation-otherlinks-right': true,
        })}
      >
        <div className="arrow-left"></div>
        <ul className="navigation-otherlinks__options">
          {option &&
            option.map(item => {
              const clickCallBack = () => history.push(item.to);
              return (
                <li key={item.text} className="d-flex justify-content-between align-items-center text-capitalize">
                  <div className="navigation-switcher__options-link" onClick={tourOverride ? undefined : clickCallBack}>
                    {item.text}
                  </div>
                  {activeModule(item.to) && <i className="iconmoon icon-checkmark" />}
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

MoreLinks.propTypes = {
  option: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  panel: PropTypes.bool.isRequired,
  tourOverride: PropTypes.bool,
};

export default withRouter(MoreLinks);
