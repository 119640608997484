import { _notifyError, _notifySuccess } from 'utils';
import * as inventoryTypes from '../types/inventoryTypes';
// import { beginApiCall, endApiCall } from './apiActions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const getOrderStart = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_ORDERS_START,
  payload,
});

const getOrdersSuccess = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_ORDERS_SUCCESS,
  payload,
});

const getOrdersFailure = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_ORDERS_FAILURE,
  payload,
});

export const getOrders = (location, params) => {
  return async (dispatch) => {
    dispatch(getOrderStart());
    try {
      const { data } = await Axios.get(`locations/${location}/stock-movements?${params}`);
      if (data.status) {
        dispatch(getOrdersSuccess(data.data));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getOrdersFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const getSingleOrderStart = (payload) => ({
  type: inventoryTypes.GET_SINGLE_ORDER_START,
  payload,
});

const getSingleOrderComplete = (payload) => ({
  type: inventoryTypes.GET_SINGLE_ORDER_COMPLETE,
  payload,
});

export const getOrder = (id) => {
  return async (dispatch) => {
    dispatch(getSingleOrderStart({ id }));
    try {
      const { data } = await Axios.get(`orders/${id}`);
      dispatch(getSingleOrderComplete(data));
      return data.data;
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getSingleOrderComplete({ id, errorMessage }));
      return { items: [] };
    }
  };
};

const updateOrderStart = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ORDER_START,
  payload,
});

const updateOrderSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ORDER_SUCCESS,
  payload,
});

const updateOrderFailure = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ORDER_FAILURE,
  payload,
});

export const declineOrder = (id, reason, toggle) => {
  return async (dispatch) => {
    dispatch(updateOrderStart({ type: 'declining' }));
    try {
      const { data } = await Axios.post(`/orders/${id}/decline`, { reason });
      if (data.status) {
        dispatch(updateOrderSuccess(data.data));
        _notifySuccess('Order Declined');
        toggle();
        return true;
      }
      throw Error();
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(updateOrderFailure({ type: 'declining', CUSTOM_ERROR_MESSAGE }));
      return false;
    }
  };
};

export const updateOrder = (id, order, toggle) => {
  return async (dispatch) => {
    dispatch(updateOrderStart({ type: 'updating' }));
    try {
      const { data } = await Axios.patch(`orders/${id}`, order);
      if (data.status) {
        dispatch(updateOrderSuccess(data.data));
        _notifySuccess('Order Updated');
        toggle();
        return true;
      }
      throw Error();
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(updateOrderFailure({ type: 'updating', CUSTOM_ERROR_MESSAGE }));
      return false;
    }
  };
};

const StartOrderMovement = () => ({
  type: inventoryTypes.MOVE_INVENTORY_ORDER_START,
});

const MoveOrderSuccess = (payload) => ({
  type: inventoryTypes.MOVE_INVENTORY_ORDER_SUCCESS,
  payload,
});

const MoveOrderFailure = (payload) => ({
  type: inventoryTypes.MOVE_INVENTORY_ORDER_FAILURE,
  payload,
});

// same as createOrder
export const moveOrder = (order, toggle, reOrder, extras) => {
  return async (dispatch) => {
    dispatch(StartOrderMovement());
    try {
      const { data } = await Axios.post('/orders', order);
      if (data.status) {
        dispatch(MoveOrderSuccess({ ...data.data, ...extras }));
        _notifySuccess(reOrder ? 'Reordered' : 'Order Sent');
        toggle();
        return true;
      }
      throw Error();
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(MoveOrderFailure(CUSTOM_ERROR_MESSAGE));
      return false;
    }
  };
};

const AcceptOrderStart = () => ({
  type: inventoryTypes.ACCEPT_ORDER_START,
});

const AcceptOrderSuccess = (payload) => ({
  type: inventoryTypes.ACCEPT_ORDER_SUCCESS,
  payload,
});

const AcceptOrderFailure = (payload) => ({
  type: inventoryTypes.ACCEPT_ORDER_FAILURE,
  payload,
});

export const acceptOrder = (orderId, order, toggle) => {
  return async (dispatch) => {
    dispatch(AcceptOrderStart());
    try {
      const { data } = await Axios.post(`/orders/${orderId}/accept`, order);
      if (data.status) {
        dispatch(AcceptOrderSuccess(orderId));
        _notifySuccess('Order Accepted');
        toggle();
        return true;
      }
      throw Error();
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(AcceptOrderFailure(CUSTOM_ERROR_MESSAGE));
      return false;
    }
  };
};
