import React from 'react';
import { ReactComponent as InventorySvg } from 'assets/svg/inventory.svg';
import { ReactComponent as PatientsSvg } from 'assets/svg/patients.svg';
import { ReactComponent as AppointmentsSvg } from 'assets/svg/appointment.svg';
import { ReactComponent as DashboardSvg } from 'assets/svg/dashboard.svg';

export const mainDrawerOptions = [
  { text: 'hospital setup', to: '/dashboard/settings/' },
  { text: 'branches', to: '/dashboard/settings/' },
  { text: 'departments', to: '/dashboard/settings/' },
  { text: 'roles', to: '/dashboard/settings/' },
  { text: 'staff management', to: '/dashboard/settings/' },
  { text: 'system configuration', to: '/dashboard/settings/' },
  { text: 'appointments', to: '/dashboard/settings/' },
  { text: `hmo`.toUpperCase(), to: '/dashboard/settings/' },
  { text: 'medical standards', to: '/dashboard/settings/' },
];

export const setupOptions = [
  { text: 'hospital setup', to: '/onboarding/configuration/hospital-setup' },
  {
    text: 'branches',
    to: '/onboarding/configuration/branches',
    required: true,
  },
  {
    text: 'departments',
    to: '/onboarding/configuration/departments',
    required: true,
  },
  { text: 'roles', to: '/onboarding/configuration/roles', required: true },
  {
    text: 'system configuration',
    to: '/onboarding/configuration/system-configuration',
    required: true,
  },
  {
    text: 'staff management',
    to: '/onboarding/configuration/staff-management',
    required: true,
  },
  { text: `hmo`.toUpperCase(), to: '/onboarding/configuration/hmo' },
  {
    text: 'medical standards',
    to: '/onboarding/configuration/medical-standards',
  },
  { text: 'appointments', to: '/onboarding/configuration/appointments' },
];

export const mainNavOptions = (mobile) => {
  const sidebarOptions = [
    {
      text: 'dashboard',
      component: <DashboardSvg className={mobile ? '' : 'nav__link-icon'} />,
      to: '#',
    },
    {
      text: 'patients',
      component: <PatientsSvg className={mobile ? '' : 'nav__link-icon'} />,
      to: '/frontdesk/patients',
    },
    {
      text: 'appointments',
      component: <AppointmentsSvg className={mobile ? '' : 'nav__link-icon'} />,
      to: '/frontdesk/appointments',
    },
    {
      text: 'inventory',
      component: <InventorySvg className={mobile ? '' : 'nav__link-icon'} />,
      to: '#',
    },
  ];
  return sidebarOptions;
};

export const auditTrailNavOptions = () => {
  const sidebarOptions = [
    {
      icon: 'audit-trail',
      text: 'Audit Trail',
      to: '/audit-trail',
    },
  ];
  return sidebarOptions;
};

export const labsNavOptions = () => {
  const sidebarOptions = [
    {
      text: 'Dashboard',
      icon: 'dashborad',
      to: '/laboratory/report',
    },
    {
      text: 'Task Management',
      icon: 'audit-trail',
      to: '/laboratory/task-management',
    },
    {
      text: 'Backlog',
      icon: 'backlog',
      to: '/laboratory/backlog',
    },
  ];
  return sidebarOptions;
};

export const inventoryNavOptions = (isParent) => {
  const sidebarOptions = [
    {
      text: 'Stock Item.',
      icon: 'list',
      to: '/inventory/stock?tab=stock-items',
    },
    {
      text: 'Services',
      icon: 'backlog',
      to: '/inventory/services',
    },
    {
      text: 'HMO',
      icon: 'audit-trail',
      to: '/inventory/hmo',
    },
  ];
  if (isParent) {
    sidebarOptions.push({
      text: 'Inventory Settings',
      icon: 'audit-trail',
      to: '/inventory/settings',
    });
  }

  return sidebarOptions;
};

export const centralStoreNavOptions = () => {
  const sidebarOptions = [
    {
      text: 'Stock Item.',
      icon: 'list',
      to: '/central-store/stock?tab=stock-items',
    },
    {
      text: 'Services',
      icon: 'backlog',
      to: '/central-store/services',
    },
    {
      text: 'HMO',
      icon: 'audit-trail',
      to: '/central-store/hmo',
    },
  ];
  return sidebarOptions;
};

export const frontDeskNavOptions = () => {
  const sidebarOptions = [
    {
      text: 'Dashboard',
      icon: 'dashboard-fd',
      to: '/frontdesk/dashboard',
    },
    {
      text: 'Patients',
      icon: 'patient-list',
      to: '/frontdesk/patients',
    },
    {
      text: 'Appointments',
      icon: 'appointments-setup',
      to: '/frontdesk/appointments',
    },
    {
      text: 'Calendar',
      icon: 'calendar',
      to: '/frontdesk/calendar',
    },
  ];
  return sidebarOptions;
};

export const clinicNavOptions = (module) => {
  const sidebarOptions = [
    {
      text: 'Dashboard',
      icon: 'dashboard-fd',
      to: `/${module}/dashboard`,
    },
    {
      text: 'Patients',
      icon: 'patient-list',
      to: `/${module}/patients`,
    },
    module === 'out-patient'
      ? {
          text: 'Appointments',
          icon: 'appointments-setup',
          to: `/${module}/appointments`,
        }
      : undefined,
    {
      text: 'Calendar',
      icon: 'calendar',
      to: `/${module}/calendar`,
    },
  ].filter((e) => e !== undefined);
  return sidebarOptions;
};

export const deskNavOptions = () => {
  const sidebarOptions = [
    {
      text: 'dashboard',
      icon: 'dashboard',
      to: '/frontdesk/dashboard',
    },
    {
      text: 'patients',
      icon: 'backlog',
      to: '/frontdesk/patients',
    },
  ];
  return sidebarOptions;
};

export const settingsNavOptions = () => {
  const sidebarOptions = [
    {
      text: 'Hospital Setup',
      icon: 'hospital',
      to: '/settings/hospital-setup',
    },
    {
      text: 'Branch',
      icon: 'branch-config',
      to: '/settings/branches',
    },
    {
      text: 'Department',
      icon: 'department',
      to: '/settings/departments',
    },
    {
      text: 'Staff',
      icon: 'staff',
      to: '/settings/staff',
    },
  ];

  const dashboardLinks = [
    {
      text: 'Patient Health',
      icon: 'hospital',
      to: '/settings/dashboard/patient-health',
    },
    {
      text: 'Hospital Performance',
      icon: 'hospital',
      to: '/settings/dashboard/hospital-performance',
    },
    {
      text: 'Financial Revenue',
      icon: 'hospital',
      to: '/settings/dashboard/financial-revenue',
    },
    {
      text: 'InventorySettings Management',
      icon: 'hospital',
      to: '/settings/dashboard/inventory-management',
    },
  ];

  const moreLinks = [
    {
      text: 'Sponsors',
      to: '/settings/sponsors',
    },
    {
      text: 'Discount',
      to: '/settings/discount',
    },
    {
      text: 'Patients List',
      to: '/settings/patients',
    },
    {
      text: 'Branch configuration',
      to: '/settings/branch-config',
    },
    {
      text: 'Plan',
      to: '/settings/plans',
    },
    {
      text: 'banks',
      to: '/settings/banks',
    },
    {
      text: 'Categories',
      to: '/settings/categories',
    },
    {
      text: 'Registration Customization',
      to: '/settings/reg-custom',
    },
    {
      text: 'Medical Standards',
      to: '/settings/medic-standard',
    },
    {
      text: 'Admission Mgt',
      to: '/settings/admission-mgt',
    },
    {
      text: 'Roles',
      to: '/settings/roles',
    },
    {
      text: 'Bulk Uploads',
      to: '/settings/bulk-uploads',
    },
    {
      text: 'License',
      to: '/settings/license',
    },
  ];
  return { sidebarOptions, moreLinks, dashboardLinks };
};

export const pharmacyNavOptions = () => {
  const sidebarOptions = [
    {
      text: 'Dashboard',
      icon: 'dashboard-fd',
      to: '/pharmacy/dashboard',
    },
    {
      text: 'Patients',
      icon: 'patient-list',
      to: '/pharmacy/patients',
    },
    {
      text: 'Retail',
      icon: 'patient-list',
      to: '/pharmacy/retail',
    },
    {
      text: 'Prescription',
      icon: 'pharmacy-presc',
      to: '/pharmacy/prescriptions?tab=out-patient',
    },

    {
      text: 'Insight',
      icon: 'insight',
      to: '/pharmacy/insight',
    },
  ];

  return sidebarOptions;
};

export const billingNavOptions = () => {
  const sidebarOptions = [
    {
      text: 'Dashboard',
      icon: 'dashboard-fd',
      to: '/billing/dashboard',
    },
    {
      text: 'Patients',
      icon: 'patients',
      to: '/billing/patients',
    },
    {
      text: 'Transactions',
      icon: 'transactions',
      to: '/billing/transactions',
    },
    {
      text: 'Insight',
      icon: 'insight',
      to: '/billing/insight',
    },
    {
      text: 'HMO',
      icon: 'insurance',
      to: '/billing/claims',
    },
  ];
  return sidebarOptions;
};

export const reportNavOptions = () => {
  const sidebarOptions = [
    {
      text: 'Dashboard',
      icon: 'dashboard-fd',
      to: '/report/dashboard',
    },
  ];
  return sidebarOptions;
};
