import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { fetchLocation } from 'redux/actions';
import { _notifyError } from 'utils';

const Switcher = ({ ...props }) => {
  const { panel, history } = props;
  const dropDownRef = useRef(null);

  const dispatch = useDispatch();
  const [display, setDisplay] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState({});
  const [loading, setLoading] = useState(false);

  const { current, orgBranches } = useSelector((state) => state.user);
  const locations = useSelector((state) => state.user?.current?.locations) || [];
  const currentBranch = useSelector((state) => state.user?.current?.value) || '';

  const toggle = () => {
    if (panel) {
      setDisplay(!display);
    }
  };

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setDisplay(false);
    }
  };

  useEffect(() => {
    //
  }, []);

  useEffect(() => {
    if (current) {
      let modified = { ...current };
      modified.modules = modified.location?.modules;
      setSelectedBranch(modified);
    }
  }, [current]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    if (!panel) {
      setDisplay(false);
    }
  }, [panel]);

  const changeBranch = (branch) => {
    const newLocation = locations.find((location) => location._id === branch._id) || {};
    let nextBranch = { ...newLocation };

    if (!newLocation._id) {
      nextBranch = { ...branch, modules: [] };
      _notifyError('You have no access to this module');
    } else {
      nextBranch = { ...nextBranch, value: nextBranch._id, label: nextBranch.name };
    }
    setSelectedBranch(nextBranch);
  };

  const isWithinCurrentBranch = selectedBranch?.value === currentBranch;

  const changeCurrentBranch = useCallback(
    async (url) => {
      if (!isWithinCurrentBranch) {
        await setLoading(true);
        await dispatch(fetchLocation(selectedBranch.value));
        await setLoading(false);
      }
      history.push(`/${url}`);
    },
    [selectedBranch, dispatch, history],
  );

  const activeModule = (moduleName) => {
    const { match } = props;
    return match.path.split('/')[1] === moduleName;
  };

  const getAcronym = (locationName) => {
    const matches = locationName.match(/\b(\w)/g);
    if (matches.length < 2) {
      return matches.join('');
    }
    return matches.slice(0, 2).join('');
  };

  const checkSelection = selectedBranch && selectedBranch.label === current.label;

  return (
    <div
      className={classnames({
        'navigation-switcher-width': display,
        'navigation-switcher': true,
      })}
      ref={dropDownRef}
    >
      <div
        className="navigation-switcher-header d-flex justify-content-between align-items-center"
        onClick={toggle}
        aria-hidden
      >
        <>
          {panel ? (
            <span className="white-space-normal">{(current && current.label) || 'Hospital Null'}</span>
          ) : (
            <span className="navigation-switcher-initial">{current.label ? getAcronym(current.label) : 'NN'}</span>
          )}
        </>
        {panel && <>{display ? <i className="iconmoon icon-caution" /> : <i className="iconmoon icon-arrow-down" />}</>}
      </div>

      <div
        className={classnames({
          show: display,
          'navigation-switcher-items': true,
        })}
      >
        <div className="navigation-switcher-title">Branches {` - ${orgBranches && orgBranches.length}`}</div>
        <ul className="navigation-switcher__options">
          {orgBranches &&
            orgBranches.map((branch, index) => {
              const active = selectedBranch && selectedBranch.value === branch.value;
              const clickhandler = () => changeBranch(branch);
              return (
                <li key={index} className="d-flex justify-content-between switch-link" onClick={clickhandler}>
                  <span
                    style={
                      branch.parent
                        ? {
                            fontWeight: 'bold',
                            fontSize: 14,
                          }
                        : undefined
                    }
                  >
                    {branch.label}
                  </span>
                  {active && <>{!loading ? <i className="iconmoon icon-checkmark" /> : <Spinner />}</>}
                </li>
              );
            })}
        </ul>

        {selectedBranch && selectedBranch.parent && (
          <>
            <div className="navigation-switcher-title">Admin Settings</div>
            <ul className="navigation-switcher__options">
              <li className="d-flex justify-content-between text-capitalize">
                <div className="navigation-switcher__options-link" onClick={() => changeCurrentBranch('settings')}>
                  Settings
                </div>
                {activeModule('settings') && <i className="iconmoon icon-checkmark" />}
              </li>
              <li className="d-flex justify-content-between text-capitalize">
                <div className="navigation-switcher__options-link" onClick={() => changeCurrentBranch('inventory')}>
                  Central Store
                </div>
                {activeModule('central-store') && checkSelection && <i className="iconmoon icon-checkmark" />}
              </li>
              <li className="d-flex justify-content-between text-capitalize">
                <div className="navigation-switcher__options-link" onClick={() => changeCurrentBranch('audit-trail')}>
                  Audit Trail
                </div>
                {activeModule('audit-trail') && checkSelection && <i className="iconmoon icon-checkmark" />}
              </li>
              <li className="d-flex justify-content-between text-capitalize">
                <div className="navigation-switcher__options-link" onClick={() => changeCurrentBranch('report')}>
                  Report
                </div>
                {activeModule('report') && checkSelection && <i className="iconmoon icon-checkmark" />}
              </li>
            </ul>
          </>
        )}

        <>
          {selectedBranch && !selectedBranch.parent && (
            <>
              <div className="navigation-switcher-title">
                Available Modules{' '}
                {` - ${(selectedBranch && selectedBranch.modules && selectedBranch.modules.length) || 0}`}
              </div>
              <ul className="navigation-switcher__options">
                {selectedBranch &&
                  selectedBranch.modules &&
                  selectedBranch.modules.map((module) => {
                    const clickCallBack = () => changeCurrentBranch(module);
                    return (
                      <li key={module} className="d-flex justify-content-between text-capitalize">
                        <div className="navigation-switcher__options-link" onClick={clickCallBack}>
                          {module}
                        </div>
                        {activeModule(module) && checkSelection && <i className="iconmoon icon-checkmark" />}
                      </li>
                    );
                  })}
              </ul>
            </>
          )}
        </>
      </div>
    </div>
  );
};

Switcher.propTypes = {
  panel: PropTypes.bool.isRequired,
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  history: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string]),
};

export default withRouter(Switcher);
