import * as roleTypes from '../types/roleTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject } from 'utils';

export const roles = (state = initialState.roles, action) => {
  switch (action.type) {
    case roleTypes.FETCH_ROLES_SUCCESS:
      return updateObject(state, action.payload);

    case roleTypes.FETCH_ROLES_FAILED:
      return updateObject(state, { error: action.error });

    case roleTypes.ADD_ROLE_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    case roleTypes.ADD_ROLE_SUCCESS:
      return updateObject(state, { ...action.payload, formErrorMessage: null });

    case roleTypes.ADD_ROLE_FAILED:
      return updateObject(state, {
        formErrorMessage: action.error,
        isSaving: false
      });

    case roleTypes.UPDATE_ROLE_START:
      return updateObject(state, action.payload);

    case roleTypes.UPDATE_ROLE_SUCCESS:
      return updateObject(state, {
        ...action.payload,
        isSaving: false,
        isDeactivating: false
      });

    case roleTypes.UPDATE_ROLE_FAILED:
      return updateObject(state, { isSaving: false, isDeactivating: false });

    case roleTypes.DELETE_ROLE_START:
      return updateObject(state, { isDeactivating: true });

    case roleTypes.DELETE_ROLE_SUCCESS:
      return updateObject(state, { ...action.payload, isDeactivating: false });

    case roleTypes.DELETE_ROLE_FAILED:
      return updateObject(state, { isDeactivating: false });

    case roleTypes.ROLES_PAGE_SORT:
      return updateObject(state, action.payload);

    case roleTypes.UPDATE_ROLES_LIST:
      return updateObject(state, action.payload);

    default:
      return { ...state };
  }
};
