import React from 'react';
import { Modal } from './index';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { UserCard } from 'components/usercard';

const Footer = props => (
  <>
    <Button
      disabled={props.leftButtonDisabled}
      onClick={props.leftButtonPress}
      className="modal__btn modal__btn--assign"
    >
      Mark Job as Delivered
    </Button>
  </>
);

Footer.propTypes = {
  leftButtonPress: PropTypes.func,
  leftButtonDisabled: PropTypes.bool
};

export const DispatchedModal = props => (
  <Modal {...props} color="grey" showComment >
    <div className="modal__list__item">
      {props.assignees && props.assignees.length ? (
        <>
          <p className="modal__list__text modal__list__text--1">Assignees</p>
          <div className="modal__list__text modal__list__text--2">
            <div className="row">
              {props.assignees.map(ls => (
                ls.name && (<div className="col-sm-6" key={ls._id}>
                  <UserCard label {...ls} />
                </div>)
              ))}
            </div>
          </div>
        </>
      ) : null}
    </div>
  </Modal>
);

DispatchedModal.defaultProps = {
  assignees: []
};

DispatchedModal.propTypes = {
  assignees: PropTypes.array
};
