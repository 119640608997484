import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const RadioInput = React.forwardRef((props, ref) => (
  <div className={`form-radio ${props.className}`}>
    <label className={`container ${props.containerClass}`}>
      {props.label}
      <input
        ref={ref}
        type={props?.type || 'radio'}
        id={props.id}
        name={props.id}
        value={props.value}
        checked={props.selected}
        onChange={props.onChange}
        disabled={props.disabled}
        {...(props.register && props.register(props.name, { ...props.validation }))}
      />
      <span className={classnames('radio-button', { 'radio-button__left': props.left })}></span>
    </label>
  </div>
));

RadioInput.displayName = 'RadioInput';

RadioInput.defaultProps = {
  className: '',
};

RadioInput.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  left: PropTypes.bool,
  className: PropTypes.string,
  containerClass: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  validation: PropTypes.object,
  onChange: PropTypes.func,
  register: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
};
