import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { FormGroup, Label } from 'reactstrap';

import moment from 'moment';

const DATE_FORMAT = 'yyyy-MM-dd';

export const DateRangePicker = (props) => {
  const { dateRange, setDateRange, ...rest } = props;

  return (
    <FormGroup className={`settings__form-group tw-my-0`}>
      <DatePicker
        dateFormat={DATE_FORMAT}
        selected={dateRange?.[0]}
        onChange={setDateRange}
        startDate={dateRange?.[0]}
        endDate={dateRange?.[1]}
        className="tw-w-full"
        selectsRange
        isClearable
        {...rest}
      />
    </FormGroup>
  );
};

DateRangePicker.propTypes = {
  dateRange: PropTypes.array,
  setDateRange: PropTypes.func,
};

export const FormDateRangePicker = (props) => {
  const { dateRange, setDateRange, className, isDisabled, label, isRequired, ...rest } = props;

  const onChangeDateRange = (dates) => {
    const startDate = dates?.[0] ? moment(dates[0]).format(DATE_FORMAT.toUpperCase()) : dates?.[0];
    const endDate = dates?.[1] ? moment(dates[1]).format(DATE_FORMAT.toUpperCase()) : dates?.[1];
    setDateRange([startDate, endDate]);
  };

  return (
    <FormGroup className={`settings__form-group ${className} ${isDisabled && 'disabled'}`}>
      <div className="d-flex">
        {label && (
          <Label className="form__label" for="name">
            {label}
          </Label>
        )}
        <span style={{ color: 'red' }}>{isRequired ? '*' : ''}</span>
      </div>
      <DatePicker
        dateFormat={DATE_FORMAT}
        selected={dateRange?.[0] ? new Date(dateRange[0]) : dateRange?.[0]}
        onChange={onChangeDateRange}
        startDate={dateRange?.[0] ? new Date(dateRange[0]) : dateRange?.[0]}
        endDate={dateRange?.[1] ? new Date(dateRange[1]) : dateRange?.[1]}
        selectsRange
        isClearable
        disabled={isDisabled}
        placeholderText="YYYY-M-D"
        {...rest}
      />
    </FormGroup>
  );
};

FormDateRangePicker.propTypes = {
  dateRange: PropTypes.array,
  setDateRange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
};
