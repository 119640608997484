import { _notifyError, _notifySuccess } from 'utils';
import * as emailTypes from '../types/emailTypes';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const sendEmailStart = () => ({
  type: emailTypes.SEND_EMAIL_START,
});

const sendEmailSuccess = () => ({
  type: emailTypes.SEND_EMAIL_SUCCESS,
});

export const sendEmail = (payload, handleSuccess, handleError) => async (dispatch) => {
  dispatch(sendEmailStart());

  try {
    const { data } = await Axios.post(`/send-mail`, payload);

    if (data.status) {
      _notifySuccess(`Email sent successfully`);
      handleSuccess?.();
    }
  } catch (error) {
    const errorMessage = error.response ? error.response.data.message || error.response.data : CUSTOM_ERROR_MESSAGE;
    handleError?.();
    _notifyError(errorMessage);
  }
  dispatch(sendEmailSuccess());
};
