import React, { lazy, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import MainLayout from '../../../layouts/MainLayout';
import AuthRoute from '../../../routes/AuthRoute';
import {
  fetchAppointmentPurposes,
  fetchBranchesConfiguration,
  fetchPlans,
  fetchSubscriptions,
} from 'modules/settings/redux/actions';
import {
  fetchBranches,
  fetchDepartments,
  fetchOrganizationCustomization,
  fetchSponsors,
  fetchStaff,
} from 'redux/actions';
import { getProducts } from 'modules/inventory/redux/actions';
import { getSerializedParams } from 'utils';

const Dashboard = lazy(() => import('../pages/Dashboard'));

const Patients = lazy(() => import('pages/app/Patients/index'));
const PatientDetailsRoutes = lazy(() => import('../pages/PatientDetails'));
const Calendar = lazy(() => import('pages/app/Calendar'));
const Appointments = lazy(() => import('pages/app/Appointments/index'));
const ErrorPage = lazy(() => import('pages/Error'));

const FrontdeskRoutes = ({ match }) => {
  const { loggedIn } = useSelector((state) => state.auth);
  const { current } = useSelector((state) => state.user);
  // TODO check accessGranted value using the modules for the current branch
  // TODO const hasAccess = current?.location?.modules.includes();

  const dispatch = useDispatch();

  useEffect(() => {
    if (current && current.parentOrganizationId) {
      dispatch(fetchSponsors(current.parentOrganizationId));
      dispatch(fetchDepartments(current.parentOrganizationId));
      dispatch(fetchStaff(current.parentOrganizationId));
    }
  }, [dispatch, current]);

  useEffect(() => {
    dispatch(fetchAppointmentPurposes());
    dispatch(fetchPlans());
    dispatch(fetchBranches());
    dispatch(fetchOrganizationCustomization());
    dispatch(fetchSubscriptions());
    dispatch(fetchBranchesConfiguration());
    dispatch(getProducts(getSerializedParams({ limit: 10000 }), current?.branchId || undefined));
  }, []);

  return (
    <>
      <Switch>
        <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />
        <AuthRoute
          exact
          path={`${match.path}`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Dashboard}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/dashboard`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Dashboard}
          accessGranted={true}
        />

        <AuthRoute
          exact
          path={`${match.path}/patients`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Patients}
          accessGranted={true}
        />
        <AuthRoute
          path={`${match.path}/patients/:id`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={PatientDetailsRoutes}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/calendar`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Calendar}
          accessGranted={true}
        />
        <AuthRoute
          path={`${match.path}/appointments`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Appointments}
          accessGranted={true}
        />

        <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
      </Switch>
    </>
  );
};

FrontdeskRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default FrontdeskRoutes;
