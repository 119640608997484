import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import { defaultChartData, defaultOptions } from './chart-data';

const chart = ({ type, width, height, data, options, id, plugins }) => {
  useEffect(() => {
    const ctx = document.getElementById(id).getContext('2d');

    new Chart(ctx, {
      type,
      data: {
        ...defaultChartData,
        ...data,
      },
      options: {
        ...defaultOptions,
        ...options,
      },
      plugins,
    });
  }, [data]);

  return <canvas id={id} width={width} height={height}></canvas>;
};

chart.defaultProps = {
  type: 'line',
  width: '100%',
  height: '100%',
  id: 'chart-js',
  plugins: [],
};

chart.propTypes = {
  type: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  data: PropTypes.object,
  options: PropTypes.object,
  id: PropTypes.string,
  plugins: PropTypes.array,
};

export default chart;
