import React from 'react';
import PropTypes from 'prop-types';

import { AuthNav } from '../components';

const AuthLayout = props => {
  const { children } = props;
  return (
    <div className="auth">
      {props.displayShapes && (
        <>
          <div className="auth__shape circle" />
          <div className="auth__shape square square--1" />
          <div className="auth__shape square square--2" />
        </>
      )}

      <AuthNav />

      <div className={`auth__body ${props.pageClass || ''}`}>
        <div className="container">
          <div className="row justify-content-between align-items-center">{children}</div>
        </div>
      </div>
    </div>
  );
};

AuthLayout.defaultProps = {
  displayShapes: true,
};

AuthLayout.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  displayShapes: PropTypes.bool,
  pageClass: PropTypes.string,
  cardClass: PropTypes.string,
};

export default AuthLayout;
