import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchSvg } from 'assets/svg/search.svg';

export const DashboardSearch = props => (
  <div className="dashboard-search">
    <input type="text" className="dashboard-search__bar" {...props} />
  </div>
);

export const SearchBar = props => {
  return (
    <div className="dashboard-search-bar">
      <SearchSvg />
      <input
        type="text"
        className="dashboard-search-bar__bar"
        placeholder={props.placeholder}
        value={props.value}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
};

SearchBar.defaultProps = {
  placeholder: 'search',
  value: '',
  // eslint-disable-next-line no-empty-function
  onChange: () => { },
};

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func
};
