import React, { useRef, useState } from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { useSelector } from 'react-redux';
import { _formatDate, populateUsersBranches } from 'utils';
import { ReactComponent as FilterSvg } from 'assets/svg/filter.svg';
import PropTypes from 'prop-types';
import { DateInput, Select } from 'components';
import { Button, Form } from 'reactstrap';
import classnames from 'classnames';
import { completed, inprogress, orderStateOptions, pending } from 'utils/constants';

const FilterReport = ({ ...props }) => {
  const ref = useRef();
  const { setFilter } = props;
  const initialState = {
    service: '',
    status: '',
    month: '',
    fromDate: undefined,
    toDate: undefined,
    show: false,
  };
  const {
    user: { orgBranches },
  } = useSelector((state) => state);
  const [state, setState] = useState({ initialState });
  const lineStatusOptions = [
    { label: 'Pending', value: pending },
    { label: 'In Progress', value: inprogress },
    { label: 'Completed', value: completed },
  ];
  const { show, fromDate, toDate, branch, status, month } = state;

  const toggleFilter = () => setState((prevState) => ({ ...prevState, show: !prevState.show }));

  const handleAttributeChange = (value, attribute) => {
    let extraField = {};

    if (attribute === 'fromDate' && toDate) {
      const to = new Date(toDate).getTime();
      const from = new Date(value).getTime();
      if (to - from < 0 || from - to > 0) {
        extraField = { toDate: undefined };
      }
    }
    setState({
      ...state,
      [attribute]: ['fromDate', 'toDate'].includes(attribute) ? new Date(value) : value,
      ...extraField,
    });
  };

  const onSubmit = () => {
    let params = '';
    setState(initialState);
    if (branch) {
      params = `branchId=${branch.value}&`;
    }
    if (fromDate && toDate) {
      params = `${params}fromDate=${_formatDate(fromDate, 'YY-MM-DD')}&toDate=${_formatDate(toDate, 'YY-MM-DD')}`;
    }

    const selectedMonth = month ? month.value + 1 : new Date().getMonth() + 1;
    const from = `${new Date().getFullYear()}-${selectedMonth}-01`;
    const to = `${new Date().getFullYear()}-${selectedMonth}-31`;
    if (status) {
      params = `${params}fromDate=${from}&toDate=${to}`;
      params = { status: true, actualQuery: `${params}&status=${status.value}` };
    }
    setFilter(params);
  };
  const isDisabled = () => {
    if (!branch) return true;
    return fromDate && !toDate;
  };

  useOnClickOutside(ref, () => setState(initialState));

  return (
    <div ref={ref} className="flex-grow-1 d-flex justify-content-center">
      <div
        className="filter-action-custom-staff-box d-flex justify-content-center ml-0 w-full"
        onClick={() => toggleFilter()}
        aria-hidden
      >
        <FilterSvg />
        <span className="filter-action__title">Filter</span>
      </div>

      {show && (
        <Form className="form__settings">
          <div className={`filter-action-custom-staff ${props.classname} reportcard_filter`}>
            <div className="arrow" />
            <Select
              isSearchable
              placeholder="Branches"
              onChange={(e) => handleAttributeChange(e, 'branch')}
              value={branch}
              options={populateUsersBranches(orgBranches)}
            />
            {props.line && (
              <Select
                isSearchable
                placeholder="Status"
                onChange={(e) => handleAttributeChange(e, 'status')}
                value={status}
                options={props.line ? lineStatusOptions : orderStateOptions}
              />
            )}
            {props.line && (
              <Select
                isSearchable
                placeholder="Month"
                onChange={(e) => handleAttributeChange(e, 'month')}
                value={month}
                options={props.monthOptions}
              />
            )}
            {!props.line && (
              <div className="row">
                <div className="col-sm-12 col-lg-6">
                  <DateInput
                    placeholder="From"
                    id="fromDate"
                    value={fromDate}
                    onDayChange={(e) => handleAttributeChange(e, 'fromDate')}
                  />

                  {fromDate && (
                    <i
                      className="clear-date fas fa-ban"
                      onClick={() => setState((prevState) => ({ ...prevState, fromDate: undefined }))}
                    />
                  )}
                </div>

                <div className="col-sm-12 col-lg-6">
                  {fromDate && (
                    <DateInput
                      id="toDate"
                      placeholder="To"
                      value={fromDate === undefined ? undefined : toDate}
                      onDayChange={(e) => handleAttributeChange(e, 'toDate')}
                      disabled={fromDate === undefined}
                      disabledDays={{
                        before: fromDate ? new Date(fromDate) : undefined,
                      }}
                    />
                  )}
                  {toDate && (
                    <i
                      className="clear-date fas fa-ban"
                      onClick={() => setState((prevState) => ({ ...prevState, toDate: undefined }))}
                    />
                  )}
                </div>
              </div>
            )}

            <div>
              <Button
                block
                className={classnames('btn__secondary two-col-button', {
                  btn__loading: false,
                })}
                onClick={() => onSubmit()}
                disabled={isDisabled()}
              >
                Apply filter
              </Button>
            </div>
          </div>
        </Form>
      )}
    </div>
  );
};

FilterReport.defaultProps = {
  cssClass: '',
};

FilterReport.propTypes = {
  line: PropTypes.bool,
  cssClass: PropTypes.string,
  selected: PropTypes.object,
  filterFunction: PropTypes.func,
  setFilter: PropTypes.func,
  initial: PropTypes.object,
  isOnBranch: PropTypes.bool,
  tasksStatuses: PropTypes.array,
  dispatch: PropTypes.func,
  classname: PropTypes.string,
  monthOptions: PropTypes.array,
};

export default FilterReport;
