import React from 'react';
import { Button, Modal } from 'reactstrap';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SoftwareUpdate = ({ isOpen, toggle, message }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" className="software" centered backdropClassName="backdrop-style">
      <div className="software-update__header">
        <div className="d-flex justify-content-between">
          <div>
            <h3 className="subject-tag">Software Update!</h3>
            <h1 className="fs-32">What&apos;s new?</h1>
            <p className="fs-14">Prepare ahead of what&apos;s is included in this update</p>
          </div>
          <Close style={{ cursor: 'pointer' }} onClick={() => null} color="#fa4659" strokeWidth={1} />
        </div>
      </div>
      <div className="patients-referral-form">
        <div className="d-flex justify-content-start pl-5 software-update_content">
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        <div className="form__settings-footer mt-5">
          <div className="row justify-content-center">
            <div className="mx-4">
              <Button
                className={classNames('btn btn__utility', {
                  btn__loading: false,
                })}
                onClick={toggle}
                // disabled={isDisabled}
                style={{ minWidth: '140px' }}
              >
                Cancel
              </Button>
            </div>
            <div className="mx-4">
              <Button
                className={classNames('btn btn__secondary', {
                  btn__loading: false,
                })}
                onClick={() => {
                  window.location.reload();
                }}
                // disabled={isDisabled}
                style={{ minWidth: '140px' }}
              >
                Refresh
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

SoftwareUpdate.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  message: PropTypes.string,
};

export default SoftwareUpdate;
