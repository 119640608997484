import React from 'react';
import PropTypes from 'prop-types';

const PlainLayout = ({ ...props }) => {
  const { children } = props;
  return (
    <div className="plain-layout">
      <div className="container plain-container">
        <div className="row">
          <div className="col-md-12">{children}</div>
        </div>
      </div>
    </div>
  );
};

PlainLayout.propTypes = {
  children: PropTypes.node,
};

export default PlainLayout;
