/* eslint-disable no-case-declarations */
import * as creditTypes from '../types/creditTypes';
import initialState from './initialState';
import { updateObject } from 'utils';
const credit = (state = initialState.credit, { type, payload }) => {
  switch (type) {
    case creditTypes.FETCH_CREDIT_START:
      return updateObject(state, { loading: true });
    case creditTypes.FETCH_CREDIT_SUCCESS:
      return updateObject(state, { item: payload, loading: false });
    case creditTypes.FETCH_CREDIT_FAIL:
      return updateObject(state, { loading: false });

    default:
      return { ...state };
  }
};

export default credit;
