import { updatedState, _notifyError, _notifySuccess, _sortItems } from 'utils';
import * as sponsorTypes from '../types/sponsorTypes';
import { beginApiCall, endApiCall } from './apiActions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const fetchSponsorsSuccess = (payload) => ({
  type: sponsorTypes.FETCH_SPONSOR_SUCCESS,
  payload,
});

const fetchSponsorsFailed = (msg) => ({
  type: sponsorTypes.FETCH_SPONSOR_FAILED,
  error: msg,
});

export const fetchSponsors = () => async (dispatch) => {
  dispatch(beginApiCall());
  dispatch({
    type: sponsorTypes.FETCH_SPONSOR_START,
  });
  try {
    const { data } = await Axios.get(`/sponsors/`);

    const payload = {
      items: _sortItems([...data.data], 'name'),
      filteredItems: _sortItems([...data.data], 'name'),
    };

    if (data.status) {
      dispatch(endApiCall());
      dispatch(fetchSponsorsSuccess(payload));
    }
  } catch (err) {
    dispatch(endApiCall());
    dispatch({
      type: sponsorTypes.FETCH_SPONSOR_FAILED,
    });
    _notifyError(err.message);
    dispatch(fetchSponsorsFailed(err.message));
  }
};

const addSponsorStart = () => ({ type: sponsorTypes.ADD_SPONSOR_START });

const addSponsorSuccess = (payload) => ({
  type: sponsorTypes.ADD_SPONSOR_SUCCESS,
  payload,
});

const addSponsorFailed = (msg) => ({
  type: sponsorTypes.ADD_SPONSOR_FAILED,
  error: msg,
});

export const addSponsor = (current, arr, close) => {
  return async (dispatch) => {
    dispatch(addSponsorStart());
    try {
      const { data } = await Axios.post(`/sponsors/`, current);

      if (data.status) {
        const cache = _sortItems([...arr].concat(data.data), 'name');
        dispatch(addSponsorSuccess(updatedState(cache)));
        _notifySuccess('HMO successfully added.');
        close();
      }
    } catch (error) {
      _notifyError(error.message);
      dispatch(addSponsorFailed(error.message));
    }
  };
};

const updateSponsorStart = (payload) => ({
  type: sponsorTypes.UPDATE_SPONSOR_START,
  payload,
});

const updateSponsorSuccess = (payload) => ({
  type: sponsorTypes.UPDATE_SPONSOR_SUCCESS,
  payload,
});

const updateSponsorFailed = (msg) => ({
  type: sponsorTypes.UPDATE_SPONSOR_FAILED,
  error: msg,
});

export const updateSelectedSponsor = (current, arr, close, action) => {
  return async (dispatch) => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const { address, email, isActive, name, phoneNumber, plans, type, logo } = current;
    const payload = { address, email, isActive, name, phoneNumber, plans, type };
    if (logo.binaryString) {
      payload.logo = logo;
    }

    dispatch(updateSponsorStart(actionType));
    try {
      const { data } = await Axios.patch(`/sponsors/${current._id}`, payload);

      if (data.status) {
        const cache = [...arr].map((prev) =>
          String(prev._id) === String(current._id) ? { ...current, ...data.data } : prev,
        );

        dispatch(updateSponsorSuccess(updatedState(cache)));
        _notifySuccess(`HMO ${action || 'updated'} successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateSponsorFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const sponsorUploadSuccess = (data) => ({
  type: sponsorTypes.UPLOAD_SPONSORS_SUCCESS,
  payload: data,
});

export const updateSponsorList = (value) => ({
  type: sponsorTypes.UPDATE_SPONSORS_LIST,
  payload: {
    filteredItems: value,
  },
});
