import { _notifyError } from 'utils';
import Axios from 'services/axios';
import * as licenseTypes from '../types/licenseTypes';
import { endApiCall } from '../../../../redux/actions/apiActions';

const fetchLicenseStart = () => ({
    type: licenseTypes.FETCH_LICENSE_START,
});
  
const fetchLicenseSuccess = payload => ({
    type: licenseTypes.FETCH_LICENSE_SUCCESS,
    payload,
});

const fetchLicenseFailed = msg => ({
    type: licenseTypes.FETCH_LICENSE_FAILED,
    error: msg,
});

export const fetchLicense = () => async dispatch => {
    dispatch(fetchLicenseStart());
    const { data } = await Axios.get(`/licenses`);
    if (!data.status) {
      dispatch(endApiCall());
      _notifyError(data.message);
      dispatch(fetchLicenseFailed(data.message));
    } else {
      dispatch(endApiCall());
      dispatch(fetchLicenseSuccess(data.data));
    }
};

export const updateLicenseList = value => ({
  type: licenseTypes.UPDATE_LICENSE_LIST,
  payload: value,
});
