import { _firstLetterUc, _notifyError, _notifySuccess, _sortItems } from 'utils';
import Axios from 'services/axios';
import * as inPatientTypes from '../types/inPatientTypes';
import { beginApiCall, endApiCall } from '../../../../redux/actions/apiActions';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const fetchInPatientsSuccess = (payload) => ({
  type: inPatientTypes.FETCH_INPATIENT_SERVICES_SUCCESS,
  payload,
});

const fetchInPatientsFailed = (msg) => ({
  type: inPatientTypes.FETCH_INPATIENT_SERVICES_FAILED,
  error: msg,
});

export const fetchInPatients = (type, serviceStatus) => async (dispatch) => {
  dispatch(beginApiCall());

  const { data } = await Axios.get(`/inpatient-services?type=${type}`);

  if (!data.status) {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchInPatientsFailed(data.message));
  } else {
    const payload = {
      items: _sortItems([...data.data], 'name').filter((item) => item.isActive === serviceStatus),
      filteredItems: _sortItems([...data.data], 'name').filter((item) => item.isActive === serviceStatus),
    };
    dispatch(endApiCall());
    dispatch(fetchInPatientsSuccess(payload));
  }
};

const addInPatientStart = () => ({ type: inPatientTypes.ADD_INPATIENT_SERVICE_START });

const addInPatientSuccess = (payload) => ({
  type: inPatientTypes.ADD_INPATIENT_SERVICE_SUCCESS,
  payload,
});

const addInPatientFailed = (msg) => ({
  type: inPatientTypes.ADD_INPATIENT_SERVICE_FAILED,
  error: msg,
});

export const addInPatientService = (current, close) => {
  return async (dispatch) => {
    dispatch(addInPatientStart());
    try {
      const { data } = await Axios.post(`/inpatient-services`, current);

      if (data.status) {
        dispatch(addInPatientSuccess(data.data));
        _notifySuccess(`${_firstLetterUc(current.type)} Services successfully added.`);
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(addInPatientFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateInPatientStart = (payload) => ({
  type: inPatientTypes.UPDATE_INPATIENT_SERVICE_START,
  payload,
});

const updateInPatientSuccess = (payload) => ({
  type: inPatientTypes.UPDATE_INPATIENT_SERVICE_SUCCESS,
  payload,
});

const updateInPatientFailed = (msg) => ({
  type: inPatientTypes.UPDATE_INPATIENT_SERVICE_FAILED,
  error: msg,
});

export const updateInPatientService = (current, close, action) => {
  return async (dispatch) => {
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const { name, branches, type, isActive } = current;

    dispatch(updateInPatientStart(actionType));
    try {
      const { data } = await Axios.patch(`/inpatient-services/${current._id}`, {
        isActive,
        name,
        branches,
        type,
      });

      if (data.status) {
        dispatch(updateInPatientSuccess(current));
        _notifySuccess(`${_firstLetterUc(type)} Service ${action || 'updated'} successfully.`);
        close();
      }
    } catch (error) {
      _notifyError(error.message);
      dispatch(updateInPatientFailed(error.message));
    }
  };
};

export const updateInPatientList = (value) => ({
  type: inPatientTypes.UPDATE_INPATIENT_SERVICES_LIST,
  payload: {
    filteredItems: value,
  },
});
