import React from 'react';
import PropTypes from 'prop-types';
import { customTwMerge } from 'utils';

const FormGroup = ({ children, className }) => {
  return (
    <div
      className={customTwMerge(
        'tw-relative tw-px-[2.0rem] tw-bg-white tw-py-[1rem] tw-border tw-border-natural-300' +
          ' tw-rounded-[4px] tw-transition tw-duration-200 tw-group focus-within:tw-shadow-[0_0_1px_1px_rgba(0,0,0,0.1)]',
        className,
      )}
    >
      {children}
    </div>
  );
};

FormGroup.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export { FormGroup };
