import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PaginationComponent, PaginationControls } from 'components';
import { _breakItemsIntoPage } from 'utils';
import { useViewport } from 'hooks/useViewport';
import { NoContent } from './noContent';

const renderContent = content => {
  return content.map(item => {
    return item;
  });
};

export const TableCards = ({ dataSource, cssClass }) => {
  const content =
    dataSource && dataSource.length > 0 ? (
      renderContent(dataSource)
    ) : cssClass ? (
      <div style={{ width: '100%' }}>
        <NoContent title="No record found" subTitle="" icon="computer" leftControl={null} rightControl={null} />
      </div>
    ) : (
      <div className="no-content text-center">
        <p className="heading color-dark">{`No Content`}</p>
      </div>
    );

  return <div className={`${cssClass || 'card card__table-listing '}`}>{content}</div>;
};

TableCards.defaultProps = {
  dataSource: [],
  cssClass: '',
};

TableCards.propTypes = {
  dataSource: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  cssClass: PropTypes.string,
};

export const Table = ({ columns, dataSource, className, actionIcons, noContent, noMinHeight }) => {
  const content =
    dataSource && dataSource.length > 0 ? (
      renderContent(dataSource)
    ) : (
      <tr>
        <td colSpan={columns.length}>{noContent || <div className="text-center">No Content</div>}</td>
      </tr>
    );

  const headerFunctions = columns.filter(item => typeof item === 'function');

  return (
    <div className="table-responsive" style={{ minHeight: noMinHeight ? 'none' : '50vh' }}>
      <table className={`table ${className}`}>
        <thead className="table-header">
          <tr>
            {columns.map((name, index) => {
              return (
                typeof name !== 'function' && (
                  <th className="table-head" scope="col" key={index}>
                    {name}
                  </th>
                )
              );
            })}
            {headerFunctions.map((Item, index) => {
              return <Item key={`functional-col-${index}`} />;
            })}
            {actionIcons && <th className="table-head" scope="col" />}
          </tr>
        </thead>
        <tbody className="table-body">{content}</tbody>
      </table>
    </div>
  );
};

Table.defaultProps = {
  actionIcons: true,
  noMinHeight: false,
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
  noContent: PropTypes.object,
  actionIcons: PropTypes.bool,
  noMinHeight: PropTypes.bool,
  dataSource: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

const TableExtended = ({ columns, dataSource, className, actionIcons }) => {
  const content =
    dataSource && dataSource.length > 0 ? (
      renderContent(dataSource)
    ) : (
      <tr>
        <td colSpan={columns.length}>No Content</td>
      </tr>
    );

  return (
    <div className="table-responsive">
      <table className={`table table-borderless table-sm ${className}`}>
        <thead className="table-header">
          <tr scope="col">
            {columns.map((item, index) => {
              const header = item.header ? item.header : item;
              const flexClass = item.option ? ' justify-content-end' : ' justify-space-around';
              return (
                <th className="table-head" scope="col" key={index}>
                  <div className={`d-flex ${flexClass} align-items-center`}>
                    {header} {item.option && <i className="iconmoon icon-option ml-3" />}
                  </div>
                </th>
              );
            })}
            {actionIcons && <th className="table-head" scope="col" />}
          </tr>
        </thead>
        <tbody className="table-content">{content}</tbody>
      </table>
    </div>
  );
};

TableExtended.defaultProps = {};

TableExtended.propTypes = {
  columns: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  className: PropTypes.string,
  noContent: PropTypes.object,
  actionIcons: PropTypes.bool,
  dataSource: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  alerts: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export const TableListing = ({ list = [], filteredList = [], columns, tableData, cardData, pageLimit, isPaginate, ...props }) => {
  const [pageArray, setPage] = useState([[]]);
  const [pageIndex, setPageIndex] = useState(0);

  const [isMobile] = useViewport();

  useEffect(() => {
    const newPageItems = _breakItemsIntoPage(filteredList, pageLimit);
    if (!newPageItems.map((item, index) => index).includes(pageIndex)) {
      if (newPageItems.length > 1) {
        setPageIndex(newPageItems.length - 1);
      } else {
        setPageIndex(0);
      }
    }
    setPage(newPageItems);
  }, [filteredList]);

  const nextPage = () => {
    if (pageIndex < pageArray.length - 1) {
      setPageIndex(pageIndex + 1);
    }
  };

  const prevPage = () => {
    if (pageIndex > 0) {
      setPageIndex(pageIndex - 1);
    }
  };

  const goToPage = e => {
    const { dataset } = e.target;
    setPageIndex(parseInt(dataset.id, 10));
  };

  const dataSource = pageArray.length
    ? pageArray[pageIndex].map((selected, index) => {
        if (isMobile && typeof cardData === 'function') {
          return cardData(selected);
        }
        return tableData(selected, index);
      })
    : [];
  return (
    <React.Fragment>
      {list && list.length > 0 ? (
        <>
          {isMobile && cardData ? (
            <TableCards dataSource={dataSource} />
          ) : (
            <Table columns={columns} dataSource={dataSource} {...props} />
          )}
          {isPaginate && (<PaginationComponent
            data={pageArray}
            next={nextPage}
            prev={prevPage}
            goTo={goToPage}
            currentPage={pageIndex}
          />)}
        </>
      ) : null}
    </React.Fragment>
  );
};

TableListing.defaultProps = {
  filteredList: [],
  isPaginate: true
};

TableListing.propTypes = {
  list: PropTypes.array.isRequired,
  filteredList: PropTypes.array,
  columns: PropTypes.array.isRequired,
  tableData: PropTypes.func,
  cardData: PropTypes.func,
  triggerPagination: PropTypes.func,
  setQuery: PropTypes.func,
  pageLimit: PropTypes.number,
  isPaginate: PropTypes.bool,
};

/**
 * Table is used for server side paginated lists
 */
//eslint-disable-next-line
export const TableList = ({
  list = [],
  columns,
  tableData,
  cardData,
  pagination,
  triggerPagination,
  setQuery,
  actionIcons,
}) => {
  const [isMobile] = useViewport();
  const dataSource = list.length
    ? list.map(selected => (isMobile && cardData ? cardData(selected) : tableData(selected)))
    : [];

  return (
    <React.Fragment>
      {list && list.length > 0 ? (
        <>
          {isMobile && cardData ? (
            <TableCards dataSource={dataSource} />
          ) : (
            <Table columns={columns} dataSource={dataSource} actionIcons={actionIcons} />
          )}
          <PaginationControls
            totalRecords={pagination && pagination.totalDocs}
            pageLimit={pagination && pagination.limit}
            pageNeighbours={4}
            paginationHandler={triggerPagination}
            pageHandler={setQuery}
            currentPage={pagination.page}
          />
        </>
      ) : null}
    </React.Fragment>
  );
};

TableList.defaultProps = {
  filteredList: [],
  actionIcons: true,
};

TableList.propTypes = {
  list: PropTypes.array.isRequired,
  filteredList: PropTypes.array,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  tableData: PropTypes.func,
  cardData: PropTypes.func,
  triggerPagination: PropTypes.func,
  setQuery: PropTypes.func,
  actionIcons: PropTypes.bool,
};

export const TableCollapsible = ({
  list = [],
  columns,
  tableData,
  cardData,
  pagination,
  triggerPagination,
  setQuery,
}) => {
  const [isMobile] = useViewport();
  const dataSource = list.length
    ? list.map(selected => (isMobile && cardData ? cardData(selected) : tableData(selected)))
    : [];

  return (
    <React.Fragment>
      {list && list.length > 0 ? (
        <>
          {isMobile && cardData ? (
            // <TableCards dataSource={dataSource} />
            <div>No card yet</div>
          ) : (
            <TableExtended columns={columns} dataSource={dataSource} />
          )}
          <PaginationControls
            totalRecords={pagination && pagination.totalDocs}
            pageLimit={pagination && pagination.limit}
            pageNeighbours={4}
            paginationHandler={triggerPagination}
            pageHandler={setQuery}
            currentPage={pagination.page}
          />
        </>
      ) : null}
    </React.Fragment>
  );
};

TableCollapsible.defaultProps = {
  filteredList: [],
};

TableCollapsible.propTypes = {
  list: PropTypes.array.isRequired,
  filteredList: PropTypes.array,
  columns: PropTypes.array.isRequired,
  pagination: PropTypes.object,
  tableData: PropTypes.func,
  cardData: PropTypes.func,
  triggerPagination: PropTypes.func,
  setQuery: PropTypes.func,
};
