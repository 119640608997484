import React, { lazy, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import MainLayout from '../../../layouts/MainLayout';
import AuthRoute from '../../../routes/AuthRoute';
import { fetchBranches, setCurrency } from 'redux/actions';
import { getServices } from '../../inventory/redux/actions';
import { getSerializedParams } from 'utils';
import { fetchSubscriptions } from '../redux/actions';

const SetUp = lazy(() => import('../pages/HospitalSetUp'));
const Branches = lazy(() => import('../pages/Branches'));
const Bank = lazy(() => import('../pages/Bank'));
const RegistrationCustomization = lazy(() => import('../pages/RegistrationCustomization'));
const BranchConfig = lazy(() => import('../pages/BranchConfig'));
const Departments = lazy(() => import('../pages/Departments'));
const Staff = lazy(() => import('../pages/Staff'));
const Roles = lazy(() => import('../pages/Roles'));
const HMO = lazy(() => import('../pages/HMO'));
const CentralConfig = lazy(() => import('../../inventory/pages/InventorySettings/CentralConfig'));
const PatientsList = lazy(() => import('../pages/PatientsList'));
const Plans = lazy(() => import('../pages/Plans'));
const AdmissionManagement = lazy(() => import('../pages/AdmissionManagement'));
const MedicalStandards = lazy(() => import('../pages/MedicalStandards'));
const Inventory = lazy(() => import('../pages/Inventory/index'));
const Category = lazy(() => import('../pages/Category/index'));
const ErrorPage = lazy(() => import('../../../pages/Error'));
const BulkUploads = lazy(() => import('../pages/BulkUploads'));
const Subscription = lazy(() => import('../pages/Subscription'));
const Calendar = lazy(() => import('../pages/Schedules'));
const HospitalPerformance = lazy(() => import('../pages/Dashboard/HospitalPerformance'));
const InventoryManagement = lazy(() => import('../pages/Dashboard/InventoryManagement'));
const FinancialRevenue = lazy(() => import('../pages/Dashboard/FinancialRevenue'));
const PatientHealth = lazy(() => import('../pages/Dashboard/PatientHealth'));
const License = lazy(() => import('../pages/License/index'));

const SettingsRoutes = ({ match }) => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state) => state.auth);
  const current = useSelector((state) => state.user?.current);
  // const branchId = useSelector(store => store.company?.location?.currentLocation?._id);
  const currency = useSelector((store) => store.branches?.items?.[0]?.currency?.code);

  useEffect(() => {
    if (!currency) return;
    dispatch(setCurrency(currency));
  }, [currency]);

  useEffect(() => {
    dispatch(fetchBranches());
    dispatch(getServices(getSerializedParams({ limit: 10000 }), current?.branchId || undefined));
    dispatch(fetchSubscriptions());
  }, []);

  return (
    <>
      <Switch>
        <Redirect exact from={`${match.path}`} to={`${match.path}/hospital-setup`} />
        <AuthRoute
          exact
          path={`${match.path}`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={SetUp}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/hospital-setup`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={SetUp}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/branches`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Branches}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/branch-config`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={BranchConfig}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/departments`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Departments}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/roles`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Roles}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/discount`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Inventory}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/sponsors`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={HMO}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/patients`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={PatientsList}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/banks`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Bank}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/plans`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Plans}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/staff`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Staff}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/license`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={License}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/categories`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Category}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/reg-custom`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={RegistrationCustomization}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/admission-mgt`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={AdmissionManagement}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/medic-standard`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={MedicalStandards}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/central-config`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={CentralConfig}
          accessGranted={true}
        />
        <AuthRoute
          path={`${match.path}/bulk-uploads`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={BulkUploads}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/schedules`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Calendar}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/subscription`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Subscription}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/dashboard/hospital-performance`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={HospitalPerformance}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/dashboard/inventory-management`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={InventoryManagement}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/dashboard/patient-health`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={PatientHealth}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/dashboard/financial-revenue`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={FinancialRevenue}
          accessGranted={true}
        />
        <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
      </Switch>
    </>
  );
};

SettingsRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default SettingsRoutes;
