import { _firstLetterUc, _notifyError, _notifySuccess, isObjectEmpty } from 'utils';
import * as inventoryTypes from '../types/inventoryTypes';
import { beginApiCall, endApiCall } from 'redux/actions/apiActions';
import Axios from 'services/axios';
import { createTransferSuccess } from './inventoryTransferActions';
import { currentBranch } from 'redux/store';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

/**
 * InventorySettings Configurations
 */
const getInventoryConfigurationSuccess = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_CONFIGURATION_SUCCESS,
  payload,
});

export const getInventoryConfiguration = () => {
  return async (dispatch) => {
    dispatch(beginApiCall());
    try {
      const { data } = await Axios.get('/configurations');

      if (data.status) {
        const payload = { configurations: data.data ? data.data.inventory : {} };
        dispatch(getInventoryConfigurationSuccess(payload));
        dispatch(endApiCall());
      }
      dispatch(endApiCall());
    } catch (err) {
      dispatch(endApiCall());
    }
  };
};

export const getValueAddedServices = (branchId) => async () => {
  let url = '/vas';
  if (branchId) url += `?branchId=${branchId}`;
  try {
    const response = await Axios.get(url);
    return Promise.resolve(response?.data?.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

const setInventoryConfigurationSuccess = (payload) => ({
  type: inventoryTypes.SET_INVENTORY_CONFIGURATION_SUCCESS,
  payload,
});

export const setInventoryConfiguration = (current) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios.post('/configurations', current);
      if (data.status) {
        const payload = { configurations: current.inventory };
        dispatch(setInventoryConfigurationSuccess(payload));
        _notifySuccess('Created successfully.');
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
    }
  };
};

const addInventoryItemStart = () => ({ type: inventoryTypes.ADD_INVENTORY_ITEM_START });

const addInventoryItemFailure = (error) => ({
  type: inventoryTypes.ADD_INVENTORY_ITEM_FAILED,
  error,
});

const addInventoryItemSuccess = (payload) => ({
  type: inventoryTypes.ADD_INVENTORY_ITEM_SUCCESS,
  payload,
});

export const addInventoryItem = (payload, close) => {
  return async (dispatch) => {
    dispatch(addInventoryItemStart());
    try {
      const { data } = await Axios.post('/items', payload);
      if (data.status) {
        dispatch(addInventoryItemSuccess(data.data));
        _notifySuccess(`Created ${_firstLetterUc(payload.type)} successfully.`);
        close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(addInventoryItemFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateInventoryItemStart = () => ({ type: inventoryTypes.UPDATE_INVENTORY_ITEM_START });

const updateInventoryItemFailure = (error) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ITEM_FAILED,
  error,
});

const updateInventoryItemSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ITEM_SUCCESS,
  payload,
});

export const updateInventoryItem = (payload, itemId, close) => {
  return async (dispatch) => {
    dispatch(updateInventoryItemStart());
    try {
      const { data } = await Axios.patch(`/items/${itemId}`, payload);
      if (data.status) {
        dispatch(updateInventoryItemSuccess({ ...data.data, _id: itemId }));
        _notifySuccess(`Updated ${_firstLetterUc(payload.type)} successfully.`);
        close();
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(updateInventoryItemFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const getInventoryProduct = (id) => {
  return async () => {
    try {
      const { data } = await Axios.get(`/products/${id}`);
      if (data.status) {
        return data.data;
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      return undefined;
    }
  };
};

const addInventoryItemStockStart = () => ({ type: inventoryTypes.ADD_INVENTORY_ITEM_STOCK_START });

const addInventoryItemStockFailure = (error) => ({
  type: inventoryTypes.ADD_INVENTORY_ITEM_STOCK_FAILED,
  error,
});

export const addInventoryItemStock = (payload, productId, close) => {
  return async (dispatch) => {
    dispatch(addInventoryItemStockStart());
    try {
      const { data } = await Axios.post(`/products/${productId}/stocks`, payload);
      if (data.status === 'true' || data.status === true) {
        dispatch(updateInventoryItemSuccess(data.data));
        _notifySuccess('Created stock successfully.');
        close();
      }
    } catch (error) {
      _notifyError(error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE);
      dispatch(addInventoryItemStockFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const updateInventoryItemStockStart = () => ({ type: inventoryTypes.UPDATE_INVENTORY_ITEM_STOCK_START });

const updateInventoryItemStockFailure = (error) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ITEM_STOCK_FAILED,
  error,
});

const updateInventoryItemStockSuccess = (payload) => ({
  type: inventoryTypes.UPDATE_INVENTORY_ITEM_STOCK_SUCCESS,
  payload,
});

export const updateInventoryItemStock = (payload, productId, close) => {
  const { expiryDate, batchId, costPrice, supplier, variant } = payload;
  const pay = { expiryDate, batchId, costPrice, supplier };
  if (variant) {
    pay.variant = variant;
  }
  return async (dispatch) => {
    dispatch(updateInventoryItemStockStart());
    try {
      const { data } = await Axios.patch(`/stocks/${payload._id}`, pay);
      if (data.status) {
        const cache = {
          productId,
          data: { ...payload, ...data.data },
        };

        dispatch(updateInventoryItemStockSuccess(cache));
        _notifySuccess('Updated stock successfully.');
        close();
      }
    } catch (error) {
      _notifyError(error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE);
      dispatch(updateInventoryItemStockFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const getInventoryStock = (id) => {
  return async () => {
    try {
      const { data } = await Axios.get(`/stocks/${id}`);
      if (data.status) {
        return data.data;
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      return undefined;
    }
  };
};

const updateStockVisibilityStart = () => ({
  type: inventoryTypes.UPDATE_STOCK_VISIBILITY_START,
});

const updateStockVisibilitySuccess = (payload) => ({
  type: inventoryTypes.UPDATE_STOCK_VISIBILITY_SUCCESS,
  payload,
});

const updateStockVisibilityFailed = () => ({
  type: inventoryTypes.UPDATE_STOCK_VISIBILITY_FAILED,
});

export const updateStockVisibility = (payload, productId, stockId, close, isRestoring) => {
  return async (dispatch) => {
    dispatch(updateStockVisibilityStart());
    try {
      const url = isRestoring
        ? `stocks/${stockId}/restore`
        : `/${productId ? 'products' : 'stocks'}/${stockId}/archive`;
      const { data } = await Axios.post(url, payload);
      if (data.status) {
        dispatch(updateStockVisibilitySuccess({ productId, stockId, isRestoring }));
        _notifySuccess(`Stock item ${isRestoring ? 'Restored' : 'Archived'} successfully.`);
        close();
        return true;
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(updateStockVisibilityFailed(CUSTOM_ERROR_MESSAGE));
      return false;
    }
  };
};

/**
 * InventorySettings Product
 */

const getProductsStart = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_PRODUCTS_START,
  payload,
});

const getProductsSuccess = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_PRODUCTS_SUCCESS,
  payload,
});

const getProductsFailure = (payload) => ({
  type: inventoryTypes.GET_INVENTORY_PRODUCTS_FAILURE,
  payload,
});

export const getProducts = (queryDetails, location) => {
  const url = `/locations/${location}/items?type=product${queryDetails ? `&${queryDetails}` : ''}`;
  return async (dispatch) => {
    dispatch(getProductsStart());

    try {
      const { data } = await Axios.get(url);

      if (data.status) {
        const payload = { products: data.data };
        dispatch(getProductsSuccess(payload));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getProductsFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const getArchivedProducts = (queryDetails) => {
  const url = `/stocks/archives?${queryDetails ? `&${queryDetails}` : ''}`;
  return async (dispatch) => {
    dispatch(getProductsStart());

    try {
      const { data } = await Axios.get(url);

      if (data.status) {
        const payload = { products: data.data };
        dispatch(getProductsSuccess(payload));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getProductsFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const listBranchProducts = (location, queryDetails) => {
  return async (dispatch) => {
    dispatch(getProductsStart());

    try {
      const { data } = await Axios.get(
        `/locations/${location}/items?type=product${queryDetails ? `?${queryDetails}` : ''}`,
      );
      if (data.status) {
        const payload = { products: data.data };
        dispatch(getProductsSuccess(payload));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getProductsFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const getArchivedStockStart = (payload) => ({
  type: inventoryTypes.GET_ARCHIVED_STOCK_START,
  payload,
});

const getArchivedStockSuccess = (payload) => ({
  type: inventoryTypes.GET_ARCHIVED_STOCK_SUCCESS,
  payload,
});

const getArchivedStockFailed = (payload) => ({
  type: inventoryTypes.GET_ARCHIVED_STOCK_FAILED,
  payload,
});

export const getArchivedStocks = () => {
  return async (dispatch) => {
    dispatch(getArchivedStockStart());
    try {
      const { data } = await Axios.get('/stocks/archived');
      if (data.status) {
        const payload = { products: data.data };
        dispatch(getArchivedStockSuccess(payload));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(getArchivedStockFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

const initiateTransferStart = () => ({ type: inventoryTypes.INITIATE_TRANSFER_START });

const initiateTransfersEnd = () => ({ type: inventoryTypes.INITIATE_TRANSFER_END });

export const initiateTransfers = (payload, close) => {
  const { from, to, createdBy } = payload;

  return async (dispatch) => {
    dispatch(initiateTransferStart());
    try {
      const { data } = await Axios.post('/transfers', payload);
      if (data.status) {
        _notifySuccess('Items transferred successfully!');
        const cache = { ...data.data, from, to, createdBy };
        dispatch(createTransferSuccess(cache));
        dispatch(initiateTransfersEnd());
        close();
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(initiateTransfersEnd());
    }
  };
};

export const getProductsByName = async (location, name, providedUrl) => {
  try {
    // 1010 ,  get it ?
    const url = providedUrl ? providedUrl : `/locations/${location}/items?type=product`;
    const { data } = await Axios.get(`${url}&limit=1010&${isObjectEmpty(name) ? '' : `name=${name}`}`);
    if (data.status) {
      return (data.data || {}).docs || [];
    }
  } catch (error) {
    _notifyError('Cannot retrieve products right now');
  }
};

export const getProductsByBatchId = async (batchId) => {
  try {
    const { data } = await Axios.get(`/stocks/search?batchId=${batchId}`);
    if (data.status) {
      return data.data;
    }
  } catch (error) {
    _notifyError("Cannot retrieve batch id's right now");
  }
};

const setReorderLevelStart = () => ({
  type: inventoryTypes.SET_REORDER_LEVEL_START,
});

const setReorderLevelSuccess = (payload) => ({
  type: inventoryTypes.SET_REORDER_LEVEL_SUCCESS,
  payload,
});

const setReorderLevelFailure = () => ({
  type: inventoryTypes.SET_REORDER_LEVEL_FAILED,
});

export const setReorderLevel = (stockId, payload, close) => {
  return async (dispatch) => {
    dispatch(setReorderLevelStart());
    try {
      const url = `branches/${currentBranch()}/items/${stockId}/set-reorder-level`;
      const { data } = await Axios.patch(url, payload);
      if (data.status) {
        dispatch(setReorderLevelSuccess({ stockId }));
        _notifySuccess(`Reorder-level successfully set.`);
        close();
        return true;
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
      dispatch(setReorderLevelFailure(CUSTOM_ERROR_MESSAGE));
      return false;
    }
  };
};
