import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export const Checkbox = React.forwardRef((props, ref) => (
  <div className={`form-box ${props.className}`}>
    <input
      ref={ref}
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      checked={props.checked}
      onChange={props.onChange}
      {...(props.register && props.register(props.name, { ...props.validation }))}
    />
    <label htmlFor={props.id}>
      {props.rightBox ? (
        <>
          <span className="text"> {props.text} </span>
          <span
            className={classnames('ml-3 box', {
              'is-checked': props.checked,
            })}
          >
            {' '}
          </span>
        </>
      ) : (
        <>
          <span
            className={classnames('box', {
              'is-checked': props.checked,
            })}
          >
            {' '}
          </span>
          <span className="text"> {props.text} </span>
        </>
      )}
    </label>
  </div>
));

Checkbox.displayName = 'Checkbox';

Checkbox.defaultProps = {
  className: '',
  type: 'checkbox',
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  rightBox: PropTypes.bool,
  text: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.any,
  validation: PropTypes.object,
  register: PropTypes.func,
};
