import * as testStandardsTypes from '../types/testStandardsTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject } from 'utils';

export const testStandards = (state = initialState.suppliers, action) => {
  switch (action.type) {
    case testStandardsTypes.FETCH_TEST_STANDARDS_SUCCESS:
      return updateObject(state, { ...action.payload, loading: false });

    case testStandardsTypes.FETCH_TEST_STANDARDS_FAILED:
      return updateObject(state, { error: action.error });

    case testStandardsTypes.FETCH_TEST_STANDARDS_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    default:
      return { ...state };
  }
};
