import * as taskManagementTypes from '../types/taskManagementTypes';
import Axios from 'services/axios';
import { completed, dispatched, inprogress, pending, declined, todo, CUSTOM_ERROR_MESSAGE } from 'utils/constants';
import { _notifyError, _notifySuccess } from 'utils';

const handleErrorResponse = (response, dispatch, func) => {
  _notifyError(
    response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
  );
  dispatch(func(CUSTOM_ERROR_MESSAGE));
};

const dispatchTasks = (status, data, prevDocs) => {
  switch (status) {
    case todo:
      return { pendingTasks: { ...data, docs: [...prevDocs, ...data.docs], isLoading: false } };
    case declined:
      return { declinedTasks: { ...data, docs: [...prevDocs, ...data.docs], isLoading: false } };
    case pending:
      return { acceptedTasks: { ...data, docs: [...prevDocs, ...data.docs], isLoading: false } };
    case inprogress:
      return { activeTasks: { ...data, docs: [...prevDocs, ...data.docs], isLoading: false } };
    case completed:
      return { completedTasks: { ...data, docs: [...prevDocs, ...data.docs], isLoading: false } };
    case dispatched:
      return { deliveredTasks: { ...data, docs: [...prevDocs, ...data.docs], isLoading: false } };
    case 'all':
      return { allTasks: { ...data, docs: [...prevDocs, ...data.docs], isLoading: false } };
    default:
      return { allTasks: { ...data, docs: [...prevDocs, ...data.docs], isLoading: false } };
  }
};

const fetchTasksStart = (status) => ({
  type: taskManagementTypes.FETCH_TASKS_START,
  payload: { [`${status}Tasks`]: { isLoading: true } },
});
const fetchTasksSuccess = (payload) => ({
  type: taskManagementTypes.FETCH_TASKS_SUCCESS,
  payload,
});
const fetchTasksFailure = (status) => ({
  type: taskManagementTypes.FETCH_TASKS_FAILURE,
  payload: { [`${status}Tasks`]: { isLoading: false } },
});
const addTasksSuccess = () => ({
  type: taskManagementTypes.ADD_TASK_SUCCESS,
});

const selectedTask = (payload) => ({
  type: taskManagementTypes.SELECT_TASK,
  payload,
});
const clearSelectedTask = () => ({ type: taskManagementTypes.CLEAR_SELECTED_TASK });

const assignTaskStart = () => ({ type: taskManagementTypes.ASSIGN_TASK_START });
const assignTaskSuccess = (payload) => ({
  type: taskManagementTypes.ASSIGN_TASK_SUCCESS,
  payload,
});
const assignTaskFailure = (error) => ({
  type: taskManagementTypes.ASSIGN_TASK_FAILURE,
  payload: error,
});

const unassignTaskStart = () => ({ type: taskManagementTypes.UNASSIGN_TASK_START });
const unassignTaskSuccess = (payload) => ({
  type: taskManagementTypes.UNASSIGN_TASK_SUCCESS,
  payload,
});
const unassignTaskFailure = (error) => ({
  type: taskManagementTypes.UNASSIGN_TASK_FAILURE,
  payload: error,
});

const changeTaskStatus = (status) => {
  switch (status) {
    case 'start':
      return { type: taskManagementTypes.CHANGE_TASK_STATUS_START };
    case 'accept':
      return { type: taskManagementTypes.ACCEPT_TASK };
    case 'decline':
      return { type: taskManagementTypes.DECLINE_TASK };
    case 'active':
      return {
        type: taskManagementTypes.START_TASK_SUCCESS,
      };
    case 'failure':
      return {
        type: taskManagementTypes.CHANGE_TASK_STATUS_FAILURE,
      };
    case 'completed':
      return { type: taskManagementTypes.COMPLETE_TASK_SUCCESS };
    case 'delivered':
      return { type: taskManagementTypes.DELIVER_TASK_SUCCESS };
    default:
      return { type: taskManagementTypes.CHANGE_TASK_STATUS_FAILURE };
  }
};

export const fetchTasks = (queryParams) => {
  return async (dispatch) => {
    dispatch(fetchTasksStart('all'));
    try {
      const { data } = await Axios.get(`/tasks?${queryParams && queryParams}`);
      if (data.status) {
        dispatch(fetchTasksSuccess(dispatchTasks('all', data.data, [])));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(fetchTasksFailure('all'));
    }
  };
};

export const fetchTasksPerStatus = (status, queryParams, prevData = []) => {
  return async (dispatch) => {
    prevData.length === 0 && dispatch(fetchTasksStart(status));
    try {
      const { data } = await Axios.get(
        `/tasks?${status === 'all' ? '' : 'status=' + status + '&'}${queryParams ? queryParams : ''}`,
      );
      if (data.status) {
        dispatch(fetchTasksSuccess(dispatchTasks(status, data.data, prevData)));
      }
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : CUSTOM_ERROR_MESSAGE;
      _notifyError(errorMessage);
      dispatch(fetchTasksFailure(status));
    }
  };
};

export const getActiveTask = (task) => {
  return async (dispatch) => {
    dispatch(selectedTask(task));
  };
};

export const clearActiveTask = () => {
  return async (dispatch) => {
    dispatch(clearSelectedTask());
  };
};

export const addTask = (details, setIsSaving, close) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios.post('/tasks', details);
      if (data.status) {
        dispatch(addTasksSuccess());
        _notifySuccess('Task Added Successfully');
        dispatch(fetchTasksPerStatus(pending));
        setIsSaving(false);
        close();
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      setIsSaving(false);
    }
  };
};

export const assignTask = (taskId, staffId) => {
  return async (dispatch) => {
    dispatch(assignTaskStart());
    try {
      const { data } = await Axios.post(`/tasks/${taskId}/assign/${staffId}`);
      if (data.status) {
        const { data } = await Axios.get(`tasks/${taskId}`);
        dispatch(assignTaskSuccess(data.data.assignees));
        dispatch(fetchTasksPerStatus(data.data.status));
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch(assignTaskFailure(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const unassignTask = (taskId, staffId) => {
  return async (dispatch) => {
    dispatch(unassignTaskStart());
    try {
      const { data } = await Axios.post(`/tasks/${taskId}/unassign/${staffId}`);
      if (data.status) {
        const { data } = await Axios.get(`tasks/${taskId}`);
        dispatch(unassignTaskSuccess(data.data.assignees));
        dispatch(fetchTasksPerStatus(data.data.status));
      }
    } catch ({ response }) {
      handleErrorResponse(response, dispatch, unassignTaskFailure);
    }
  };
};

export const startTaskAction = (taskId, close) => {
  return async (dispatch) => {
    dispatch(changeTaskStatus('start'));
    try {
      const { data } = await Axios.post(`/tasks/${taskId}/start`);
      if (data.status) {
        dispatch(changeTaskStatus('active'));
        _notifySuccess('Task Started Successfully');
        close();
        dispatch(fetchTasksPerStatus('active'));
        dispatch(fetchTasksPerStatus('accepted'));
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch(changeTaskStatus('failure'));
    }
  };
};

export const completeTask = (taskId, close) => {
  return async (dispatch) => {
    dispatch(changeTaskStatus('start'));
    try {
      const { data } = await Axios.post(`/tasks/${taskId}/complete`);
      if (data.status) {
        dispatch(changeTaskStatus('completed', data.data));
        _notifySuccess('Task Status Updated');
        close();
        dispatch(fetchTasksPerStatus(inprogress));
        dispatch(fetchTasksPerStatus(completed));
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch(changeTaskStatus('failure', CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const deliverTaskAction = (taskId, deets, close) => {
  return async (dispatch) => {
    dispatch(changeTaskStatus('start'));
    try {
      const { data } = await Axios.post(`/tasks/${taskId}/deliver`, deets);
      if (data.status) {
        dispatch(changeTaskStatus('delivered'));
        _notifySuccess(`Task ${data.data.delivery.method}`);
        close();
        dispatch(fetchTasksPerStatus(completed));
        dispatch(fetchTasksPerStatus(dispatched));
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch(changeTaskStatus('failure', CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const fetchClients = () => {
  return async (dispatch) => {
    dispatch({ type: taskManagementTypes.GET_CLIENTS_START });

    try {
      const { data } = await Axios.get('/companies');
      if (data.status) {
        dispatch({ type: taskManagementTypes.GET_CLIENTS_SUCCESS, payload: data.data });
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch({ type: taskManagementTypes.GET_CLIENTS_FAILURE });
    }
  };
};

export const addComments = (taskId, comment, close) => {
  return async (dispatch) => {
    dispatch({ type: taskManagementTypes.ADD_COMMENT_START });
    try {
      const { data } = await Axios.post(`tasks/${taskId}/comments`, { body: comment });
      if (data.status) {
        dispatch({ type: taskManagementTypes.ADD_COMMENT_SUCCESS });
        _notifySuccess('Comment Added Successfully');
        close();
        dispatch(fetchTasksPerStatus(inprogress));
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch({ type: taskManagementTypes.ADD_COMMENT_FAILURE });
    }
  };
};

export const addResultAction = (taskId, details, completeTaskTrigger) => {
  return async (dispatch) => {
    dispatch({ type: taskManagementTypes.ADD_RESULT_START });
    try {
      const { data } = await Axios.post(`/tasks/${taskId}/result`, details);
      if (data.status) {
        dispatch({ type: taskManagementTypes.ADD_RESULT_SUCCESS, payload: data.data.result });
        _notifySuccess('Result Added Successfully');
        completeTaskTrigger();
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch({ type: taskManagementTypes.ADD_RESULT_FAILURE });
    }
  };
};

export const extendDueDate = (taskId, dueDate) => {
  return async (dispatch) => {
    dispatch({ type: taskManagementTypes.EXTEND_DUE_DATE_START });
    try {
      const { data } = await Axios.patch(`/tasks/${taskId}/extend-date`, { dueDate });
      if (data.status) {
        dispatch({ type: taskManagementTypes.EXTEND_DUE_DATE_SUCCESS, payload: data.data.dueDate });
        _notifySuccess('Date Extended Successfully');
        dispatch(fetchTasksPerStatus(data.data.status));
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch({ type: taskManagementTypes.EXTEND_DUE_DATE_FAILURE });
    }
  };
};

export const acceptTaskAction = (taskId, close) => {
  return async (dispatch) => {
    dispatch(changeTaskStatus('start'));
    try {
      const { data } = await Axios.post(`tasks/${taskId}/accept`);
      if (data.status) {
        dispatch(changeTaskStatus('accept'));
        _notifySuccess('Task Accepted');
        dispatch(fetchTasksPerStatus(data.data.status));
        dispatch(fetchTasksPerStatus(todo));
        close();
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch(changeTaskStatus('failure', CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const declineTaskAction = (taskId, reason, close) => {
  return async (dispatch) => {
    dispatch(changeTaskStatus('start'));
    try {
      const { data } = await Axios.post(`tasks/${taskId}/decline`, { reason });
      if (data.status) {
        _notifySuccess('Task Declined');
        dispatch(changeTaskStatus('decline'));
        dispatch(fetchTasksPerStatus(data.data.status));
        dispatch(fetchTasksPerStatus(todo));
        close();
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch(changeTaskStatus('failure', CUSTOM_ERROR_MESSAGE));
    }
  };
};

const getQueryParams = (queryParams) => {
  const urlParams = new URLSearchParams(queryParams);
  const branchId = urlParams.get('branchId');
  const fromDate = urlParams.get('fromDate');
  const toDate = urlParams.get('toDate');
  return { branchId, fromDate, toDate };
};

export const getStatusBreakDown = (queryParams) => {
  return async (dispatch) => {
    dispatch({ type: taskManagementTypes.FETCH_STATUS_BREAKDOWN_START });
    try {
      const { data } = await Axios.get(`/tasks/report/status-breakdown?${queryParams && queryParams}`);
      if (data.status) {
        dispatch({
          type: taskManagementTypes.FETCH_STATUS_BREAKDOWN_SUCCESS,
          payload: { ...data.data, params: getQueryParams(queryParams) },
        });
      }
    } catch ({ response }) {
      _notifyError(
        response ? (typeof response.data !== 'string' ? response.data.message : response.data) : CUSTOM_ERROR_MESSAGE,
      );
      dispatch({ type: taskManagementTypes.FETCH_STATUS_BREAKDOWN_FAILURE });
    }
  };
};
