import * as staffTypes from '../types/staffTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject } from 'utils';

export const staff = (state = initialState.staff, action) => {
  switch (action.type) {
    case staffTypes.FETCH_STAFF_SUCCESS:
      return updateObject(state, action.payload);

    case staffTypes.FETCH_STAFF_FAILED:
      return updateObject(state, { error: action.error });

    case staffTypes.ADD_STAFF_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    case staffTypes.ADD_STAFF_SUCCESS:
      return updateObject(state, { ...action.payload, formErrorMessage: null });

    case staffTypes.ADD_STAFF_FAILED:
      return updateObject(state, {
        formErrorMessage: action.error,
        isSaving: false
      });

    case staffTypes.UPDATE_STAFF_START:
      return updateObject(state, { formErrorMessage: null, ...action.payload });

    case staffTypes.UPDATE_STAFF_SUCCESS:
      return updateObject(state, {
        ...action.payload,
        formErrorMessage: null,
        isSaving: false,
        isDeactivating: false
      });

    case staffTypes.UPDATE_STAFF_FAILED:
      return updateObject(state, {
        isSaving: false,
        isDeactivating: false
      });

    case staffTypes.DELETE_STAFF_START:
      return updateObject(state, { isDeactivating: true });

    case staffTypes.DELETE_STAFF_SUCCESS:
      return updateObject(state, { ...action.payload, isDeactivating: false });

    case staffTypes.DELETE_STAFF_FAILED:
      return updateObject(state, { isDeactivating: false });

    case staffTypes.STAFF_PAGE_SORT:
      return updateObject(state, action.payload);

    case staffTypes.UPDATE_STAFF_LIST:
      return updateObject(state, action.payload);

    default:
      return { ...state };
  }
};

export default staff;