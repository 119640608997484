import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import classnames from 'classnames';

const TabLink = ({ title, tabId, path, activeTab, toggle, index, disabled }) => {
  const disabledCheck = disabled || activeTab === tabId;
  return (
    <NavItem title={index + 1}>
      <Link
        className={` ${classnames({ active: activeTab === tabId }, disabledCheck && 'no-after')} nav-link px-md-4 `}
        onClick={() => !disabled && toggle(tabId)}
        to={`${path}?tab=${tabId}`}
        title={index}
      >
        {
          <div className={classnames('nav-link-check', disabledCheck && 'mob-only')}>
            {disabledCheck ? (
              <span className="title-mobile"> {index + 1}</span>
            ) : (
              <span className="mark">&#10003;</span>
            )}
          </div>
        }
        <span className="title">{title}</span>
      </Link>
    </NavItem>
  );
};

TabLink.propTypes = {
  title: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  tabId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
};

export default TabLink;
