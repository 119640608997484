import * as reportTypes from '../types/reportTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject } from 'utils';

export const report = (state = initialState.report, action) => {
  switch (action.type) {
    case reportTypes.FETCH_REPORT_SECTION_FILTERS_START:
      return updateObject(state, { [action.section]: { isLoadingFilters: true } });
    case reportTypes.FETCH_REPORT_SECTION_FILTERS_SUCCESS:
      return updateObject(state, { [action.section]: { filters: action.payload, isLoadingFilters: false } });

    case reportTypes.FETCH_REPORT_SECTIONS_START:
      return updateObject(state, { sections: { isLoading: true } });
    case reportTypes.FETCH_REPORT_SECTIONS_SUCCESS:
      return updateObject(state, { sections: { data: action.payload, isLoading: false } });

    case reportTypes.FETCH_REPORT_START:
      return updateObject(state, { isLoading: true });
    case reportTypes.FETCH_REPORT_SUCCESS:
      return updateObject(state, {
        [action.section]: { ...state[action.section], data: action.payload },
        isLoading: false,
      });
    case reportTypes.FETCH_REPORT_FAIL:
      return updateObject(state, { isLoading: false });

    default:
      return { ...state };
  }
};
