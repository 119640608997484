import { apiConstants } from '../types/apiTypes';
import initialState from 'redux/reducers/initialState';

const apiReducer = (state = initialState.inProgress, action) => {
  if (action.type === apiConstants.BEGIN_API_CALL) {
    return true;
  }

  if (action.type === apiConstants.END_API_CALL) {
    return false;
  }

  return state;
};

export default apiReducer;
