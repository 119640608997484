import React from 'react';
import PropTypes from 'prop-types';

export const NoContent = props => {
  const {
    icon,
    title,
    subTitle,
    leftControl: LeftControl,
    rightControl: RightControl,
    leftControlText,
    rightControlText,
    children,
    cssClass
  } = props;

  return (
    <div className={`nocontent ${cssClass}`}>
      <div className="nocontent-box">
        <div className="nocontent-iconbox mb-5">
          <i className={`iconmoon icon-${icon}`} />
        </div>
        {children || (
          <>
            <h4 className="nocontent-title color-dark">{title}</h4>
            <p className="nocontent-subtitle color-grey">{subTitle}</p>
            <div className="nocontent-buttons">
              {LeftControl && <LeftControl leftControlText={leftControlText} cssClass="mr-3" {...props} />}
              {RightControl && <RightControl rightControlText={rightControlText} {...props} />}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

NoContent.defaultProps = {
  leftControl: () => null,
  rightControl: () => null,
};

NoContent.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  leftControl: PropTypes.func,
  rightControl: PropTypes.func,
  rightControlText: PropTypes.string,
  leftControlText: PropTypes.string,
  cssClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
