import * as emailTypes from '../types/emailTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject } from 'utils';

export const departments = (state = initialState.email, action) => {
  switch (action.type) {
    case emailTypes.SEND_EMAIL_START:
      return updateObject(state, { isLoading: true });
    case emailTypes.SEND_EMAIL_SUCCESS:
      return updateObject(state, { isLoading: false });

    default:
      return { ...state };
  }
};

export default departments;
