import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'components/common';

export const LeftTabs = ({ ...props }) => {
  const { tabs, defaultTab } = props;
  const [activeTab, setActiveTab] = useState('');

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  const activeTabObj = tabs.reduce((acc, tab) => {
    acc[tab.tabId] = tab;
    return acc;
  }, {});

  const { component: Component, ...others } = activeTabObj[activeTab] ?? {};

  return (
    <div className="md:tw-flex tw-gap-x-5 patients-admission">
      <div className="tw-bg-white md:tw-h-[695px] md:tw-overflow-y-auto no-scrollbar">
        <div className="md:tw-grid tw-gap-y-2 md:tw-gap-y-0 tw-hidden">
          {tabs.map((tab, index) => {
            let isTabActive = activeTab === tab['tabId'];
            return (
              <>
                <button
                  className={`tw-appearance-none tw-my-0 tw-shadow-none tw-text-semibold tw-text-[1.4rem] 
                  tw-whitespace-nowrap first:tw-rounded-tl-md first:tw-rounded-tr-md last:tw-rounded-bl-md 
                  last:tw-rounded-br-md left-tab-layout-tabs-item ${
                    isTabActive ? 'tw-text-primary-500 tw-bg-primary-100' : 'tw-text-natural-500'
                  }`}
                  key={`left-tab-${tab.tabId}${index}`}
                  onClick={() => setActiveTab(tab.tabId)}
                >
                  <i className={tab.tabIcon} />

                  <p>{tab.title}</p>
                </button>
              </>
            );
          })}
        </div>
        <div className="tw-gap-y-2 md:tw-gap-y-0 md:tw-hidden">
          <Select
            options={tabs.map((tab) => ({ ...tab, label: `${tab.title.toUpperCase()}`, value: tab.tabId }))}
            value={{ label: others?.title?.toUpperCase(), value: others?.tabId }}
            onChange={(e) => setActiveTab(e.tabId)}
          />
        </div>
      </div>
      <div
        className=" tw-border tw-rounded-lg tw-sticky md:tw-h-[695px] md:tw-overflow-y-auto no-scrollbar
      md:tw-flex-1 tw-min-w-full md:tw-min-w-0"
      >
        {Component && typeof Component === 'function' && (
          <Component isTabActive={true} setActivetab={setActiveTab} {...props} />
        )}
      </div>
    </div>
  );
};

LeftTabs.propTypes = {
  tabs: PropTypes.array,
  defaultTab: PropTypes.string,
};
