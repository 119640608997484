import * as departmentTypes from '../types/departmentTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject } from 'utils';

export const departments = (state = initialState.departments, action) => {
  switch (action.type) {
    case departmentTypes.FETCH_DEPARTMENTS_SUCCESS:
      return updateObject(state, action.payload);

    case departmentTypes.FETCH_DEPARTMENTS_FAILED:
      return updateObject(state, { error: action.error });

    case departmentTypes.UPLOAD_DEPARTMENTS_SUCCESS:
      return updateObject(state, action.payload);

    case departmentTypes.ADD_DEPARTMENT_START:
      return updateObject(state, { formErrorMessage: null, isSaving: true });

    case departmentTypes.ADD_DEPARTMENT_SUCCESS:
      return updateObject(state, { ...action.payload, formErrorMessage: null });

    case departmentTypes.ADD_DEPARTMENT_FAILED:
      return updateObject(state, {
        formErrorMessage: action.error,
        isSaving: false
      });

    case departmentTypes.UPDATE_DEPARTMENT_START:
      return updateObject(state, { formErrorMessage: null, ...action.payload });

    case departmentTypes.UPDATE_DEPARTMENT_SUCCESS:
      return updateObject(state, {
        ...action.payload,
        formErrorMessage: null,
        isSaving: false,
        isDeactivating: false
      });

    case departmentTypes.UPDATE_DEPARTMENT_FAILED:
      return updateObject(state, {
        // formErrorMessage: action.error,
        isSaving: false,
        isDeactivating: false
      });

    case departmentTypes.DELETE_DEPARTMENT_START:
      return updateObject(state, { isDeactivating: true });

    case departmentTypes.DELETE_DEPARTMENT_SUCCESS:
      return updateObject(state, { ...action.payload, isDeactivating: false });

    case departmentTypes.DELETE_DEPARTMENT_FAILED:
      return updateObject(state, { isDeactivating: false });

    case departmentTypes.DEPARTMENTS_PAGE_SORT:
      return updateObject(state, action.payload);

    case departmentTypes.UPDATE_DEPARTMENTS_LIST:
      return updateObject(state, action.payload);

    default:
      return { ...state };
  }
};

export default departments;