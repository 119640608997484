import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import classNames from 'classnames';

export const ConfirmationModal = props => {
  const { isOpen, toggle, cssClass, handleSave, body, loading } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="" centered backdropClassName="backdrop-style" className={cssClass}>
      <div className="modal__header">
        <div className="container-fluid">
          <div className="modal__header--inner d-flex align-items-center justify-content-between">
            <div className="mt-3">
              <h2>Confirmation</h2>
            </div>

            <div className="modal__header--icon">
              <Close style={{ cursor: 'pointer' }} onClick={props.toggle} color="#fa4659" strokeWidth={1} />
            </div>
          </div>
        </div>
      </div>
      <ModalBody>
        <div className="p-5 d-flex align-items-center justify-content-center text-center">{body}</div>
        <div className="form__settings-footer my-5">
          <div className="row justify-content-center">
            <div className="mx-4">
              <Button className="btn btn__utility mr-2" onClick={() => toggle()}>
                Cancel
              </Button>
              <Button
                className={classNames('btn btn__secondary', {
                  btn__loading: loading,
                })}
                onClick={handleSave}
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  handleSave: PropTypes.func,
  body: PropTypes.element,
  loading: PropTypes.bool,
  cssClass: PropTypes.string,
};
