/* eslint-disable no-case-declarations */
import * as branchesConfigTypes from '../types/branchesConfigTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject, _sortItems } from 'utils';

export const branchesConfig = (state = initialState.branchesConfig, action) => {
  switch (action.type) {
    case branchesConfigTypes.UPDATE_BRANCHES_CONFIGURATION_LIST:
      return updateObject(state, action.payload);

    case branchesConfigTypes.FETCH_BRANCHES_CONFIGURATION_SUCCESS:
      return updateObject(state, action.payload);

    case branchesConfigTypes.FETCH_BRANCHES_CONFIGURATION_FAILED:
      return updateObject(state, { error: action.error });

    case branchesConfigTypes.ADD_BRANCH_CONFIGURATION_SUCCESS:
      const cache = _sortItems([...state.items, ...action.payload], 'name');
      return updateObject(state, {
        items: cache,
        filteredItems: cache,
      });

    case branchesConfigTypes.UPDATE_BRANCH_CONFIGURATION_SUCCESS:
      const newConfigs = [...state.items].map(prev => {
        if (prev._id === action.payload.branchId) {
          return {
            ...prev,
            config: {
              ...prev.config,
              ...action.payload.config,
            },
          };
        }
        return prev;
      });
      return updateObject(state, {
        items: newConfigs,
        filteredItems: newConfigs,
      });

    default:
      return { ...state };
  }
};
