import React from 'react';
import PropTypes from 'prop-types';

export const YearMonthForm = ({ date, localeUtils, onChange, fromMonth, toMonth }) => {
  const months = localeUtils.getMonths();

  const years = [];
  for (let i = toMonth.getFullYear(); i <= fromMonth.getFullYear(); i++) {
    years.push(i);
  }

  const handleChange = (e) => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption">
      <select name="month" onChange={handleChange} value={date.getMonth()}>
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" onChange={handleChange} value={date.getFullYear()}>
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

YearMonthForm.propTypes = {
  date: PropTypes.object,
  onChange: PropTypes.func,
  localeUtils: PropTypes.object,
  toMonth: PropTypes.object,
  fromMonth: PropTypes.object,
};
