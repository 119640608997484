import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Nav, TabContent } from 'reactstrap';
import { TabLink } from './tabLink';
import { TabDetails } from './tabDetails';

export const PageTabs = ({ defaultTab, path, ...props }) => {
  const [activeTab, setActiveTab] = useState('');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    const getTab = async () => {
      try {
        const { search } = window.location;
        const { tabMatcher } = props;
        const params = new URLSearchParams(search);
        let tab = params.get('tab') || defaultTab;
        tab = typeof tabMatcher === 'object' ? tabMatcher[tab] || defaultTab : tab;
        setActiveTab(tab);
      } catch (error) {
        // TODO Display error properly
      }
    };

    getTab();
  }, [defaultTab]);

  useEffect(() => {
    if (typeof props.setCurrentTab === 'function') {
      props.setCurrentTab({ tabId: activeTab });
    }
  }, [activeTab]);

  const activeTabObj = props.tabs.reduce((acc, tab) => {
    acc[tab.tabId] = tab;
    return acc;
  }, {});

  const { component, tabId } = activeTabObj[activeTab] ?? {};

  return (
    <div className={`section ${props.extraClass ? props.extraClass : ''}`}>
      <div className={`page-tabs ${props.NavtabClass} mb-5 mb-md-0`}>
        <Nav tabs pills>
          {props.tabs.map((tab) => {
            const { tabId, title, tabIcon } = tab;
            return (
              <TabLink
                key={tabId}
                title={title}
                tabId={tabId}
                category={tabId}
                tabIcon={tabIcon}
                path={path}
                toggle={toggle}
                activeTab={activeTab}
              />
            );
          })}
        </Nav>
        {props.rightControl && <props.rightControl {...props} />}
      </div>

      <div className="tw-mt-5">
        <TabContent activeTab={activeTab}>
          {component && tabId && (
            <TabDetails key={tabId} tabId={tabId} component={component} onCurrentTab={true} {...props} />
          )}
        </TabContent>
      </div>
    </div>
  );
};

PageTabs.propTypes = {
  defaultTab: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  tabs: PropTypes.array,
  extraClass: PropTypes.string,
  tabMatcher: PropTypes.object,
  setCurrentTab: PropTypes.func,
  rightControl: PropTypes.func,
  NavtabClass: PropTypes.string,
};
