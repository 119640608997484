import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, DateInput, FormInput } from 'components';
import { Button, Form } from 'reactstrap';
import classnames from 'classnames';
import { orderStateOptions } from 'utils/constants';
import { populateUsersBranches, _formatDate } from 'utils';
import { Link } from 'react-router-dom';

export const GeneratedReport = ({ staffList, branches }) => {
  const initialValue = {
    title: '',
    branch: '',
    orderStatus: undefined,
    operator: undefined,
    fromDate: undefined,
    toDate: undefined,
  };
  const [state, setState] = useState(initialValue);
  const { branch, orderStatus, operator, fromDate, toDate, title } = state;
  const handleAttributeChange = (value, attribute) => {
    let extraField = '';
    if (attribute === 'fromDate' && toDate) {
      const to = new Date(toDate).getTime();
      const from = new Date(value).getTime();
      if (to - from < 0 || from - to > 0) {
        extraField = { toDate: undefined };
      }
    }

    setState({
      ...state,
      [attribute]: ['fromDate', 'toDate'].includes(attribute) ? _formatDate(new Date(value), 'YYYY-MM-DD') : value,
      ...extraField,
    });
  };

  const _isDisabled = () => {
    if (fromDate && toDate === undefined) return true;
    if (!branch) return true;
    if (branch || orderStatus || operator || (fromDate && toDate)) return false;
    return true;
  };
  
  return (
    <div className="card card__report">
      <div className="form-header container-fluid">
        <h3 className="form-header__title">Generate Report</h3>
        <h4 className="form-header__subtitle">Select up to two filter option to start your search</h4>
      </div>
      <div className="container-fluid">
        <Form onSubmit={e => e.preventDefault()} className="form__settings">
          <div className="row">
            <div className="col-12">
              <Select
                isMulti={false}
                isRequired
                label="Branch"
                placeholder="Select Branch"
                options={populateUsersBranches(branches)}
                onChange={e => handleAttributeChange(e, 'branch')}
                value={branch || populateUsersBranches(branches)[0]}
              />
            </div>
            <div className="col-12">
              <Select
                isMulti={false}
                label="Order Status"
                placeholder="Select Order Status"
                options={orderStateOptions}
                onChange={e => handleAttributeChange(e, 'orderStatus')}
                value={orderStatus}
              />
            </div>
            <div className="col-12">
              <Select
                isMulti={false}
                label="Operator"
                options={staffList}
                placeholder="Select Operator"
                onChange={e => handleAttributeChange(e, 'operator')}
                value={operator}
              />
            </div>
            <div className="col-md-6">
              <DateInput
                label="Date From"
                id="fromDate"
                value={fromDate}
                onDayChange={e => handleAttributeChange(e, 'fromDate')}
              />
            </div>
            <div className="col-md-6">
              {fromDate && (
                <DateInput
                  label="Date To"
                  disabledDays={{
                    before: fromDate ? new Date(fromDate) : undefined,
                  }}
                  id="toDate"
                  value={toDate}
                  datePickerProps={{ disabled: fromDate === undefined }}
                  onDayChange={e => handleAttributeChange(e, 'toDate')}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <FormInput
                id="title"
                label="Title"
                placeholder="Enter title"
                type="text"
                value={title}
                onChange={e => handleAttributeChange(e.target.value, 'title')}
              />
            </div>
          </div>

          <div className="form__settings-footer">
            <div className="row">
              <div className="form__settings-button">
                <Button
                  className={classnames('btn__secondary', {
                    btn__loading: false,
                  })}
                  download
                  disabled={_isDisabled()}
                >
                  {_isDisabled() ? (
                    `Get Report`
                  ) : (
                    <Link
                      to={{
                        pathname: `report/generated-list`,
                        state: { ...state, staffList, branches },
                      }}
                    >
                      {' '}
                      Get Report{' '}
                    </Link>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

GeneratedReport.propTypes = {
  staffList: PropTypes.array,
  branches: PropTypes.array,
};
