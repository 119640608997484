import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';

import { EmptyScreen } from 'modules/onboarding/components';

export const DoughnutChart = props => {
  const chartRef = useRef();
  const { statusBreakdown, totalTasks } = props;
  const [state, setState] = useState({ statuses: [], figures: [] });

  const data = {
    labels: statusBreakdown.map(({ status }) => status),
    datasets: [
      {
        label: 'Lab Tasks Breakdown',
        backgroundColor: ['#53C7F0', '#6A69E4', '#F5A623', '#50E3C2', '#5A4146'],
        data: statusBreakdown.map(({ count }) => count),
        fill: false,
        borderWidth: 0,
        hoverOffset: 4,
      },
    ],
  };

  useEffect(() => {
    let tempStatus = statusBreakdown.map(({ status }) => status);
    let tempCounts = statusBreakdown.map(({ count }) => count);
    setState(prev => ({ ...prev, statuses: tempStatus, figures: tempCounts }));
  }, [statusBreakdown]);
  return (
    <div className="doughnut-chart flex-column flex-md-row" style={{ minHeight: '350px' }}>
      {statusBreakdown.length > 0 ? (
        <>
          <div className="doughnut">
            <Doughnut
              ref={chartRef}
              data={data}
              options={{
                cutoutPercentage: 90,
                title: {
                  display: true,
                  fontSize: 20,
                },
                legend: {
                  display: false,
                  position: 'right',
                  labels: {
                    usePointStyle: true,
                  },
                },
              }}
            />
            <div className="counter">
              <span className="total"> Total: </span>
              <span className="number">{totalTasks}</span>
            </div>
          </div>
          <ul className="mt-8 legend">
            {chartRef.current !== undefined &&
              chartRef.current?.chartInstance?.legend.legendItems.map(item => {
                return (
                  <li
                    key={item.text}
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      margin: '30px auto',
                      justifyContent: 'space-between',
                      fontSize: '12px',
                    }}
                  >
                    <div className="color-box">
                      <div
                        style={{
                          width: '15px',
                          height: '15px',
                          backgroundColor: item.fillStyle,
                          margin: '0px 20px 0px 0px',
                          borderRadius: '31px',
                        }}
                      />
                    </div>
                    <div className="mr-5"> {item.text.toUpperCase()} </div>

                    <div> {state.figures[item.index]} </div>
                  </li>
                );
              })}
          </ul>
        </>
      ) : (
        <div className="settings__empty">
          <EmptyScreen title="No Orders In The System" />
        </div>
      )}
    </div>
  );
};

DoughnutChart.propTypes = {
  allTasks: PropTypes.array,
  filterValue_branch: PropTypes.object,
  filterValue_period: PropTypes.object,
  statusBreakdown: PropTypes.array,
  totalTasks: PropTypes.number,
};
