import React, { useState, useRef } from 'react';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import PropTypes from 'prop-types';

export const ContextMenu = props => {
  const ref = useRef();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(prevState => !prevState);

  const { triggerElement: Component, outerRef, component, ulClass, ulStyle, children, parentClass } = props;
  useOnClickOutside([ref, outerRef], () => setMenuOpen(false));

  return (
    <div className={`context-menu ${parentClass}`} ref={ref}>
      {Component ? (
        <span onClick={() => toggleMenu()}>
          <Component />
        </span>
      ) : (
        <div className="context-menu-icon" onClick={() => toggleMenu()}>
          {component ? component : <i className="iconmoon icon-option" />}
        </div>
      )}

      {isMenuOpen && (
        <ul className={`context-menu-body ${ulClass}`} style={ulStyle}>
          {children}
        </ul>
      )}
    </div>
  );
};

ContextMenu.propTypes = {
  children: PropTypes.node,
  component: PropTypes.object,
  outerRef: PropTypes.object,
  ulClass: PropTypes.string,
  ulStyle: PropTypes.object,
  parentClass: PropTypes.string,
  triggerElement: PropTypes.node,
};

ContextMenu.defaultProps = {
  ulClass: PropTypes.string,
  outerRef: { current: null },
};
