import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import { Toggle } from '../index';
import { Loader } from 'components';

export const SettingsModal = props => {
  const { hasComponent: Component } = props;
  const exited = () => (props?.onExit ? props?.onExit() : null);
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      size={props.size}
      centered={true}
      backdropClassName="backdrop-style"
      className={props.cssClass}
      style={props.style}
      onClosed={props.onClosed}
      scrollable={props.scrollable}
    >
      <div ref={props.Ref}>
        <div className="modal__header">
          <div className="container-fluid">
            <div className="modal__header--inner">
              <div className="modal__header--text" style={props.positionHeader && { marginLeft: props.positionHeader }}>
                <h3 className={classnames(`modal__header__title ${props.icon && 'color-primary'}`, props.titleClass)}>
                  {props.icon && <span className={classnames(props.icon, 'mr-3')} />}
                  {props.title}
                </h3>

                {props.subtitle && <h4 className="modal__header__subtitle">{props.subtitle}</h4>}
              </div>

              <div className="modal__header--icon">
                {props.hasSwitch ? (
                  <div className="modal__header--toggle">
                    <Toggle onSwitch={props.onSwitch} id="clientSwitch" hasLeftText hasRightText />
                  </div>
                ) : null}

                {Component ? <Component /> : null}

                {props.hasEdit ? (
                  <span className="mr-3">
                    <i className="far fa-edit" onClick={props.onEditClick} />
                  </span>
                ) : null}

                <Close
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    exited();
                    props.toggle();
                  }}
                  color="#fa4659"
                  strokeWidth={1}
                />
              </div>
            </div>
          </div>
        </div>

        <ModalBody className="modal__body position-relative">
          <>
            {props.contentLoader && <Loader extraClass="loader-modal" />}
            {props.component ? <props.component {...props} /> : props.children}
          </>
        </ModalBody>
      </div>
    </Modal>
  );
};

SettingsModal.propTypes = {
  children: PropTypes.node,
  hasSwitch: PropTypes.bool,
  hasEdit: PropTypes.bool,
  onEditClick: PropTypes.func,
  hasComponent: PropTypes.func,
  onClosed: PropTypes.func,
  isOpen: PropTypes.bool,
  onSwitch: PropTypes.func,
  subtitle: PropTypes.string,
  size: PropTypes.string,
  title: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  toggle: PropTypes.func,
  cssClass: PropTypes.string,
  contentLoader: PropTypes.bool,
  Ref: PropTypes.object,
  icon: PropTypes.string,
  titleClass: PropTypes.string,
  positionHeader: PropTypes.bool,
  scrollable: PropTypes.bool,
  style: PropTypes.object,
  onExit: PropTypes.func,
};

SettingsModal.defaultProps = {
  hasSwitch: false,
  size: 'md',
};
