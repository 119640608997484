import initialState from 'redux/reducers/initialState';
import { updateObject } from 'utils';
import * as licenseTypes from '../types/licenseTypes';

export const license = (state = initialState.license, action) => {
    switch (action.type) {
        case licenseTypes.FETCH_LICENSE_SUCCESS:
            return updateObject(state, { license: action.payload, filteredItems: action.payload, isLoading: false });

        case licenseTypes.FETCH_LICENSE_FAILED:
            return updateObject(state, { error: action.error, isLoading: false })

        case licenseTypes.FETCH_LICENSE_START:
            return updateObject(state, { isLoading : true });

        case licenseTypes.UPDATE_LICENSE_LIST:
            return updateObject(state, { filteredItems: action.payload })
        default:
            return { ...state };
    }
}
