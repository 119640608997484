import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import logo from 'assets/icon/indigo-logo.png';
import { settingsNavOptions } from 'config/nav-options';
import Switcher from './Switcher';
import MoreLinks from './MoreLinks';
import UserLinks from './UserLinks';
import { Notifications } from 'components/modal/notifications';
import DashboardLinks from './DashboardLinks';
import { useEnvironment } from 'hooks/useEnvironment';
import SoftwareUpdate from '../softwareUpdate';
import {
  deleteNotifications,
  fetchNotifications,
  markAllNotifications,
  markNotification,
} from 'redux/actions/notifications';
import { _notifyError } from 'utils';
import { fetchLocation } from 'redux/actions';

export const MainSideBar = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, notifications } = useSelector((state) => state);
  const locations = useSelector((state) => state.user?.current?.locations) || [];
  const isParent = useSelector((state) => state.user?.current?.parent) || false;
  const parentId = useSelector((state) => state.user?.current?.parentOrganizationId) || '';

  const [showNotifications, setShowNotifications] = useState(false);
  const { panel, sidebarOptions, isSetting } = props;
  const { moreLinks, dashboardLinks } = settingsNavOptions();
  const [isProduction] = useEnvironment('production');
  const [readNotifications, setReadNotifications] = useState([]);
  const [state, setState] = useState({
    showUpdate: false,
    message: '',
  });

  const { showUpdate, message } = state;

  useEffect(() => {
    if (!showNotifications) return;
    dispatch(fetchNotifications());
  }, [dispatch, showNotifications]);
  const readNotification = (id) => dispatch(markNotification(id));

  const allReadNotification = (ids) => dispatch(markAllNotifications(ids));
  const deleteAllNotifications = (ids) => dispatch(deleteNotifications(ids));

  const toggleUpdate = (message) => {
    setState({ showUpdate: !showUpdate, message });
  };

  useEffect(() => {
    setReadNotifications(notifications?.items?.filter((item) => item?.read === false));
  }, [notifications?.items]);

  const changeCurrentBranch = async (url, nextBranch) => {
    if (!isParent) {
      await dispatch(fetchLocation(nextBranch.value));
    }
    history.push(`${url}`);
  };

  const goToUrl = async (url, branchId) => {
    const newLocation = locations.find((location) => location._id === branchId) || {};
    let nextBranch = { ...newLocation };

    if (!newLocation._id) {
      _notifyError('You have no access to this module');
    } else {
      nextBranch = { ...nextBranch, value: nextBranch._id, label: nextBranch.name };
      await changeCurrentBranch(url, nextBranch);
    }
  };

  return (
    <>
      <SoftwareUpdate
        message={message}
        isOpen={showUpdate}
        toggle={() => setState({ showUpdate: !showUpdate, message: '' })}
      />
      <div className="navigation-sidebar">
        <div
          className={classnames({
            'navigation-sidebar__logo': true,
            'navigation-sidebar__logo-center': panel === true,
          })}
        >
          <img src={logo} alt="Indigo EMR" />
        </div>

        <Switcher panel={panel} />

        {isSetting && (
          <nav className="navigation-sidebar__nav">
            <DashboardLinks option={dashboardLinks} panel={panel} tourOverride={props.tourOverride} />
          </nav>
        )}

        <nav className="navigation-sidebar__nav">
          {sidebarOptions &&
            sidebarOptions.map((option) => {
              return option.morelink || option.dashboardLinks ? null : (
                <NavLink
                  key={option.text}
                  to={option.to}
                  className="nav-link d-flex justify-content-start align-items-center"
                  title={option.text}
                  onClick={(event) =>
                    props.tourOverride !== undefined
                      ? event.preventDefault()
                      : typeof props.mobileClickAction === 'function' && props.mobileClickAction()
                  }
                >
                  <i className={`iconmoon icon-${option.icon}`}></i>
                  <span
                    className={classnames({
                      'nav-link-text': true,
                      'nav-link-display': panel === false,
                      'ml-1': panel === false,
                    })}
                  >
                    {option.text}
                  </span>
                </NavLink>
              );
            })}
        </nav>
        {isSetting && (
          <nav className="navigation-sidebar__nav">
            <MoreLinks option={moreLinks} panel={panel} tourOverride={props.tourOverride} />
          </nav>
        )}
      </div>

      <div className="navigation-otheritems">
        <ul className="navigation-otheritems__items mt-auto">
          <li className="notification cursor-pointer" onClick={() => setShowNotifications(!showNotifications)}>
            <div className="notification-item d-flex justify-content-start align-items-center">
              <i className="iconmoon icon-notification">
                <span className="path1" />
                <span className="path2" />
                {!isProduction ? (
                  readNotifications?.length > 0 && <span className="path3" />
                ) : (
                  <span className="path3" />
                )}
              </i>
              <span
                className={classnames({
                  'notification-item-text': true,
                  'notification-item-display': panel === false,
                  'ml-1': panel === false,
                })}
              >
                Notification
              </span>
            </div>
          </li>
          <li className="notification cursor-pointer">
            <div onClick={() => goToUrl('/settings/schedules', parentId)} style={{ textDecoration: 'none' }}>
              <div className="notification-item d-flex justify-content-start align-items-center">
                <i className="iconmoon icon-credit-card">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </i>

                <span
                  className={classnames('notification-item notification-item-text', {
                    'notification-item-display': panel === false,
                  })}
                >
                  Doctor Schedule
                </span>
              </div>
            </div>
          </li>
          <li className="notification cursor-pointer">
            <Link to={'/settings/subscription'} style={{ textDecoration: 'none' }}>
              <div className="notification-item d-flex justify-content-start align-items-center">
                <i className="iconmoon icon-credit-card">
                  <span className="path1" />
                  <span className="path2" />
                  <span className="path3" />
                </i>

                <span
                  className={classnames('notification-item notification-item-text', {
                    'notification-item-display': panel === false,
                  })}
                >
                  Subscription & payment
                </span>
              </div>
            </Link>
          </li>
          <li className="notification tw-bg-[#403F89] tw-py-4  tw-rounded-3xl tw-border tw-border-[#E6E7EC] cursor-pointer">
            <Link to={'/support'} target="_blank" style={{ textDecoration: 'none' }}>
              <div className="notification-item d-flex justify-content-start align-items-center">
                <i className="iconmoon icon-help" />
                <div
                  className={classnames('notification-item notification-item-text', {
                    'notification-item-display': panel === false,
                  })}
                >
                  <span>Help Center</span>
                  <p className="tw-text-[#E1E7EC] tw-text-lg">Answers Here</p>
                </div>
              </div>
            </Link>
          </li>
          <div className="tw-w-full tw-h-0.5 tw-bg-[#A6A5EF] tw-my-6" />
          <UserLinks user={user} panel={panel} />
        </ul>
      </div>
      {showNotifications && !isProduction ? (
        <Notifications
          isOpen={showNotifications}
          toggle={() => setShowNotifications(!showNotifications)}
          notifications={notifications}
          readNotification={readNotification}
          allReadNotification={allReadNotification}
          deleteAllNotifications={deleteAllNotifications}
          toggleUpdate={toggleUpdate}
        />
      ) : (
        <Notifications
          isOpen={showNotifications}
          toggle={() => setShowNotifications(!showNotifications)}
          notifications={notifications}
          readNotification={readNotification}
          toggleUpdate={toggleUpdate}
        />
      )}
    </>
  );
};

MainSideBar.propTypes = {
  sidebarOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  panel: PropTypes.bool.isRequired,
  avatar: PropTypes.string,
  isSetting: PropTypes.bool.isRequired,
  mobileClickAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  tourOverride: PropTypes.bool,
  readNotification: PropTypes.func,
  showNotifications: PropTypes.bool,
  setShowNotifications: PropTypes.func,
  allReadNotification: PropTypes.func,
  deleteAllNotifications: PropTypes.func,
};
