import React from 'react';
import { Button, Spinner } from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { userLogout } from 'redux/actions';
import { useDispatch, useSelector } from 'react-redux';

export const PageControls = ({ ...props }) => {
  const { backClick, skip, skipClick, disabled, next, isSaving, nextClick, nextClickButtonText } = props;
  const { user } = useSelector(state => state);
  const dispatch = useDispatch();
  const logOut = async () => {
    await dispatch(userLogout());
  };
  return (
    <div className="page-controls justify-content-center">
      {backClick && (
        <div className="page-controls__cta ml-0">
          <Button onClick={backClick} className={classnames('btn__utility')}>
            Back
          </Button>
        </div>
      )}
      {skip && (
        <div className="page-controls__cta">
          <Button className={classnames('btn__primary')} onClick={skipClick}>
            Skip
          </Button>
        </div>
      )}
      {next && (
        <div className="page-controls__cta mr-4">
          <Button
            disabled={disabled}
            className={classnames('btn__secondary', {
              btn__loading: isSaving,
            })}
            onClick={nextClick}
          >
            {nextClickButtonText || 'Continue'}
          </Button>
        </div>
      )}
      <Button
        style={{ position: 'relative', float: 'left' }}
        className="btn btn__secondary onboarding_logout_btn"
        onClick={() => logOut()}
      >
        Logout {user?.isLoggingOut && <Spinner />}
      </Button>
    </div>
  );
};

PageControls.defaultProps = {
  skip: false,
  next: true,
};

PageControls.propTypes = {
  skip: PropTypes.bool,
  skipClick: PropTypes.func,
  isSaving: PropTypes.bool,
  next: PropTypes.bool,
  nextClick: PropTypes.func,
  backClick: PropTypes.func,
  nextClickButtonText: PropTypes.string,
  disabled: PropTypes.bool,
};
