import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from 'assets/svg/close.svg';
import { Form, Modal } from 'reactstrap';
import classnames from 'classnames';
import { Loader } from 'components';
import { Select } from 'components/common';
import { fancyDate } from 'utils';
import { EmptyScreen } from 'components/empty-screen';
import moment from 'moment';
import { useEnvironment } from 'hooks/useEnvironment';

export const Notifications = props => {
  const filterOptions = [
    { label: 'All', value: 'all' },
    { label: 'Read', value: 'read' },
    { label: 'Unread', value: 'unread' },
  ];
  const [filterOption, setFilterOption] = useState({});
  const [notifItems, setnotifItems] = useState([]);
  const [isProduction] = useEnvironment('production');

  const displayNotifications = value => {
    switch (value) {
      case 'all':
        return setnotifItems(props.notifications?.items);
      case 'read':
        return setnotifItems(props.notifications?.items?.filter(item => item.read === true));
      case 'unread':
        return setnotifItems(props.notifications?.items?.filter(item => item.read === false));
      default:
        return setnotifItems(props.notifications?.items);
    }
  };

  const onClick = item => {
    setTimeout(() => {
      props.toggle();
      props.readNotification(item._id);
    }, 500);
  };

  const getNotificationIds = () => {
    return props.notifications?.items?.map(notify => {
      return notify?._id;
    });
  };

  useEffect(() => {
    setnotifItems(props.notifications?.items);
    displayNotifications(filterOption?.value);
  }, [props.notifications?.items, filterOption?.value]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <div className="modal__header">
        <div className="container-fluid">
          <div className="modal__header--inner">
            <div className="modal__header--text">
              <h3 className="modal__header__title">Notifications</h3>
              <h4 className="modal__header__subtitle">Total items: {props.notifications?.items?.length}</h4>
            </div>

            <div className="modal__header--icon">
              <Close style={{ cursor: 'pointer' }} onClick={props.toggle} color="#fa4659" strokeWidth={1} />
            </div>
          </div>
        </div>
      </div>

      <div className={classnames('modal__body', 'notifications')}>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="modal__list">
                <div className="d-flex justify-content-between align-items-center">
                  <Form className="settings__form w-25">
                    <div className="row">
                      <div className="col-sm-12">
                        <Select
                          placeholder="Filter"
                          value={filterOption}
                          onChange={e => setFilterOption(e)}
                          isDisabled={!props.notifications?.items?.length}
                          options={filterOptions}
                        />
                      </div>
                    </div>
                  </Form>
                  {!isProduction ? (
                    <>
                      <span
                        className="notification__mark-action"
                        onClick={() => {
                          props.allReadNotification(getNotificationIds());
                        }}
                      >
                        Mark all as read
                      </span>
                      <span
                        className="notification__mark-action"
                        onClick={() => {
                          props.deleteAllNotifications(getNotificationIds());
                        }}
                      >
                        Delete all
                      </span>
                    </>
                  ) : (
                    <span className="notification__mark-action">Mark all as read</span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="notification__content">
            {props.notifications ? (
              props.notifications?.isLoading ? (
                <Loader extraClass={'h-25'} />
              ) : notifItems?.length ? (
                notifItems?.map(notif => {
                  return (
                    <div key={notif?._id} className="notification__card mb-5" onClick={() => onClick(notif)}>
                      {!notif?.read && <div className="notification__card-indicator"></div>}
                      <p className="mt-2">
                        {notif.title === 'New Task' ? (
                          notif.body
                        ) : notif.title === 'Software Update' ? (
                          <div className="d-flex justify-content-between align-items-center">
                            <h3>{notif?.title}</h3>
                            <button
                              type="button"
                              className="btn btn__primary"
                              onClick={() => props.toggleUpdate(notif?.body)}
                            >
                              View
                            </button>
                          </div>
                        ) : (
                          notif?.body
                        )}{' '}
                      </p>
                      <p className="notification__card-time">
                        {notif?.createdAt > new Date()
                          ? fancyDate(notif?.createdAt)
                          : moment(notif?.createdAt).fromNow()}
                      </p>
                    </div>
                  );
                })
              ) : (
                <EmptyScreen title="No new notifications" />
              )
            ) : null}{' '}
          </div>
        </div>
      </div>
      <div className="modal__footer">
        <p className="font-weight-bolder"> ALL notifications will be cleared out after 15 days</p>
      </div>
    </Modal>
  );
};

Notifications.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  notifications: PropTypes.object,
  readNotification: PropTypes.func,
  allReadNotification: PropTypes.func,
  deleteAllNotifications: PropTypes.func,
  toggleUpdate: PropTypes.func,
};
