import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { ReactComponent as SetupSvg } from 'assets/svg/dashboard-setup.svg';
import { ReactComponent as TourSvg } from 'assets/svg/dashboardtour.svg';

export const OnboardingCard = ({ ...props }) => {
  const { buttonText, svg, title, subtitle, onClick, section } = props;
  const history = useHistory();

  return (
    <div className="card card__onboarding flex-column flex-md-row">
      <div className="avatar">{svg === 'setup' ? <SetupSvg /> : <TourSvg />}</div>
      <div className="content">
        <h2 className="title">{title}</h2>
        <p className="subtitle">{subtitle}</p>
      </div>
      <div className="button">
        {['start', 'continue'].includes(buttonText) ? (
          <Button
            className={classnames('btn__utility btn__onboarding btn_responsive')}
            onClick={() => {
              history.push(`/onboarding/configuration?tab=${section}`);
            }}
          >
            {buttonText}
          </Button>
        ) : (
          // <NavLink
          //   className="btn btn__utility"
          //   exact
          //   to={}
          // >
          //   {buttonText}
          // </NavLink>
          <Button className={classnames('btn__utility btn__onboarding btn_responsive')} onClick={onClick}>
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

OnboardingCard.defaultProps = {
  title: 'Set up Indigo',
  subtitle: 'Your application hasnt been set up yet',
  buttonText: 'start',
  section: '',
};

OnboardingCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func,
  svg: PropTypes.string,
  section: PropTypes.string,
};
