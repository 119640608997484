import React, { lazy, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBranches, setCurrency } from 'redux/actions';

import MainLayout from '../../../layouts/MainLayout';
import AuthRoute from '../../../routes/AuthRoute';

const Stock = lazy(() => import('../pages/Stock'));
const Services = lazy(() => import('../pages/Services'));
const HMO = lazy(() => import('../pages/HMO'));
const InventorySettings = lazy(() => import('../pages/InventorySettings'));
const ErrorPage = lazy(() => import('../../../pages/Error'));

const InventoryRoutes = ({ match }) => {
  const dispatch = useDispatch();

  const { loggedIn } = useSelector(state => state.auth);
  const branchId = useSelector(store => store.user?.current?.branchId);
  const currency = useSelector(store => store.branches?.items?.find(e => e._id === branchId)?.currency?.code);
  // TODO check accessGranted value using the modules for the current branch

  useEffect(() => {
    dispatch(fetchBranches());
  }, []);
  useEffect(() => {
    if (!currency) return;
    dispatch(setCurrency(currency));
  }, [currency]);

  return (
    <>
      <Switch>
        <Redirect exact from={`${match.path}`} to={`${match.path}/stock`} />
        {/* <AuthRoute
          exact
          path={`${match.path}`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Stock}
          accessGranted={true}
        /> */}

        <AuthRoute
          path={`${match.path}/stock`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Stock}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/services`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Services}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/hmo`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={HMO}
          accessGranted={true}
        />

        <AuthRoute
          path={`${match.path}/settings`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={InventorySettings}
          accessGranted={true}
        />

        <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
      </Switch>
    </>
  );
};

InventoryRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default InventoryRoutes;
