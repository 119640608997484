import { useEffect, useState } from 'react';

export const useEnvironment = (environment = 'production') => {
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (window.location?.host?.includes('staging')) return;
    // eslint-disable-next-line no-undef
    if (process.env.NODE_ENV === environment) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  }, []);

  return [status];
};
