import React from 'react';
import PropTypes from 'prop-types';

export const Toggle = ({ hasLeftText, leftText, hasRightText, rightText, id, checked, onSwitch, style }) => (
  <div className="toggle" style={style}>
    {hasLeftText && <p className="toggle__option toggle__option--left">{leftText || 'Patient'}</p>}
    <div className="toggler">
      <label htmlFor={id}>
        <input type="checkbox" className="clientSwitch" id={id} checked={checked} onChange={onSwitch} />
        <span className="switch" />
        <span className="switch__button"> </span>
      </label>
    </div>
    {hasRightText && <p className="toggle__option toggle__option--right">{rightText || 'Company'}</p>}
  </div>
);

Toggle.propTypes = {
  onSwitch: PropTypes.func,
  hasLeftText: PropTypes.bool,
  leftText: PropTypes.string,
  hasRightText: PropTypes.bool,
  checked: PropTypes.bool,
  rightText: PropTypes.string,
  //the toggle wnt work without id
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
};

export const MainToggler = ({ id, text, textColor, checked, onSwitch, isDisabled, positionClass, removeMargin }) => (
  <div className={`toggle ${positionClass}`} style={{ marginLeft: `${removeMargin ? 0 : '57%'}` }}>
    <div className={`toggler ${positionClass && 'ml-3'}`}>
      <label htmlFor={id}>
        <input
          type="checkbox"
          id={id}
          className="clientSwitch"
          name={id}
          checked={checked}
          onChange={onSwitch}
          onClick={e => e.stopPropagation()}
          disabled={isDisabled}
        />
        <span className="switch is-checked" />
        <span className="switch__button"> </span>
      </label>
    </div>
    <p className="toggle__option toggle__option--right" style={{ color: textColor || '#F5A623' }}>
      {text || 'Company'}
    </p>
  </div>
);

MainToggler.defaultProps = {
  checked: false,
};

MainToggler.propTypes = {
  onSwitch: PropTypes.func,
  text: PropTypes.string,
  id: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  positionClass: PropTypes.string,
  checked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  removeMargin: PropTypes.bool,
};
