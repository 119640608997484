import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';
import moment from 'moment';

export const ViewCommentModal = (props) => (
  <Modal
    centered
    contentClassName={`modal--confirm ${props.color} `}
    isOpen={props.isOpen}
    nocloseicon="true"
    size="md"
    toggle={props.toggle}
  >
    <div className="modal--confirm__body">
      <h3 className="modal__title with-subtitle">Comments</h3>
      {props.comments && props.comments.length ? (
        <div className="form-control--user-comments">
          <div className="comment">
            {props.comments.map((comment, index) => (
              <div className="comment-body" key={index}>
                <p className="comment-body__header">
                  {`${comment.author ? comment.author.name : 'User'}`}{' '}
                  <span className="float-right date">{moment(new Date(comment.date)).fromNow()}</span>
                </p>
                <p className="comment-body__footer">{comment.body || comment.comment}</p>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <p>No comments made about this task</p>
      )}
    </div>

    <div className="modal--confirm__footer">
      <button className="btn modal--confirm__btn" onClick={props.closeClick}>
        Close
      </button>
    </div>
  </Modal>
);

ViewCommentModal.propTypes = {
  isOpen: PropTypes.bool,
  closeClick: PropTypes.func,
  toggle: PropTypes.func,
  comments: PropTypes.array,
  color: PropTypes.string,
};
