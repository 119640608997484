import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

export const TableHeader = props => {
  return (
    <div className="header">
      <h2 className="header__title">{props.title}</h2>
      <div className="header__button">
        <Button className="btn btn__secondary" block>
          Save
        </Button>
      </div>
    </div>
  );
};

TableHeader.propTypes = {
  title: PropTypes.string,
};
