import React from 'react';

const Required = () => {
  return (
    <span className="tw-text-[1.2rem]" style={{ color: 'red' }}>
      *
    </span>
  );
};

export default Required;
