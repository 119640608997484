/* eslint-disable linebreak-style */
import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import 'rc-time-picker/assets/index.css';

const selectStyle = {
  control: base => ({
    ...base,
    border: '1px solid rgba(151, 151, 151, 0.1)',
    borderRadius: 8,
  }),
};

export const SelectWithPromise = props => {
  const { label, className, isDisabled, isRequired, ...rest } = props;
  return (
    <FormGroup
      className={`settings__form-group ${className} ${isDisabled && 'disabled'}`}
      onClick={e => e.stopPropagation()}
    >
      <div className="d-flex">
        {label && (
          <Label className="form__label" for="name">
            {label}
          </Label>
        )}
        <span style={{ color: 'red' }}>{isRequired ? '*' : ''}</span>
      </div>
      <AsyncSelect
        className="modal-select form__select"
        classNamePrefix="modal-select"
        isMulti={props.isMulti}
        isDisabled={isDisabled}
        placeholder={props.placeholder}
        styles={selectStyle}
        onChange={props.onChange}
        value={props.value}
        cacheOptions={props.cacheOptions}
        defaultOptions={props.defaultOptions}
        loadOptions={props.loadOptions}
        menuIsOpen={props.menuIsOpen}
        onMenuClose={props.onMenuClose}
        onMenuOpen={props.onMenuOpen}
        isOptionSelected={props.isOptionSelected}
        hideSelectedOptions={props.hideSelectedOptions}
        closeMenuOnSelect={props.closeMenuOnSelect}
        {...rest}
      />
    </FormGroup>
  );
};

SelectWithPromise.defaultProps = {
  isMulti: false,
  options: [],
  placeholder: '',
  value: null,
};

SelectWithPromise.propTypes = {
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  loadOptions: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isSearchable: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  isOptionSelected: PropTypes.func,
  hideSelectedOptions: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
};
