/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedSupplier } from 'redux/actions';
import PropTypes from 'prop-types';
import Check from 'services/Check';
import classnames from 'classnames';
import BlackListSelectedSupplier from '../../../../settings/pages/Inventory/Supplier/BlackListSelectedSupplier';

export const ViewSupplier = ({ entity }) => {
  const [trigger, setTrigger] = useState(0);
  const { suppliers } = useSelector(state => state);
  const dispatch = useDispatch();
  const currentItem = suppliers.items.find(supplier => supplier._id === entity._id);
  const supplier = currentItem || entity;

  const blackListTrigger = () => {
    setTrigger(prevState => prevState + 1);
  };

  const whiteListSupplier = selected => {
    const payload = {
      _id: selected._id,
      isBlacklisted: false,
      isActive: true,
      reasonForBlacklist: '',
    };

    const updatedItem = { ...selected, ...payload };

    // eslint-disable-next-line no-empty-function
    dispatch(updateSelectedSupplier(payload, updatedItem, () => {}, 'whitelisted'));
  };

  const linkAction = () => {
    if (supplier.isBlacklisted) {
      return whiteListSupplier(supplier);
    }
    return blackListTrigger();
  };

  return (
    <>
      <BlackListSelectedSupplier selected={supplier} trigger={trigger} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <ViewFormater field="Supplier" value={supplier.name} />
          </div>

          <div className="col-12">
            <ViewFormater field="Phone Number" value={supplier.phoneNumber} />
          </div>

          <div className="col-12">
            <ViewFormater field="Email Address" value={supplier.email} />
          </div>

          {entity.isBlacklisted && (
            <div className="col-12">
              <ViewFormater field="Reason" value={supplier.reasonForBlacklist} />
            </div>
          )}

          <div className="col-12">
            <ViewFormater field="Operator" value={supplier?.operator?.name || 'Not Available'} />
          </div>

          <div className="col-12">
            <ViewFormater field="Item Supplied" value={'Not Available'} />
          </div>

          <Check permission={['edit-supplier', 'super-edit']}>
            <div className="col-12">
              <p
                className={classnames('supplier-view-link', supplier.isBlacklisted ? 'blue' : 'red')}
                onClick={() => linkAction()}
              >
                {supplier.isBlacklisted ? 'White' : 'Black'}list Supplier
              </p>
            </div>
          </Check>
        </div>
      </div>
    </>
  );
};

ViewSupplier.propTypes = {
  entity: PropTypes.object,
  toggle: PropTypes.func,
};

const ViewFormater = ({ field, value }) => (
  <div className="supplier-view-formatter">
    <p>{field}</p>
    <p>{value}</p>
  </div>
);

ViewFormater.propTypes = {
  field: PropTypes.string,
  value: PropTypes.string,
};
