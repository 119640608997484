import { combineReducers } from 'redux';
import auth from './authReducer';
import company from './companyReducer';
import user from './userReducer';
import notifications from './notificationReducer';
import { currencies, currency, permissions } from './utilsReducer';
import inProgress from './apiReducer';
import sponsors from './sponsorReducer';
import branches from './branchesReducer';
import categories from './categoryReducer';
import staff from './staffReducer';
import suppliers from './supplierReducer';
import departments from './departmentsReducer';
import patientsDepartments from './patientsDepartments';
import appOptions from './appOptionReducer';
import email from './emailReducer';
import { taskManagement } from '../../modules/laboratory/redux/reducers';
import * as settingsReducers from '../../modules/settings/redux/reducers';
import * as inventoryReducers from '../../modules/inventory/redux/reducers';
import * as frontdeskReducers from '../../modules/front-desk/redux/reducers';
import * as patientsReducers from 'modules/patients/redux/reducers';
import * as pharmacyReducers from 'modules/pharmacy/redux/reducers';
import * as financeReducers from 'modules/finance/redux/reducers';
import * as auditTrailReducers from 'modules/audit-trail/redux/reducers';

const allReducers = combineReducers({
  auth,
  user,
  notifications,
  currencies,
  currency,
  permissions,
  inProgress,
  branches,
  categories,
  departments,
  patientsDepartments,
  taskManagement,
  sponsors,
  staff,
  suppliers,
  company,
  email,
  appOptions,
  ...patientsReducers,
  ...settingsReducers,
  ...inventoryReducers,
  ...frontdeskReducers,
  ...pharmacyReducers,
  ...financeReducers,
  ...auditTrailReducers,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_APP') {
    state = undefined;
  }
  return allReducers(state, action);
};

export default (state, action) => {
  return rootReducer(action.type === 'LOGOUT_SUCCESS' ? { auth: { loggedIn: false } } : { ...state, ...auth }, action);
};
