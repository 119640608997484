/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { _notifyCustom } from 'utils';

const NotificationCard = ({ data, close, rightControl, title, event, callback }) => {
  const getTitle = message => {
    if (!message) return;
    const doc = new DOMParser().parseFromString(data, 'text/html');
    const paragraphElement = doc.querySelector('p');
    let header;

    if (paragraphElement !== null) {
      header = paragraphElement.textContent;
    }
    return event === 'software-update' ? header : message;
  };

  return (
    <>
      <div className={`r notification-card-2 notification-card-2-active`}>
        <div className="notification-card-2-message">
          <h3 className="mb-4 notification-card-2-message-title">{title}</h3>
          <small className="notification-card-2-message__tip small">{moment().fromNow()}</small>
          <p className="mb-0 notification-card-2-message-text">{getTitle(data)}</p>
        </div>
        {rightControl && (
          <div className="notification-card-2-control">
            {event === 'software-update' ? (
              <span className="px-4 pt-2 cursor-pointer" onClick={callback}>
                Open
              </span>
            ) : null}
            <hr className="m-0" />
            <span className="px-4 pt-2 cursor-pointer" onClick={close}>
              Close
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export const _notifySocket = ({ duration, ...props }) => {
  _notifyCustom({ duration, component: NotificationCard, ...props });
};

export default NotificationCard;

NotificationCard.propTypes = {
  data: PropTypes.object,
  rightControl: PropTypes.bool,
  messages: PropTypes.array,
  duration: PropTypes.number,
  close: PropTypes.func,
  handle: PropTypes.bool,
  getAcceptedVitals: PropTypes.func,
  title: PropTypes.string,
  event: PropTypes.string,
  callback: PropTypes.func,
};
