/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { FormGroup, Label } from 'reactstrap';
import ReactSelect, { components } from 'react-select';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { YearMonthForm } from './yearForm';
import AsyncSelect from 'react-select/async';
import classnames from 'classnames';

const selectStyle = {
  control: (base, state) => ({
    ...base,
    border: '4px solid rgba(151, 151, 151, 0.1)',
    borderRadius: 8,
    borderColor: state.isFocused ? 'grey' : 'red',
  }),
  groupHeading: (base) => ({
    ...base,
    color: '#6a69e4',
    textTransform: 'capitalize',
    fontSize: '1.8rem',
  }),
};

const dayPickerStyle = {
  border: 'transparent',
  margin: '4px 0',
  width: '100%',
  outline: 'none',
};

export const CustomLabels = ({ children, ...props }) => (
  <components.Option {...props}>
    <input type="checkbox" onChange={() => null} />
    &nbsp;{children}
  </components.Option>
);
CustomLabels.propTypes = { children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]) };

export const Select = (props) => {
  const { label, className, isRequired, style, ...rest } = props;
  return (
    <FormGroup
      className={`settings__form-group ${className} ${props.isDisabled && 'disabled'}`}
      style={style}
      onClick={(e) => e.stopPropagation()}
    >
      <div className="d-flex">
        {label && (
          <Label className="form__label" for={props.id}>
            {label}
          </Label>
        )}
        <span style={{ color: 'red' }}>{isRequired ? '*' : ''}</span>
      </div>
      {props.isAsyncType ? (
        <AsyncSelect
          className="modal-select form__select"
          classNamePrefix="modal-select"
          styles={selectStyle}
          {...rest}
        />
      ) : (
        <ReactSelect
          components={props.isGrouped ? { Option: CustomLabels } : null}
          className="modal-select form__select"
          classNamePrefix="modal-select"
          styles={selectStyle}
          allowSelectAll={true}
          {...rest}
        />
      )}
    </FormGroup>
  );
};

export const DateInput = (props) => {
  const fromMonth =
    props.fromMonth || new Date(10 + new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  const toMonth = props.toMonth || new Date(`${new Date().getFullYear()}`);

  const [month, setMonth] = useState(new Date());

  const handleYearMonthChange = (month) => {
    setMonth(month);
  };

  return (
    <div
      className={classnames('settings__form-group form-group', props.className)}
      style={{ position: 'relative', marginBottom: props.noBottomMargin ? 0 : null }}
    >
      <label htmlFor={props.id} className={classnames('form__label', props?.labelClass)}>
        {props.label} <span style={{ color: 'red' }}>{props.isRequired ? '*' : ''}</span>
      </label>
      <div className="tw-flex tw-justify-between tw-items-center">
        <DayPickerInput
          value={props.value || undefined}
          onDayChange={props.onDayChange}
          dayPickerProps={{
            selectedDays: props.value,
            disabledDays: props.disabledDays,
            month: month,
            modifiers: props.modifiers,
            fromMonth: fromMonth,
            toMonth: toMonth,
            captionElement: <YearMonthForm onChange={handleYearMonthChange} fromMonth={fromMonth} toMonth={toMonth} />,
          }}
          fromYear={props.fromYear}
          keepFocus={false}
          id={props.id}
          formatDate={formatDate}
          selected={props.value || undefined}
          placeholder={props.placeholder}
          format={props.dateFormat}
          parseDate={parseDate}
          inputProps={{ readOnly: true, style: dayPickerStyle, value: props.value, disabled: props.isDisabled }}
        />
        {props.isAppointment ? (
          <div className="date-input date-input__calendar">
            <span>
              <i className="iconmoon icon-arrow-down" />
            </span>
          </div>
        ) : (
          <div className={props.className ? props.className : 'date-input'}>
            <span>
              <i className="fas fa-calendar-alt" />
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export const TimeInput = (props) => (
  <div className={!props?.noMargin && 'settings__form-group form-group'} style={{ position: 'relative' }}>
    <label htmlFor={props.id} className="form__label">
      {props.label}
    </label>
    <TimePicker
      value={props.value}
      disabled={props.disabled}
      showSecond={props.showSecond}
      className={`timepicker ${props.className ?? ''}`}
      placeholder={props.placeholder}
      onChange={props.onChange}
    />
  </div>
);

Select.defaultProps = {
  isMulti: false,
  options: [],
  placeholder: '',
  value: null,
  style: {},
  isSearchable: true,
};

Select.propTypes = {
  isMulti: PropTypes.bool,
  isGrouped: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  grouped: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isSearchable: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  isAsyncType: PropTypes.bool,
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  isOptionSelected: PropTypes.func,
  hideSelectedOptions: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  style: PropTypes.object,
  defaultOptions: PropTypes.array,
  cacheOptions: PropTypes.bool,
  loadOptions: PropTypes.func,
};

DateInput.propTypes = {
  label: PropTypes.string,
  labelClass: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isAppointment: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
  dayPickerProps: PropTypes.object,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  currentYear: PropTypes.number,
  toMonth: PropTypes.object,
  fromMonth: PropTypes.object,
  fromYear: PropTypes.number,
  disabledDays: PropTypes.object,
  onDayChange: PropTypes.func,
  dateFormat: PropTypes.string,
  modifiers: PropTypes.object,
  className: PropTypes.string,
};

TimeInput.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showSecond: PropTypes.bool,
  value: PropTypes.object,
  disabled: PropTypes.bool,
};

TimeInput.defaultProps = {
  showSecond: false,
  disabled: false,
};
