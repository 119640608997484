import React, { useCallback, useState } from 'react';
import { FormInput } from '../input';
import { Select } from '../select';
import PropTypes from 'prop-types';
import { Suggestions } from 'components/suggestions';
import { Button, Spinner } from 'reactstrap';
import { ProfileDetail } from 'modules/front-desk/components/ProfileDetail';

export const FieldBoxWithSuggestions = ({
  component,
  isSearching,
  handleSearchResult,
  searchList,
  suggestionKey,
  searchString,
  onChange,
  onAddClick,
  isEdit,
  showAddButton,
  ...props
}) => {
  const components = {
    Select: Select,
    FormInput: FormInput,
  };

  const [showSuggestions, setShowSuggestions] = useState(false);

  const Component = components[component];

  const _handleSuggestedItem = (result) => {
    handleSearchResult(result);
    setShowSuggestions(false);
  };

  const filteredList = useCallback(() => {
    if (!searchString) {
      return searchList;
    }
    return (searchList || []).filter(
      (list) => !!list?.[suggestionKey]?.toLowerCase()?.match(searchString?.toLowerCase()),
    );
  }, [searchString, searchList]);

  return !isEdit ? (
    <div className="form__suggestions position-relative">
      <Component
        {...props}
        onBlur={() => {
          setTimeout(() => {
            setShowSuggestions(false);
          }, 300);
        }}
        onChange={(e) => {
          onChange(e);
          !showSuggestions && setShowSuggestions(true);
        }}
        isRequired={props.isRequired}
      />
      {isSearching && (
        <div style={{ position: 'absolute', background: '#FFF', top: '30px', right: '30px' }}>
          <Spinner color="secondary" />
        </div>
      )}
      <Suggestions
        showSuggestions={showSuggestions}
        getSuggestedItem={_handleSuggestedItem}
        field={suggestionKey}
        input={searchString}
        list={filteredList()}
      />
      {showAddButton && (
        <div className="form__suggestions-btn">
          <Button size="sm" disabled={!searchString} onClick={onAddClick}>
            Add
          </Button>
        </div>
      )}
    </div>
  ) : (
    <ProfileDetail {...props} />
  );
};

FieldBoxWithSuggestions.propTypes = {
  component: PropTypes.string,
  isSearching: PropTypes.bool,
  showAddButton: PropTypes.bool,
  isRequired: PropTypes.bool,
  isEdit: PropTypes.bool,
  handleSearchResult: PropTypes.func,
  onAddClick: PropTypes.func,
  onChange: PropTypes.func,
  searchList: PropTypes.array,
  suggestionKey: PropTypes.string,
  searchString: PropTypes.string,
};

FieldBoxWithSuggestions.defaultProps = {
  isSearching: false,
  isEdit: false,
};
