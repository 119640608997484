/* eslint-disable linebreak-style */
import React from 'react';
import { ReactComponent as FileClip } from 'assets/svg/file-clip.svg';
import PropTypes from 'prop-types';

export const UploadFile = (props) => {
  const { onChange, multiple, accept, fileName, fileList, onDelete, ...rest } = props;
  let inputRef;

  const pickFile = () => {
    inputRef.click();
  };

  return (
    <div className="upload-file__block">
      <div className="upload-file" onClick={pickFile}>
        <input
          onChange={(e) => {
            e.target.files.length > 0 && onChange(e);
          }}
          type="file"
          multiple={multiple}
          ref={(ref) => {
            inputRef = ref;
          }}
          accept={accept.join()}
          {...rest}
        />
        <FileClip height={18} />
        {!multiple ? (
          <span>{fileName || 'Upload a file'}</span>
        ) : fileList?.length === 0 ? (
          <span>Upload a file</span>
        ) : (
          <span>{`${fileList?.length} files uploaded`}</span>
        )}
      </div>
      {multiple ? (
        <div className="upload-file__list">
          {fileList?.map((file, index) => {
            return (
              <div className="upload-file__item" key={index}>
                <span>{file.name}</span>
                <span className="delete-file">
                  <i className="fas fa fa-times" id={file.binaryString} onClick={onDelete} />
                </span>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
};

UploadFile.defaultProps = {
  accept: [],
};

UploadFile.propTypes = {
  fileName: PropTypes.bool,
  fileList: PropTypes.array,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  accept: PropTypes.array,
};
