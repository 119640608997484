import React from 'react';
import PropTypes from 'prop-types';
import Check from 'services/Check';
import { SearchBar } from './search';
import { cn } from 'utils';

export const TableTop = (props) => {
  const {
    addButtonPermission,
    rightControl: RightControl,
    rightControlText,
    leftControl: LeftControl,
    leftControlText,
    noSearchBar,
    searchControl: SearchControl,
  } = props;
  const hasRightAndLeft = LeftControl && RightControl;
  return (
    <div
      className={cn('tw-justify-between tw-items-center tw-space-y-5', {
        'md:tw-flex ': !hasRightAndLeft,
        'lg:tw-flex ': hasRightAndLeft,
      })}
    >
      {!noSearchBar && (
        <div className="tw-min-w-[600px] md:tw-max-w-xl lg:tw-max-w-2x">
          {SearchControl ? <SearchControl {...props} /> : <SearchBar {...props} />}
        </div>
      )}

      <div className="md:tw-flex md:tw-items-center md:tw-space-x-9">
        {LeftControl && <LeftControl leftControlText={leftControlText} cssClass="" {...props} />}

        <Check permission={addButtonPermission}>
          {RightControl && <RightControl rightControlText={rightControlText} {...props} />}
        </Check>
      </div>
    </div>
  );
};

TableTop.defaultProps = {};

TableTop.propTypes = {
  rightControl: PropTypes.func,
  leftControl: PropTypes.func,
  searchControl: PropTypes.func,
  rightControlText: PropTypes.string,
  leftControlText: PropTypes.string,
  addButtonPermission: PropTypes.array,
  noSearchBar: PropTypes.bool,
};
