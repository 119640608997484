import React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';
import { ReactComponent as Close } from 'assets/svg/close.svg';

export const ViewModal = props => {
  const { isOpen, toggle, cssClass, body, title } = props;

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="" centered backdropClassName="backdrop-style" className={cssClass}>
      <div className="modal__header">
        <div className="container-fluid">
          <div className="modal__header--inner d-flex align-items-center justify-content-between">
            <div className="mt-3">
              <h2>{title}</h2>
            </div>

            <div className="modal__header--icon">
              <Close style={{ cursor: 'pointer' }} onClick={props.toggle} color="#fa4659" strokeWidth={1} />
            </div>
          </div>
        </div>
      </div>
      <ModalBody>
        <div className="p-5 d-flex align-items-center justify-content-center text-center">{body}</div>
        <div className="form__settings-footer my-5">
          <div className="row justify-content-center">
            <div className="mx-4">
              <Button className="btn btn__utility mr-2" onClick={() => toggle()}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

ViewModal.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  body: PropTypes.element,
  cssClass: PropTypes.string,
};
