import * as bankTypes from '../types/bankTypes';
import initialState from 'redux/reducers/initialState';

export const bank = (state = initialState.banks, action) => {
  switch (action.type) {
    case bankTypes.FETCH_BANKS_SUCCESS:
      return {
        ...state,
        items: action.payload,
        filteredItems: action.payload,
      };
    case bankTypes.FETCH_BANKS_FAILED:
      return { ...state };

    case bankTypes.ADD_BANKS_SUCCESS:
      return {
        ...state,
        items: [...state.items.concat(action.payload)],
        filteredItems: [...state.items.concat(action.payload)],
      };
    case bankTypes.ADD_BANKS_START:
      return {
        ...state,
        isSaving: true,
      };
    case bankTypes.ADD_BANKS_END:
      return {
        ...state,
        isSaving: false,
      };
    case bankTypes.ADD_BANKS_FAILED:
      return { ...state };
    case bankTypes.UPDATE_BANKS_SUCCESS:
      return {
        ...state,
        filteredItems: [
          ...state.items.map(bank => {
            const bankItem = bank._id === action.payload._id ? { ...bank, name: action.payload.name } : bank;
            return {
              ...bankItem,
            };
          }),
        ],
        items: [
          ...state.items.map(bank => {
            const bankItem = bank._id === action.payload._id ? { ...bank, name: action.payload.name } : bank;
            return {
              ...bankItem,
            };
          }),
        ],
      };
    case bankTypes.UPDATE_BANKS_FAILED:
      return { ...state };
    case bankTypes.UPDATE_BANK_LIST:
      return {
        ...state,
        filteredItems: action.payload,
      };
    default:
      return state;
  }
};

