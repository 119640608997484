/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AuthErrorCard, Select } from 'components';
import { Button, Form } from 'reactstrap';
import classnames from 'classnames';
import { _validateForm } from 'utils';
import * as actionTypes from 'redux/types/utilityTypes';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedSupplier } from 'redux/actions';
import Check from 'services/Check';

export const BlackListSupplier = ({ entity, toggle }) => {
  const dispatch = useDispatch();
  const { suppliers } = useSelector(store => store);
  const { isSaving, formErrorMessage } = suppliers;

  const initialItem = {};

  const itemDetails = entity._id
    ? {
        ...entity,
      }
    : initialItem;

  const [state, setState] = useState({
    supplier: itemDetails,
    errorState: {},
    deactivateModal: false,
  });

  const { supplier, errorState } = state;

  const handleAttributeChange = (value, attribute) => {
    const formStatus = _validateForm(value, attribute, supplier, errorState);
    setState({
      ...state,
      supplier: formStatus.obj,
      errorState: formStatus.errorState,
    });
  };
  const isDisabled = () => {
    let status;
    const condition = supplier.reasonForBlacklist && !errorState.reasonForBlacklist;
    condition ? (status = true) : (status = false);
    return status;
  };

  const handleBlackList = selected => {
    const payload = {
      isBlacklisted: true,
      reasonForBlacklist: supplier.reasonForBlacklist.value,
      isActive: false,
    };

    const updatedItems = { ...selected, ...payload };

    dispatch(updateSelectedSupplier(selected._id, payload, updatedItems, toggle, 'blacklisted'));
  };

  const onClose = () => dispatch({ type: actionTypes.CLOSE_FORM_ERROR });

  const _onSubmit = payload => {
    if (payload._id) {
      handleBlackList(payload);
    }
  };

  const blackListReasons = [
    { label: 'Bad experience', value: 'Bad experience' },
    { label: 'No longer in business', value: 'No longer in business' },
    { label: 'Delivery of fake items', value: 'Delivery of fake items' },
    { label: 'Delivery of expired goods', value: 'Delivery of expired goods' },
  ];

  return (
    <>
      <div className="container-fluid">
        {formErrorMessage && <AuthErrorCard error={formErrorMessage} onClose={onClose} />}
        <Form onSubmit={e => e.preventDefault()} className="form__settings form">
          <div className="row">
            <div className="col-md-12">
              <Select
                placeholder="Select Reason"
                className={errorState.reasonForBlacklist ? 'is-invalid' : null}
                onChange={e => handleAttributeChange(e, 'reasonForBlacklist')}
                value={supplier.reasonForBlacklist}
                options={blackListReasons}
              />
            </div>
          </div>

          <div className="form__settings-footer">
            <div className="row">
              {supplier._id && (
                <Check permission={['blacklist-supplier', 'super-deactivate']}>
                  <div className="form__settings-button">
                    <Button
                      disabled={!isDisabled()}
                      onClick={() => _onSubmit(supplier)}
                      block
                      className={classnames('btn__secondary', {
                        btn__loading: isSaving,
                      })}
                    >
                      submit
                    </Button>
                  </div>
                </Check>
              )}
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

BlackListSupplier.propTypes = {
  entity: PropTypes.object,
  toggle: PropTypes.func,
};
