import { _notifyError, _notifySuccess, _sortItems, updatedState } from 'utils';
import * as staffTypes from '../types/staffTypes';
import { beginApiCall, endApiCall } from './apiActions';
import Axios from 'services/axios';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const fetchStaffSuccess = (payload) => ({
  type: staffTypes.FETCH_STAFF_SUCCESS,
  payload,
});

const fetchStaffFailed = (msg) => ({
  type: staffTypes.FETCH_STAFF_FAILED,
  error: msg,
});

export const fetchStaff = () => {
  return async (dispatch) => {
    dispatch(beginApiCall());
    try {
      const { data } = await Axios.get('/staff');

      if (data.status === 'true') {
        const sortData = _sortItems(data.data, 'name');

        const payload = {
          items: sortData,
          filteredItems: sortData,
        };
        dispatch(fetchStaffSuccess(payload));
        dispatch(endApiCall());
      }
    } catch (err) {
      dispatch(fetchStaffFailed(err.message));
      dispatch(endApiCall());
    }
  };
};

const addStafftart = () => ({
  type: staffTypes.ADD_STAFF_START,
});

const addStaffuccess = (payload) => ({
  type: staffTypes.ADD_STAFF_SUCCESS,
  payload,
});

const addStaffFailed = (msg) => ({
  type: staffTypes.ADD_STAFF_FAILED,
  error: msg,
});

export const addStaff = (current, arr, onSuccess) => {
  return async (dispatch) => {
    dispatch(addStafftart());
    try {
      const { data } = await Axios.post('/staff', current);
      if (data.status) {
        const cache = _sortItems([...arr].concat(data.data), 'name');
        dispatch(addStaffuccess(updatedState(cache)));
        onSuccess();
        _notifySuccess('Staff successfully added.');
      }
    } catch (error) {
      dispatch(addStaffFailed(error?.response?.data?.message || CUSTOM_ERROR_MESSAGE));
      _notifyError(error?.response?.data?.message || CUSTOM_ERROR_MESSAGE);
    }
  };
};

const updateStafftart = (payload) => ({
  type: staffTypes.UPDATE_STAFF_START,
  payload,
});

const updateStaffuccess = (payload) => ({
  type: staffTypes.UPDATE_STAFF_SUCCESS,
  payload,
});

const updateStaffFailed = (msg) => ({
  type: staffTypes.UPDATE_STAFF_FAILED,
  error: msg,
});

export const updateSelectedStaff = (current, arr, close, action) => {
  return async (dispatch) => {
    const { email, name, phoneNumber, locations, sections, departments, specialties, isActive, _id } = current;
    const actionType = action ? { isDeactivating: true } : { isSaving: true };
    const payload = { email, name, phoneNumber, locations, sections, departments, specialties, isActive };
    dispatch(updateStafftart(actionType));
    try {
      const { data } = await Axios.patch(`/staff/${_id}`, payload);
      if (data.status) {
        const cache = [...arr].map((prev) => (String(prev._id) === String(current._id) ? current : prev));
        close();
        dispatch(updateStaffuccess(updatedState(cache)));
        _notifySuccess(`Staff ${action || 'updated'} successfully.`);
      }
    } catch (error) {
      dispatch(updateStaffFailed(CUSTOM_ERROR_MESSAGE));
      _notifyError(error?.response.data.message || CUSTOM_ERROR_MESSAGE);
    }
  };
};

const deleteStaffStart = () => ({ type: staffTypes.DELETE_STAFF_START });

const deleteStaffSuccess = (payload) => ({
  type: staffTypes.DELETE_STAFF_SUCCESS,
  payload,
});

const deleteStaffFailed = (msg) => ({
  type: staffTypes.DELETE_STAFF_FAILED,
  error: msg,
});

export const deleteStaff = (id, arr, close) => {
  return async (dispatch) => {
    dispatch(deleteStaffStart());

    const { data } = await Axios.delete(`/staff/${id}`);

    if (data.status) {
      const cache = [...arr].filter((prev) => String(prev._id) !== String(id));

      dispatch(
        deleteStaffSuccess({
          items: cache,
          filteredItems: cache,
        }),
      );
      _notifySuccess(`Staff deleted successfully.`);
      close();
    } else {
      _notifyError(data.message);
      dispatch(deleteStaffFailed(CUSTOM_ERROR_MESSAGE));
    }
  };
};

export const staffPageSort = (arr) => {
  return {
    type: staffTypes.STAFF_PAGE_SORT,
    payload: {
      items: arr,
      filteredItems: arr,
    },
  };
};

export const updateStaffList = (value) => ({
  type: staffTypes.UPDATE_STAFF_LIST,
  payload: {
    filteredItems: value,
  },
});
