import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { StatusPill } from 'modules/inventory/components';

export const TaskCard = (props) => {
  const cardWithoutButton = props.type === 'todo' || props.type === 'dispatched';
  const cardWithButton = props.type !== 'todo' && props.type !== 'dispatched' && !props.noButton;

  const renderButtonName = (type) => {
    switch (type) {
      case 'pending':
        return 'Start';
      case 'inProgress':
        return 'Complete';
      case 'completed':
        return 'Dispatch';
      default:
        return '';
    }
  };

  const containerClick = () => {
    if (cardWithoutButton) {
      props.onClick();
    }
  };

  return (
    <div
      className="card card__task"
      style={{ cursor: cardWithoutButton ? 'pointer' : 'default', height: '120px' }}
      onClick={() => containerClick()}
    >
      {props.showDeadline &&
        (props.deadline === 'due' ? (
          <div className="card__task__notify-deadline due" />
        ) : props.deadline === 'overdue' ? (
          <div className="card__task__notify-deadline overdue" />
        ) : null)}
      <div className="card__task__body">
        {props.patient && <h6 className="card__task__location">Patient: {props.patient.name}</h6>}
        {props.operator && <h5 className="card__task__date">Consultant: {props.operator?.name || 'N/A'}</h5>}
        <span className="card__task__date">{new Date(props.createdAt).toDateString()}</span>
        {props.showStatus && (
          <span className="float-right">
            <StatusPill status={props.status} color={`status-${props.status}`} />
          </span>
        )}
        {props.company && <h6 className="card__task__patient">{props.company.name || null}</h6>}
        {props.services && <h5 className="card__task__service">{props.services?.[0]?.name}</h5>}
        {props.operator?._id && <h5 className="card__task__service">Requested By: {props.operator?.name || 'NA'}</h5>}
      </div>

      {cardWithButton && (
        <div className="card__task__action">
          <Button className="card__task__button" onClick={props.onClick}>
            {renderButtonName(props.type)}
          </Button>
        </div>
      )}
    </div>
  );
};

TaskCard.defaultProps = {
  showDeadline: false,
  deadline: '',
};

TaskCard.propTypes = {
  name: PropTypes.string,
  noButton: PropTypes.bool,
  patient: PropTypes.object,
  onClick: PropTypes.func,
  serviceIds: PropTypes.array,
  services: PropTypes.array,
  assignees: PropTypes.array,
  comments: PropTypes.array,
  type: PropTypes.string,
  createdAt: PropTypes.string,
  deadline: PropTypes.string,
  showDeadline: PropTypes.bool,
  showPatient: PropTypes.bool,
  company: PropTypes.object,
  operator: PropTypes.object,
  status: PropTypes.string,
  showStatus: PropTypes.bool,
};
