import { _notifyError, _notifySuccess, _sortItems, _undoTransformToSelectOptions } from 'utils';
import Axios from 'services/axios';
import * as branchesConfigTypes from '../types/branchesConfigTypes';
import { beginApiCall, endApiCall } from '../../../../redux/actions/apiActions';
import { CUSTOM_ERROR_MESSAGE } from 'utils/constants';

const fetchBranchesConfigurationSuccess = (payload) => ({
  type: branchesConfigTypes.FETCH_BRANCHES_CONFIGURATION_SUCCESS,
  payload,
});

const fetchBranchesConfigurationFailed = (msg) => ({
  type: branchesConfigTypes.FETCH_BRANCHES_CONFIGURATION_FAILED,
  error: msg,
});

export const fetchBranchesConfiguration = () => async (dispatch) => {
  dispatch(beginApiCall());
  const { data } = await Axios.get(`/branches?hasConfig=true`);
  if (!data.status) {
    dispatch(endApiCall());
    _notifyError(data.message);
    dispatch(fetchBranchesConfigurationFailed(data.message));
  } else {
    const payload = {
      items: _sortItems([...data.data], 'name'),
      filteredItems: _sortItems([...data.data], 'name'),
    };
    dispatch(endApiCall());
    dispatch(fetchBranchesConfigurationSuccess(payload));
  }
};

const addBranchConfigurationSuccess = (payload) => ({
  type: branchesConfigTypes.ADD_BRANCH_CONFIGURATION_SUCCESS,
  payload,
});

export const addBranchConfiguration = ({ config, branch }) => {
  const branchIds = branch.map((branch) => branch.value);
  const payload = { config, branchIds };

  return async (dispatch) => {
    try {
      const { data } = await Axios.patch(`/add-config`, payload);
      if (data.status) {
        const newConfig = _undoTransformToSelectOptions(branch, 'name').map((branch) => ({
          ...branch,
          config,
        }));
        dispatch(addBranchConfigurationSuccess(newConfig));
        _notifySuccess('Branch Configuration successfully added.');
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
    }
  };
};

const updateBranchConfigurationSuccess = (payload) => ({
  type: branchesConfigTypes.UPDATE_BRANCH_CONFIGURATION_SUCCESS,
  payload,
});

export const updateBranchConfiguration = ({ config, branchId }) => {
  return async (dispatch) => {
    try {
      const { data } = await Axios.patch(`/branches/${branchId}/set-config`, config);
      if (data.status) {
        dispatch(updateBranchConfigurationSuccess({ config, branchId }));
        _notifySuccess(`Branch Configuration successfully updated.`);
      }
    } catch (error) {
      _notifyError(CUSTOM_ERROR_MESSAGE);
    }
  };
};

export const updateBranchConfigurationList = (value) => ({
  type: branchesConfigTypes.UPDATE_BRANCHES_CONFIGURATION_LIST,
  payload: {
    filteredItems: value,
  },
});
