import Axios from 'services/axios';
import * as authTypes from '../types/authTypes';

const loginSuccess = (user) => ({
  type: authTypes.LOGIN_SUCCESS,
  payload: user,
});

const loginFailure = (error) => ({
  type: authTypes.LOGIN_FAILURE,
  payload: error,
});

export const loginUser = (userDetails, handleSuccess, handleError) => async (dispatch) => {
  try {
    const { data, status } = await Axios.post('/login', userDetails);
    if (status) {
      handleSuccess?.(data?.data);
      dispatch(loginSuccess(true));
    }
  } catch (error) {
    const { data } = error.response || {};
    const err = data && data.message ? data.message : 'Unknown error occurred, please refresh or try again.';
    dispatch(loginFailure(err));
    handleError?.(err);
  }
};

const resetRequestSuccess = (user) => ({
  type: authTypes.RESET_REQUEST_SUCCESS,
  payload: user,
});

const resetRequestFailure = (error) => ({
  type: authTypes.RESET_REQUEST_FAILURE,
  payload: error,
});

export const resetRequest = (email) => async (dispatch) => {
  try {
    const data = await Axios.post('/reset-password', { email });
    dispatch(resetRequestSuccess(data.status));
    return { status: true };
  } catch (error) {
    let message = 'Unknown error occurred, please refresh or try again.';
    if (error.response === undefined) {
      dispatch(resetRequestFailure({ message }));
      return { status: false, message };
    }
    const { data, status } = error.response;
    dispatch(resetRequestFailure({ message: data.message, status }));
    if (data.message && data.message.includes('not found')) {
      message = 'This email is not assosiated with any account.';
    }
    return { status: false, message };
  }
};

const changePasswordSuccess = (user) => ({
  type: authTypes.CHANGE_PASSWORD_SUCCESS,
  payload: user,
});

const changePasswordFailure = (error) => ({
  type: authTypes.CHANGE_PASSWORD_FAILURE,
  payload: error,
});

export const changePassword = (token, password, consent) => async (dispatch) => {
  try {
    const { data } = await Axios.post('/set-password', {
      token,
      password,
      consent,
    });

    if (data.status) {
      dispatch(changePasswordSuccess(data.status));
      return { status: true };
    }
    throw Error;
  } catch (error) {
    const { data, status } = error.response || {};

    dispatch(changePasswordFailure({ message: data ? data.message : 'Network Error', status: status || 'error' }));

    return { status: false, message: data.message };
  }
};

const logOutSuccess = (payload) => ({
  type: authTypes.LOGOUT_SUCCESS,
  payload,
});

const logOutFailure = (error) => ({
  type: authTypes.LOGOUT_FAILURE,
  payload: error,
});

export const logOut = () => async (dispatch) => {
  try {
    localStorage.clear();
    dispatch(logOutSuccess({}));
  } catch (error) {
    dispatch(logOutFailure());
  }
};
