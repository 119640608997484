import React, { lazy, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { environment } from 'config';
import MainLayout from '../../../layouts/MainLayout';
import AuthRoute from '../../../routes/AuthRoute';
import {
  fetchAppointmentPurposes,
  fetchBranchesConfiguration,
  fetchDiseases,
  fetchPlans,
  fetchTestStandards,
} from 'modules/settings/redux/actions';
import {
  fetchBranches,
  fetchDepartments,
  fetchOrganizationCustomization,
  fetchSponsors,
  fetchStaff,
} from 'redux/actions';
import { isOutPatient } from 'utils';
import { _notifySocket } from '../components/NotificationCard';

const {
  api: { uri },
} = environment;

const socket = io(uri, {
  transports: ['polling'],
});

const Dashboard = lazy(() => import('../pages/Dashboard'));
const DashboardListing = lazy(() => import('../pages/Patients/Listing'));
const TheatreListing = lazy(() => import('../pages/Patients/SurgeryListing'));
const MedicalReport = lazy(() => import('../pages/Patients/PatientDetails/ManageMedicalReport'));
const PatientList = lazy(() => import('pages/app/Patients/index'));
const PatientDetailsRoutes = lazy(() => import('../pages/Patients/PatientDetails'));
const Calendar = lazy(() => import('pages/app/Calendar'));
const Appointments = lazy(() => import('pages/app/Appointments/index'));
const TransferredPatient = lazy(() => import('../outpatient/TransferredPatientLogModal'));
const ErrorPage = lazy(() => import('pages/Error'));

const PatientsRoutes = ({ match }) => {
  const loggedIn = useSelector((state) => state.auth?.loggedIn);
  const current = useSelector((state) => state.user?.current);

  // TODO check accessGranted value using the user permitted modules for the current branch
  // const hasAccess = hasModuleAccess(current?.location?.modules, 'frontdesk');

  const consultantSections = useSelector(
    (store) => store.staff?.items?.find((e) => e._id === current?.details?.userId)?.sections,
  );
  const isResidentDoctor = consultantSections?.find((sec) => sec.name === 'Resident Doctor');
  const isVital = consultantSections?.find((sec) => sec.name === 'Vitals');

  const dispatch = useDispatch();

  useEffect(() => {
    if (current && current.parentOrganizationId) {
      dispatch(fetchDepartments(current.parentOrganizationId));
      dispatch(fetchStaff(current.parentOrganizationId));
    }
  }, [dispatch, current]);

  useEffect(() => {
    dispatch(fetchAppointmentPurposes());
    dispatch(fetchOrganizationCustomization());
    dispatch(fetchBranchesConfiguration());
    dispatch(fetchBranches());
    dispatch(fetchDiseases());
    dispatch(fetchTestStandards());
    dispatch(fetchSponsors());
    dispatch(fetchPlans);
  }, []);

  const getAcceptedVitals = async () => {
    //
  };

  useEffect(() => {
    socket.on('connect', () => {
      if (socket.connected) {
        //
      }
    });
  }, []);

  useEffect(() => {
    if (!consultantSections || !current) return;
    const appointmentListener = (data) => {
      _notifySocket({
        duration: 6000,
        rightControl: true,
        handle: true,
        data,
        getAcceptedVitals,
      });
    };

    const doctorAppointmentListener = (data) => {
      _notifySocket({
        duration: 6000,
        rightControl: true,
        data,
      });
    };
    const consultantTransferNotificationListener = (data) => {
      const notify = () =>
        _notifySocket({
          duration: 6000,
          rightControl: true,
          data,
        });
      if (isResidentDoctor || isVital) return notify();
      else if (data?.operatorTransferedTo?._id) {
        return (
          current?.details?.userId === data.operatorTransferedTo._id &&
          _notifySocket({
            duration: 6000,
            rightControl: true,
            handle: true,
            data,
            getAcceptedVitals,
          })
        );
      }
    };

    socket.on('newAppointment', appointmentListener);
    socket.on(' newConsultingAppointment', doctorAppointmentListener);
    socket.on('consultantTransferNotification', consultantTransferNotificationListener);
    return () => {
      socket.off('newAppointment', appointmentListener);
      socket.off(' newConsultingAppointment', doctorAppointmentListener);
      socket.off('consultantTransferNotification', consultantTransferNotificationListener);
    };
  }, [consultantSections, current]);

  return (
    <Switch>
      <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />
      <AuthRoute
        path={`${match.path}/dashboard`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        exact
        component={Dashboard}
        accessGranted={true}
      />

      <AuthRoute
        path={`${match.path}/dashboard/patients/:service`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        exact
        component={DashboardListing}
        accessGranted={true}
      />

      <AuthRoute
        path={`${match.path}/dashboard/theatre`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        exact
        component={TheatreListing}
        accessGranted={true}
      />

      <AuthRoute
        path={`${match.path}/dashboard/appointments`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        component={Appointments}
        accessGranted={isOutPatient()}
      />

      <AuthRoute
        path={`${match.path}/dashboard/transferred-patient`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        component={TransferredPatient}
        accessGranted={isOutPatient()}
      />

      <AuthRoute
        path={`${match.path}/dashboard/medical-reports`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        component={MedicalReport}
        accessGranted={true}
      />

      <AuthRoute
        exact
        path={`${match.path}/patients`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        component={PatientList}
        accessGranted={true}
      />

      <AuthRoute
        path={`${match.path}/patients/:id`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        component={PatientDetailsRoutes}
        accessGranted={true}
      />

      <AuthRoute
        path={`${match.path}/appointments`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        component={Appointments}
        accessGranted={true}
      />

      <AuthRoute
        path={`${match.path}/calendar`}
        isAuthenticated={loggedIn}
        layout={MainLayout}
        component={Calendar}
        accessGranted={true}
      />

      <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
    </Switch>
  );
};

PatientsRoutes.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default PatientsRoutes;
