import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const RegistrationCard = props => {
  const [fullView, showFullView] = useState(props.isOpen || false);
  const showStyle = {
    maxHeight: '300rem',
    overflow: 'inherit'
  };

  return (
    <div className={`card card__registration ${props.cssClass}`} style={fullView ? showStyle : {}}>
      <div className="header" onClick={() => showFullView(!fullView)}>
        {props.iconName ? (
          <div className="title-icon">
            <i className={`iconmoon icon-${props.iconName || 'frontdesk-heartbeat'} mr-4`} />
          </div>
        ) : null}
        <h2>{props.title}</h2>
        <span
          style={fullView ? { transform: 'rotate(180deg)' } : {}}
          className="settings-icon"
        >
          <i className="iconmoon icon-arrow-down" />
        </span>
      </div>
      {props.children || (
        <div className="main">
          {props.list.map((item, index) => (
            <div
              className="item"
              key={index}
              onClick={() => props.checkItem(item._id)}
            >
              <div className="item__text">
                {item.text}
                {item.checked && item.checked === 'required' &&
                  <span className='color-primary'> *</span>
                }
              </div>
              {item.checked &&
                <label
                  className="item__input checked">
                  <span className="checker">
                    <i className="fas fa-check" />
                  </span>
                </label>
              }
            </div>
          ))}
        </div>

      )}
    </div>
  );
};

RegistrationCard.defaultProps = {
  title: 'patients plan',
  list: []
};

RegistrationCard.propTypes = {
  title: PropTypes.string,
  iconName: PropTypes.string,
  cssClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  list: PropTypes.any,
  isOpen: PropTypes.bool,
  checkItem: PropTypes.func
};
