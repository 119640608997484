import React from 'react';
import PropTypes from 'prop-types';

const ProgressIndicator = ({ label, percentage, total, color }) => {
  const theme = () => {
    if (color) return `progress__box-${color}`;
    if (percentage >= 70) return 'progress__box-green';
    if (percentage >= 50) return 'progress__box-orange';
    return 'progress__box-red';
  };

  return (
    <div className="d-flex flex-column-gap-2 align-items-center gx-2">
      <div className="flex-basis-25 color-grey fs-16">{label}</div>
      <div className="flex-basis-50">
        <div className={`progress__box ${theme()}`} style={{ '--percentage': `${percentage}%` }} />
      </div>
      <div className="flex-basis-25 color-dark fs-16 d-flex justify-content-end">
        <span>{total}</span>
      </div>
    </div>
  );
};

ProgressIndicator.defaultProps = {
  label: 'Private',
  percentage: 50,
  total: '₦27,890,324',
  color: '',
};

ProgressIndicator.propTypes = {
  label: PropTypes.string,
  percentage: PropTypes.number,
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
};

export default ProgressIndicator;
