import React from 'react';
import { Link } from 'react-router-dom';
import indigoLogo from 'assets/icon/indigo-logo.png';

export const AuthNav = () => (
  <div className="auth__nav">
    <div className="container">
      <Link to="/login">
        <div className="auth__nav__logo">
          <img src={indigoLogo} alt="Indigo EMR" />
        </div>
      </Link>
    </div>
  </div>
);
