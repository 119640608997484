import * as utilityTypes from '../types/utilityTypes';
import initialState from 'redux/reducers/initialState';

export const currencies = (state = initialState.currencies, action) => {
  switch (action.type) {
    case utilityTypes.FETCH_CURRENCY_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};

export const currency = (state = initialState.currency, action) => {
  switch (action.type) {
    case utilityTypes.SET_CURRENCY:
      return action.payload;

    default:
      return state;
  }
};

export const permissions = (state = initialState.permissions, action) => {
  switch (action.type) {
    case utilityTypes.FETCH_PERMISSIONS_SUCCESS:
      return [...state, ...action.payload];

    default:
      return [...state];
  }
};
