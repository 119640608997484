import React from 'react';
import PropTypes from 'prop-types';
import Check from 'services/Check';

export const ProductTableTop = props => {
  const {
    addButtonPermission,
    rightControl: RightControl,
    rightControlText,
    leftControl: LeftControl,
    leftControlText,
    searchControl: SearchControl,
  } = props;

  return (
    <div className="row table-actions">
      <div className="col-md-4">
        <SearchControl {...props} />
      </div>

      <div className="col-md-8 d-flex flex-column flex-md-row flex-gap-2 justify-content-end align-items-center mt-4 mt-md-0">
        {LeftControl && <LeftControl leftControlText={leftControlText} cssClass="mr-3" {...props} />}

        <Check permission={addButtonPermission}>
          {RightControl && <RightControl rightControlText={rightControlText} {...props} />}
        </Check>

        {/* <div className="table-actions__filter">
          <Filter {...props} />
        </div>  */}
      </div>
    </div>
  );
};

ProductTableTop.defaultProps = {};

ProductTableTop.propTypes = {
  rightControl: PropTypes.func,
  leftControl: PropTypes.func,
  searchControl: PropTypes.func,
  rightControlText: PropTypes.string,
  leftControlText: PropTypes.string,
  addButtonPermission: PropTypes.array,
};
