
import SwiperCore, { Scrollbar, Mousewheel } from 'swiper';

import './assets/styles/main.scss';
import 'swiper/swiper.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Scrollbar, Mousewheel]);

import LaboratoryRoutes from './routes';

export default LaboratoryRoutes;