import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ReactComponent as ListSvg } from 'assets/svg/list.svg';

export const TaskContainer = props => {

  return (
    <div className={`task-container`}>
      <div className="task-container__header">
        <div className="task-container__header--text">
          <h3 className="task-container__title">{props.title}</h3>
          <h3 className="task-container__items">{props.items} items</h3>
        </div>
        <span className="filter-action__title" onClick={props.refresh} >Refresh</span>
      </div>

      <div
        className={classnames('task-container__body', {
          empty: !props.children
        })}
      >
        {props.items ? (
          props.children
        ) : (
          <div className="h-100 d-flex flex-column align-items-center justify-content-center">
            <ListSvg />
            <p className="empty-text">No tasks currently</p>
          </div>
        )}
      </div>
    </div>
  );
};

TaskContainer.defaultProps = {
  color: 'blue',
  items: 0,
  title: 'To Do',
  showDateRange: true
};

TaskContainer.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node,
  items: PropTypes.any,
  title: PropTypes.string,
  disabledDays: PropTypes.object,
  month: PropTypes.object,
  startDateClick: PropTypes.func,
  endDateClick: PropTypes.func,
  showDateRange: PropTypes.bool,
  refresh: PropTypes.func
};
