import React, { lazy, useEffect } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import MainLayout from '../../../layouts/MainLayout';
import AuthRoute from '../../../routes/AuthRoute';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBranches, fetchSponsors } from 'redux/actions';
import { getProducts } from 'modules/inventory/redux/actions';
import { getSerializedParams } from 'utils';

const Dashboard = lazy(() => import('../pages/Dashboard'));

const ErrorPage = lazy(() => import('pages/Error'));

const Reporting = ({ match }) => {
  const dispatch = useDispatch();

  const { loggedIn } = useSelector((state) => state.auth);
  const { current } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(fetchBranches());
    dispatch(fetchSponsors());
    dispatch(getProducts(getSerializedParams({ limit: 10000 }), current?.branchId || undefined));
  }, []);

  return (
    <>
      <Switch>
        <Redirect exact from={`${match.path}`} to={`${match.path}/dashboard`} />

        <AuthRoute
          exact
          path={`${match.path}/dashboard`}
          isAuthenticated={loggedIn}
          layout={MainLayout}
          component={Dashboard}
          accessGranted={true}
        />

        <AuthRoute path="*" isAuthenticated={loggedIn} layout={MainLayout} component={ErrorPage} accessGranted={true} />
      </Switch>
    </>
  );
};

Reporting.propTypes = {
  match: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
};

export default Reporting;
