/* eslint-disable no-case-declarations */
import * as bedTypes from '../types/bedTypes';
import initialState from 'redux/reducers/initialState';
import { updateObject, _sortItems } from 'utils';

export const beds = (state = initialState.beds, action) => {
  switch (action.type) {

    case bedTypes.FETCH_BEDS_START:
      return updateObject(state, {isLoading : true });

    case bedTypes.FETCH_BEDS_SUCCESS:
      return updateObject(state, { ...action.payload , isLoading : false });

    case bedTypes.FETCH_BEDS_FAILED:
      return updateObject(state, { error: action.error , isLoading : false });

    case bedTypes.ADD_BED_START:
      return updateObject(state, { isSaving: true });

    case bedTypes.ADD_BED_SUCCESS:
      const newBeds = _sortItems([...state.items].concat(action.payload), 'name');
      return updateObject(state, { items: newBeds, filteredItems: newBeds, isSaving: false });

    case bedTypes.ADD_BED_FAILED:
      return updateObject(state, {
        isSaving: false,
      });

    case bedTypes.UPDATE_BED_START:
      return updateObject(state, { ...action.payload });

    case bedTypes.UPDATE_BED_SUCCESS:
      const updated = [...state.items].map(prev =>
        String(prev._id) === String(action.payload._id) ? action.payload : prev,
      );
      return updateObject(state, {
        items: updated,
        filteredItems: updated,
        isSaving: false,
        isDeactivating: false,
      });

    case bedTypes.UPDATE_BED_FAILED:
      return updateObject(state, {
        isSaving: false,
        isDeactivating: false,
      });

    case bedTypes.UPDATE_BEDS_LIST:
      return updateObject(state, action.payload);

    default:
      return { ...state };
  }
};

