import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as EmptySvg } from 'assets/svg/empty-state.svg';
import { ReactComponent as EmptySvg2 } from 'assets/svg/empty-state-2.svg';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import Check from 'services/Check';

export const EmptyScreen = props => {
  return (
    <div className="settings__empty-content">
      <div className="settings__empty-img">{props.aux ? <EmptySvg2 /> : <EmptySvg />}</div>
      <p className="settings__empty-text">{props.title}</p>
      {props.footer && (
        <Check permission={props.addButtonPermission}>
          <div className="settings__empty-footer">
            {props.showAddButton && (
              <div className="add-action">
                <Button className={classnames('btn__secondary')} onClick={props.primaryClick}>
                  {props.addButtonText}
                </Button>
              </div>
            )}
            {props.showImportButton && (
              <div className="import-action">
                <Button className={classnames('btn__utility')} onClick={props.secondaryClick}>
                  Upload list
                </Button>
              </div>
            )}
          </div>
        </Check>
      )}
    </div>
  );
};

EmptyScreen.defaultProps = {
  title: 'No Branches',
  addButtonText: 'add branch',
  footer: false,
  showImportButton: true,
  showAddButton: true,
};

EmptyScreen.propTypes = {
  title: PropTypes.string,
  addButtonText: PropTypes.string,
  footer: PropTypes.bool,
  aux: PropTypes.bool,
  addButtonPermission: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  showAddButton: PropTypes.bool,
  showImportButton: PropTypes.bool,
  primaryClick: PropTypes.func,
  secondaryClick: PropTypes.func,
};
